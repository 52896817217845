import { Issue } from "@/http/api/v2/issue";
import { IssueDto, IssueFilterParams } from "@/http/dto/issue";
import { DATE_DAY_FORMAT, DATE_FORMAT } from "@/utiles/constants";
import moment from "moment";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface IIssueState {
  pageSize: number;
  page: number;
  loading: boolean;
  end: boolean;
  issues: IssueDto[];
  filter: Omit<IssueFilterParams, "skip" | "take">;
}

@Module({
  namespaced: true,
})
export default class IssueV2Module extends VuexModule implements IIssueState {
  public readonly pageSize = 10;
  public offset = 0;
  public page = 1;
  public loading = false;
  public end = false;
  public error = false;
  public issues: IssueDto[] = [];
  public filter: Omit<IssueFilterParams, "skip" | "take"> = {};

  get groupedIssuesByDate() {
    return this.issues.reduce(
      (acc, cur) => {
        const date = moment(cur.createdAt).format(DATE_FORMAT);
        if (!acc[date]) {
          acc[date] = { title: moment(cur.createdAt).format(DATE_DAY_FORMAT), list: [] };
        }

        acc[date].list.push(cur);
        return acc;
      },
      {} as {
        [key: string]: {
          title: string;
          list: IssueDto[];
        };
      },
    );
  }

  get initial() {
    return this.page === 1 && this.issues.length === 0;
  }

  @Mutation
  private SET_OFFSET(offset: number) {
    this.offset = offset;
  }
  @Mutation
  private SET_PAGE(page: number) {
    this.page = page;
  }

  @Mutation
  private SET_LOADING(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  private SET_END(end: boolean) {
    this.end = end;
  }

  @Mutation
  private SET_ERROR(error: boolean) {
    this.error = error;
  }

  @Mutation
  private SET_ISSUES(issues: IssueDto[]) {
    this.issues = [...issues];
  }

  @Mutation
  private SET_FILTER(filter: Omit<IssueFilterParams, "skip" | "take">) {
    this.filter = { ...filter };
  }

  @Action
  public async fetchIssues(payload: { hotelObjectId: string }) {
    if (this.end || this.loading || this.error) {
      return;
    }

    this.context.commit("SET_LOADING", true);

    try {
      const issues = await Issue.getIssues(payload.hotelObjectId, {
        ...this.filter,
        skip: (this.page - 1) * this.pageSize + this.offset,
        take: this.pageSize,
      });

      this.context.commit("SET_ISSUES", [...this.issues, ...issues]);

      if (issues.length < this.pageSize) {
        this.context.commit("SET_END", true);
      }
    } catch (error) {
      this.context.commit("SET_ERROR", true);
    } finally {
      this.context.commit("SET_LOADING", false);
    }
  }

  @Action
  public async nextPage(payload: { hotelObjectId: string }) {
    if (this.end) {
      return;
    }

    this.context.commit("SET_PAGE", this.page + 1);

    try {
      await this.context.dispatch("fetchIssues", payload);
    } catch (error) {
      this.context.commit("SET_PAGE", this.page - 1);
    }
  }

  @Action
  public async refreshPage(payload: {
    hotelObjectId: string;
    filter?: Omit<IssueFilterParams, "skip" | "take">;
  }) {
    this.context.commit("SET_PAGE", 1);
    this.context.commit("SET_END", false);
    this.context.commit("SET_ISSUES", []);
    this.context.commit("SET_ERROR", false);
    this.context.commit("SET_OFFSET", 0);
    this.context.commit("SET_FILTER", payload.filter || {});

    await this.context.dispatch("fetchIssues", payload);
  }

  @Action
  public async insertIssue(payload: { issue: IssueDto }) {
    const copiedIssues = [...this.issues];
    const exist = copiedIssues.some(i => i.objectId === payload.issue.objectId);

    if (exist) {
      this.context.dispatch("updateIssue", {
        objectId: payload.issue.objectId,
        issue: payload.issue,
      });
      return;
    }

    const position = copiedIssues.findIndex(i => i.createdAt < payload.issue.createdAt);

    if (position === -1) {
      copiedIssues.push(payload.issue);
    } else {
      copiedIssues.splice(position, 0, payload.issue);
    }

    this.context.commit("SET_ISSUES", copiedIssues);
    this.context.commit("SET_OFFSET", this.offset + 1);
  }

  @Action
  public async updateIssue(payload: { objectId: string; issue: IssueDto }) {
    this.context.commit(
      "SET_ISSUES",
      this.issues.map(i => (i.objectId === payload.objectId ? payload.issue : i)),
    );
  }

  @Action
  public async deleteIssue(payload: { objectId: string }) {
    this.context.commit(
      "SET_ISSUES",
      this.issues.filter(i => i.objectId !== payload.objectId),
    );
  }
}
