





























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, namespace } from "vuex-class";

import LayoutHeader from "@/components/layouts/layoutHeader.vue";
import { axiosClient } from "@/http/client";
import { Advertisement } from "@/http/api/advertisement";
import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";
import { router } from "@/main";

const HotelModule = namespace("HotelModule");
const idRegex = /^\S{3,}$/;

@Component({
  components: {
    LayoutHeader,
  },
})
export default class Login extends Vue {
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  data = {
    id: "",
    password: "",
  };

  loading: boolean = false;

  baseUrl: string = axiosClient.defaults.baseURL || "";
  ads: any[] = [];

  mounted() {
    this.check();
    this.loadAds();
  }

  async loadAds() {
    this.ads = (await Advertisement.List("login")) as any;
  }

  async submit() {
    if (!idRegex.test(this.data.id)) {
      this.$message.warning("아이디는 4글자 이상 특수문자 없이 입력해주세요.");
      return;
    }

    if (!this.data.password) {
      this.$message.warning("비밀번호를 입력해주세요.");
      return;
    }

    try {
      this.loading = true;

      await this.login();
      await this.check();
    } catch {
      this.$message.error("로그인에 실패하였습니다. 아이디 및 비밀번호를 확인해주세요.");
    } finally {
      this.loading = false;
    }
  }

  async login() {
    const response: any = await Auth.login(this.data.id, this.data.password);
    localStorage.setItem("token", response.token);
  }

  async check() {
    try {
      await Auth.hasHotel();
      const hotels = await Hotel.list();
      const token = localStorage.getItem("token");

      if (!hotels || !hotels.length) {
        return router.push("/admin/registerHotel");
      }

      this.toHotelUrl({
        url: hotels[0].url,
        page: "",
        token,
      });
    } catch {}
  }

  imageUrl(fileUrl: string) {
    if (fileUrl.startsWith("http")) {
      return fileUrl;
    } else return `https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/${fileUrl}`;
  }
}
