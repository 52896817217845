







































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";
import IssueContents from "@/components/issues/issueContents.vue";
import { jsonToText, textToJson, jsonToHtml, htmlToText } from "@/utiles/contentsParser";
import ProfileImage from "@/components/common/profileImage.vue";
import { File as FileUpload } from "@/http/api/file";
import axios from "axios";
import Attachment from "@/components/common/attachment.vue";
import { Reply } from "@/http/api/reply";

const maxFileSize = 1024 * 1024 * 20;

const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const MemberModule = namespace("MemberModule");

@Component({
  components: {
    IssueContents,
    ProfileImage,
    Attachment,
  },
})
export default class IssueReplyInput extends Vue {
  @Prop() parentObjectIds: any;
  @Prop() defaultData: any;
  @Prop() reply: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @IssueModule.Action("loadIssue") loadIssue: any;
  @IssueModule.Action("loadIssueReplies") loadIssueReplies: any;

  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  loading: boolean = false;
  data: any = {};
  files: any[] = [];

  get edit() {
    return !!this.data.objectId;
  }

  mounted() {
    if (this.defaultData) {
      this.data = { ...this.defaultData };
      this.files = [...this.defaultData.files];
    }
  }

  async onLoad(editor: any) {
    if (this.defaultData) {
      editor.quill.setText(this.defaultData.contents);
    }
  }

  getContents() {
    const editor = this.$refs.editor as any;
    if (editor.contents !== null && editor.contents !== "" && editor.contents !== undefined) {
      const dom = new DOMParser().parseFromString(editor.contents, "text/html");
      return htmlToText(dom.body);
    }

    return "";
  }

  async submit() {
    const contents = this.getContents();

    if (!contents && !this.files.length) {
      this.$message.warning("댓글 내용을 입력해주세요.");
      return;
    }

    try {
      this.loading = true;

      if (!this.edit) {
        await Reply.createReply({
          parentObjectIds: this.parentObjectIds,
          contents,
          files: this.files,
        });

        this.$message.success("댓글을 작성하였습니다.");
      } else {
        await Reply.updateReply({
          parentObjectIds: this.parentObjectIds,
          objectId: this.data.objectId,
          contents,
          files: this.files,
        });

        this.$message.success("댓글을 수정하였습니다.");
        this.cancel();
      }

      const editor = this.$refs.editor as any;
      editor.editor.quill.setText("");

      this.data = {};
      this.files = [];
    } catch {
      if (!this.edit) {
        this.$message.error("댓글 작성에 실패하였습니다.");
      } else {
        this.$message.error("댓글 수정에 실패하였습니다.");
      }
    } finally {
      this.$emit("upsert");
      this.loading = false;
    }
  }

  selectFile() {
    (this.$refs.file as HTMLElement).click();
  }

  async onFileChange(e: Event) {
    const input = e.target as HTMLInputElement;

    try {
      if (!input.files || !input.files[0]) {
        return;
      }

      if (this.files.length + input.files.length > 10) {
        this.$message.warning("파일은 10개까지만 업로드가 가능합니다.");
        return;
      }

      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];

        if (file.size > maxFileSize) {
          throw { message: "파일 용량이 20MB를 초과하였습니다." };
        }
      }

      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];

        const { presignedUrl, url } = await FileUpload.getPreSignedUrl(file.name);

        await axios.request({
          url: presignedUrl,
          method: "PUT",
          data: file,
          headers: { "Access-Control-Allow-Origin": "*" },
        });

        this.files = [
          ...this.files,
          {
            url: url,
            fileName: file.name,
            size: file.size,
          },
        ];
      }
    } catch (ex) {
      this.$message.error("파일 업로드에 실패하였습니다.");
    } finally {
      input.value = "";
    }
  }

  deleteFile(index: number) {
    this.files = this.files.filter((_, i) => i != index);
  }

  cancel() {
    this.$emit("cancel", false);
  }
}
