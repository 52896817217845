













import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import noticeTable from "@/components/notice/noticeTable.vue";
import noticeFilter from "@/components/notice/noticeFilter.vue";
import fixedButtonContainer from "@/components/basic/fixedButtonContainer.vue";
import issueWriteButton from "@/components/issues/issueWriteButton.vue";
import ScrollUp from "@/components/basic/scrollUp.vue";
import { midnightTimer } from "@/utiles/timer";

const HotelModule = namespace("HotelModule");
const NoticeModule = namespace("NoticeModule");
const Modal = namespace("modal");

@Component({
  components: {
    noticeTable,
    noticeFilter,
    ScrollUp,
    issueWriteButton,
    fixedButtonContainer,
  },
})
export default class Notice extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;

  @Modal.Action("showModal") showModal: any;

  @NoticeModule.Action("loadNoticeList") loadNoticeList: any;
  @NoticeModule.Action("loadNoticeTopList") loadNoticeTopList: any;
  @NoticeModule.Action("loadNoticeCount") loadNoticeCount: any;
  @NoticeModule.Mutation("setNoticeFilterKeywordType") setNoticeFilterKeywordType: any;
  @NoticeModule.Mutation("resetNoticeFilter") resetNoticeFilter: any;

  filter: any = {
    current: 1,
    skip: 0,
    take: 10,
    keyword: "",
    keywordType: "title",
    teamObjectIds: [],
    categories: [],
    startDate: null,
    endDate: null,
  };

  mounted() {
    this.load();
    midnightTimer(this.loadNoticeTopList, 10, 60000);
  }

  async load() {
    await Promise.all([
      this.loadNoticeList({ filter: this.filter }),
      this.loadNoticeTopList(),
      this.loadNoticeCount({ filter: this.filter }),
    ]);
  }

  write() {
    this.showModal({
      type: "write-notice-modal",
      transparent: true,
      maskClosable: false,
      events: {
        load: () => {
          this.load();
        },
      },
    });
  }
}
