















import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FindAccountForm from "@/components/account/findAccountForm.vue";

const HotelModule = namespace("HotelModule");

@Component({
  components: {
    FindAccountForm,
  },
})
export default class FindId extends Vue {
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  result: any = null;

  verified(value: any) {
    this.result = value;
  }

  toLogin() {
    this.$router.push({ name: "root" });
  }

  toFindPassword() {
    this.$router.push({ name: "find-password" });
  }
}
