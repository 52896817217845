



















import { Vue, Component } from "vue-property-decorator";
import BaseTerm from "@/components/terms/baseTerm.vue";
import PrivateRumy from "@/components/terms/privateRumy.vue";
import TermsOfUse from "@/components/terms/termsOfUse.vue";

@Component({
  components: {
    BaseTerm,
    PrivateRumy,
    TermsOfUse,
  },
})
export default class Terms extends Vue {
  termsOfUseAgreement = false;
  privacyAgreement = false;

  get allChecked() {
    return this.termsOfUseAgreement && this.privacyAgreement;
  }

  goBack() {
    this.$router.back();
  }

  next() {
    if (this.allChecked) {
      this.$emit("next");
    }
  }
}
