export const anchorRegex = /@([^\s\@]*)/;
export const linkRegex = /(https?:\/\/\S+)/;
export const newLineRegex = /\r?\n/;
export const contentRegex = [
  { name: "mention", regex: anchorRegex, offset: 1 },
  { name: "link", regex: linkRegex, offset: 0 },
];

const getType = (node: Node) => {
  if (node.nodeType !== Node.ELEMENT_NODE) {
    return "text";
  }

  switch (node.nodeName) {
    case "BODY":
      return "doc";

    case "P":
      return "paragraph";

    case "BR":
      return "text";

    case "SPAN":
      return "text";
  }

  return "";
};

export const htmlToJson = (node: Node) => {
  let result: any = {
    type: getType(node),
  };

  if (node.nodeType === Node.TEXT_NODE) {
    result.text = node.textContent;
  }

  if (node.childNodes.length) {
    switch (node.nodeName) {
      case "SPAN":
        result.text = "";
        break;

      default:
        result.content = [];
        break;
    }
  } else {
    switch (node.nodeName) {
      case "P":
        result.content = [node.textContent];
        break;

      case "SPAN":
        result.text = node.textContent;
        break;
    }
  }

  for (let i = 0; i < node.childNodes.length; i++) {
    const child = node.childNodes[i];

    switch (result.type) {
      case "text":
        result.text += htmlToJson(child).text;
        break;

      default:
        result.content = [...result.content, htmlToJson(child)];
        break;
    }
  }

  return result;
};

export const htmlToText = (node: Node) => {
  const json = htmlToJson(node);
  const text = jsonToText(json);
  const feff = new RegExp(String.fromCharCode(65279), "g");

  return text.replace(feff, "");
};

export const textToJson = (memberList: any[], contents: string, depth: number = 0): any => {
  if (depth === 0) {
    return {
      type: "doc",
      content: newLineRegex.test(contents)
        ? textToJson(memberList, contents, depth + 1)
        : [
            {
              type: "paragraph",
              content: textToJson(memberList, contents, depth + 1),
            },
          ],
    };
  }

  if (newLineRegex.test(contents)) {
    return contents.split(newLineRegex).map(item => {
      return item === ""
        ? { type: "paragraph" }
        : {
            type: "paragraph",
            content: textToJson(memberList, item, depth + 1),
          };
    });
  } else {
    for (let i in contentRegex) {
      const { name, regex, offset } = contentRegex[i];
      if (regex.test(contents)) {
        let result: any[] = [];
        const match = contents.match(regex);

        if (!match || match.index === undefined) {
          continue;
        }

        if (match.index > 0) {
          const beforeText = contents.substr(0, match.index);
          result = [...result, ...textToJson(memberList, beforeText, depth + 1)];
        }

        const anchorText = contents.substr(match.index + offset, match[1].length);
        const member = memberList.find((item: any) => item.name === anchorText);
        result = [
          ...result,
          {
            type: name,
            attrs: {
              id: member ? member.objectId : anchorText,
              label: anchorText,
            },
          },
        ];

        if (match.index + offset + match[1].length < contents.length) {
          const afterText = contents.substr(match.index + offset + match[1].length);
          result = [...result, ...textToJson(memberList, afterText, depth + 1)];
        }

        return result;
      }
    }
  }

  return [{ type: "text", text: contents }];
};

export const jsonToText = (json: any): any => {
  switch (json.type) {
    case "doc":
      return json.content
        .map((item: any) => jsonToText(item))
        .join("\n")
        .trim();

    case "paragraph":
      return json.content ? json.content.map((item: any) => jsonToText(item)).join("") : "";

    case "text":
      return json.text;

    case "mention":
      return "@" + json.attrs.label;

    case "link":
      return json.attrs.label;
  }
};

export const jsonToHtml = (json: any, ignoreNewLine: boolean = false): any => {
  switch (json.type) {
    case "doc":
      return json.content.map((item: any) => jsonToHtml(item, ignoreNewLine)).join("");

    case "paragraph": {
      const paragraph = json.content
        ? json.content.map((item: any) => jsonToHtml(item, ignoreNewLine)).join("")
        : ignoreNewLine
        ? ""
        : "<br>";

      return ignoreNewLine ? `<span>${paragraph}</span>` : `<p>${paragraph}</p>`;
    }

    case "text":
      return `<span>${json.text.replace("<", "&lt;")}</span>`;

    case "mention":
      return `<span class="readonly-mention">@${json.attrs.label}</span>`;

    case "link":
      return ignoreNewLine
        ? `<a title="${json.attrs.label}">${json.attrs.label}</a>`
        : `<a href=${json.attrs.label} target="_blank" title="${json.attrs.label}">${json.attrs.label}</a>`;
  }
};
