var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-hotel",class:{ 'theme-dark': _vm.dark, 'theme-wide': _vm.wide }},[_c('left-tab'),_c('hotel-header',{attrs:{"value":_vm.selectedTab},on:{"change":_vm.change}}),_c('right-tab',{attrs:{"value":_vm.selectedTab,"open":_vm.open},on:{"toggle":_vm.toggle}}),_c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapper"},[(_vm.hotelInfo.objectId != null)?_vm._t("default"):_vm._e()],2),_c('div',{staticClass:"ad"},[_c('a-carousel',{attrs:{"dot-position":"bottom","autoplay":true,"autoplaySpeed":15000}},_vm._l((_vm.ads),function(item){return _c('a',_vm._b({key:item.objectId,style:({
            backgroundImage: ("url('" + (_vm.imageUrl(item.fileUrl)) + "')"),
          })},'a',
            item.hasLink
              ? {
                  href: (_vm.baseUrl + "/advertisement/linkTo/" + (item.objectId)),
                  target: '_blank',
                }
              : {}
          ,false))}),0)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }