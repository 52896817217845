import login from "./login.vue";

export default [
  {
    layout: "account-layout",
    name: "admin-login",
    path: "/admin",
    component: login,
  },
];
