import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Auth } from "@/http/api/auth";
import { router } from "@/main";

const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,20}$/;

@Module({
  namespaced: true,
})
class passwordChange extends VuexModule {
  passwordChangeForm: any = {
    password: "",
    newPassword: "",
    newPasswordCheck: "",
    PwdErrorMessage: "",
    NewPwdErrorMessage: "",
    NewPwdCheckErrorMessage: "",
  };
  @Action
  async passwordChange() {
    const { password, newPassword, newPasswordCheck } = this.passwordChangeForm;

    if (!passwordRegex.test(newPassword)) {
      this.passwordChangeForm.NewPwdErrorMessage =
        "*문자+숫자+특수문자가 포함 된 6자리 이상을 입력해주세요.";
    }

    if (newPassword != newPasswordCheck && newPasswordCheck) {
      this.passwordChangeForm.NewPwdCheckErrorMessage = "비밀번호가 일치하지 않습니다.";
    }

    await Auth.passwordChange(password, newPassword).then(
      () => {
        alert("변경완료 되었습니다.");
      },
      () => {
        this.passwordChangeForm.PwdErrorMessage = "현재 비밀번호가 같지 않습니다.";
      },
    );
  }
}

export default passwordChange;
