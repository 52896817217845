




import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import selectHotel from "@/pages/home/selectHotel.vue";

const HotelModule = namespace("HotelModule");

@Component({
  components: {
    selectHotel,
  },
})
export default class HotelListSettings extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;

  async created() {
    await this.loadInfoAsync();
  }
}
