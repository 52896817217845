import Client from "../client";

export class NoticeReply {
  static async replyList(parentObjectId: string) {
    return await Client.get<any[]>(`/noticeReply/${parentObjectId}`);
  }

  static async createReply({ parentObjectIds, contents, files }: any) {
    return await Client.put(`/noticeReply/${parentObjectIds}`, {
      contents,
      files,
    });
  }

  static async updateReply({ parentObjectIds, objectId, contents, files }: any) {
    return await Client.post(`/noticeReply/${parentObjectIds}/${objectId}`, {
      contents,
      files,
    });
  }

  static async deleteReply(parentObjectIds: string, objectId: string) {
    return await Client.del(`/noticeReply/${parentObjectIds}/${objectId}`);
  }
}
