









import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class SupportMenu extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;

  menus = [
    {
      key: "support-dashboard",
      title: "대시보드",
    },
    {
      key: "support-hotel",
      title: "호텔 관리",
    },
    {
      key: "support-user",
      title: "유저 관리",
    },
    {
      key: "support-issue",
      title: "이슈 관리",
    },
    {
      key: "support-notice",
      title: "게시글 관리",
    },
    {
      key: "support-advertisement",
      title: "광고 관리",
    },
    {
      key: "support-popup",
      title: "팝업 관리",
    },
    {
      key: "support-version",
      title: "버전 관리",
    }
  ];

  get activeKey() {
    return this.menus.findIndex((item: any) => {
      if (item.key !== this.$route.name) {
        return false;
      }

      if (
        item.params &&
        this.$route.params &&
        Object.keys(item.params).some((key: any) => item.params[key] !== this.$route.params[key])
      ) {
        return false;
      }

      return true;
    });
  }
}
