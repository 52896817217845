




































































import { Component, Prop, Vue } from "vue-property-decorator";
import { App } from "@/http/api/v2/supporter/app";
import { AppVersionCreateDto, AppVersionDto, AppVersionUpdateDto } from "@/http/dto/app";
import moment from "moment";

const DEFAULT_VERSION_FORM = {
  versionCode: "",
  isMandatory: false,
} as const;

@Component({})
export default class SupportVersion extends Vue {
  formModalVisible = false;

  loading = false;
  data: AppVersionDto[] = [];
  total = 0;
  current = 1;

  submitLoading = false;
  selectedVersionObjectId: string | null = null;
  versionForm: AppVersionCreateDto | AppVersionUpdateDto = {
    versionCode: "",
    isMandatory: false,
  };

  versionFormRules = {
    versionCode: [{ required: true, message: "버전 코드를 입력해주세요." }],
  };

  get columns() {
    return [
      {
        title: "버전",
        dataIndex: "versionCode",
        scopedSlots: {
          customRender: "versionCode",
        },
      },
      {
        title: "필수",
        dataIndex: "isMandatory",
        scopedSlots: {
          customRender: "isMandatory",
        },
      },
      {
        title: "생성일",
        dataIndex: "createdTime",
        customRender: (value: any) => {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    ];
  }

  get pagination() {
    return {
      total: this.total,
      pageSize: 20,
      current: this.current,
    };
  }

  get formModalTitle() {
    if (this.selectedVersionObjectId) {
      const selectedVersion = this.data.find(
        (item) => item.objectId === this.selectedVersionObjectId,
      );

      return `버전 수정 (${selectedVersion?.versionCode})`;
    }

    return "새 버전 추가";
  }

  get formSubmitText() {
    if (this.selectedVersionObjectId) {
      return `버전 수정`;
    }

    return "새 버전 추가";
  }

  mounted() {
    this.fetchVersions();
  }

  setFormModalVisible(visible: boolean) {
    this.formModalVisible = visible;
  }

  async fetchVersions(pagination?: Record<string, unknown>) {
    try {
      this.loading = true;

      const { current = 1 } = pagination || {};
      const { items, total } = await App.getAppVersions({
        skip: ((current as number) - 1) * this.pagination.pageSize,
        take: this.pagination.pageSize,
      });

      if (Array.isArray(items)) {
        this.data = items;
        this.total = total;
      }
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`버전 리스트를 불러오지 못했습니다. ${message}`);
          return;
        }
      }

      this.$message.error(`버전 리스트를 불러오지 못했습니다.`);
    } finally {
      this.loading = false;
    }
  }

  editVersion(objectId: string) {
    const selectedVersion = this.data.find((item) => item.objectId === objectId);
    if (!selectedVersion) {
      return;
    }

    this.selectedVersionObjectId = objectId;
    this.versionForm = {
      versionCode: selectedVersion.versionCode,
      isMandatory: selectedVersion.isMandatory,
    };

    this.setFormModalVisible(true);
  }

  onSubmit() {
    (this.$refs.form as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (this.selectedVersionObjectId) {
        await this.updateVersion(this.selectedVersionObjectId, this.versionForm);
      } else {
        await this.createVersion(this.versionForm);
      }
    });
  }

  async createVersion(form: AppVersionCreateDto) {
    try {
      this.submitLoading = true;
      await App.createAppVersion(form);
      this.$message.success("버전을 추가했습니다.");
      this.setFormModalVisible(false);
      this.clearVersionForm();
      this.refresh();
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`버전을 추가하지 못했습니다. ${message}`);
          return;
        }
      }

      this.$message.error(`버전을 추가하지 못했습니다.`);
    } finally {
      this.submitLoading = false;
    }
  }

  async updateVersion(objectId: string, form: AppVersionUpdateDto) {
    try {
      this.submitLoading = true;
      await App.updateAppVersion(objectId, form);
      this.$message.success("버전을 수정했습니다.");
      this.setFormModalVisible(false);
      this.clearVersionForm();
      this.refresh();
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`버전을 수정하지 못했습니다. ${message}`);
          return;
        }
      }

      this.$message.error(`버전을 수정하지 못했습니다.`);
    } finally {
      this.submitLoading = false;
    }
  }

  async deleteVersion(objectId: string) {
    try {
      this.submitLoading = true;
      await App.deleteAppVersion(objectId);
      this.$message.success("버전을 삭제했습니다.");
      this.setFormModalVisible(false);
      this.clearVersionForm();
      this.refresh();
    } catch (ex) {
      if (ex.response) {
        const { data } = ex.response;
        const message = data && (data.error || data.Error);
        if (message && message.length < 100) {
          this.$message.error(`버전을 삭제하지 못했습니다. ${message}`);
          return;
        }
      }

      this.$message.error(`버전을 삭제하지 못했습니다.`);
    } finally {
      this.submitLoading = false;
    }
  }

  clearVersionForm() {
    this.selectedVersionObjectId = null;
    this.versionForm = {
      ...DEFAULT_VERSION_FORM,
    };
  }

  closeFormModal() {
    this.setFormModalVisible(false);
    this.clearVersionForm();
  }

  refresh() {
    this.data = [];
    this.total = 0;
    this.current = 1;

    this.fetchVersions();
  }
}
