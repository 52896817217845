



























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { Support } from "@/http/api/support";
import moment from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class UserManagement extends Vue {
  @HotelModule.State("hotelObjectId") hotelObjectId: any;

  get hotelObjectIdValue() {
    return this.hotelObjectId;
  }

  @Watch("hotelObjectIdValue")
  hotelObjectIdChanged() {
    this.load();
  }

  get rules() {
    return {
      password: [
        {
          required: true,
          message: "비밀번호를 입력해주세요.",
        },
      ],
      confirm: [
        {
          required: true,
          message: "비밀번호를 한 번 더 입력해주세요.",
        },
      ],
    };
  }

  changePassword: any = null;

  columns = [
    {
      title: "권한",
      dataIndex: "role",
      scopedSlots: { customRender: "role" },
    },
    {
      title: "아이디",
      dataIndex: "id",
    },
    {
      title: "유저명",
      dataIndex: "name",
    },
    {
      align: "right",
      title: "마지막 접속시간",
      dataIndex: "lastChallengeTime",
      customRender: (value: any) => (value ? moment(value).format("YYYY/MM/DD HH:mm:ss") : value),
    },
    {
      align: "right",
      title: "가입일",
      dataIndex: "createdTime",
      customRender: (value: any) => moment(value).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      align: "right",
      title: "비밀번호 변경",
      scopedSlots: { customRender: "password" },
    },
  ];

  get pagination() {
    return {
      total: this.total,
      pageSize: 20,
      current: this.current,
    };
  }

  loading = false;
  data = [];
  total = 0;
  current = 1;

  keyword = "";

  mounted() {
    this.load();
  }

  async load(pagination?: any) {
    try {
      this.loading = true;

      const { current = 1 } = pagination || {};
      const { count, list } = await Support.users({
        page: current,
        keyword: this.keyword,
        hotelObjectId: this.hotelObjectId,
      });
      this.current = current;
      this.total = count;
      this.data = list;
    } catch {
    } finally {
      this.loading = false;
    }
  }

  openChangePassword(item: any) {
    const { objectId } = item;
    this.changePassword = { objectId };
  }

  closeChangePassword() {
    this.changePassword = null;
  }

  async applyChangePassword() {
    const form: any = this.$refs.form;
    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (!this.changePassword.password) {
        this.$message.warning("비밀번호를 입력해주세요.");
        return;
      }

      if (!this.changePassword.confirm) {
        this.$message.warning("비밀번호를 한 번 더 입력해주세요.");
        return;
      }

      if (this.changePassword.password != this.changePassword.confirm) {
        this.$message.warning("입력하신 서로 비밀번호가 다릅니다.");
        return;
      }

      try {
        await Support.password(this.changePassword.objectId, this.changePassword.password);
        this.$message.success("비밀번호가 변경되었습니다.");
        this.closeChangePassword();
      } catch {
        this.$message.error("비밀번호 변경에 실패하였습니다.");
      }
    });
  }
}
