import "es6-promise/auto";
import "babel-polyfill";

import Vue from "vue";
import VueRouter from "vue-router";
import Antd from "ant-design-vue";

import app from "./app.vue";
import routes from "./routes";
import store from "./store/store";
import modals from "./modals";
import moment from "moment";

import "ant-design-vue/dist/antd.less";

Vue.config.productionTip = false;

window.document.addEventListener("keydown", e => {
  if (e.keyCode === 8 && e.target) {
    const target = e.target as HTMLElement;

    if (
      target.tagName !== "INPUT" &&
      target.tagName !== "TEXTAREA" &&
      !target.attributes.getNamedItem("contenteditable")
    ) {
      e.preventDefault();
    }
  }
});

moment.locale("ko");

Vue.use(Antd);
Vue.use(VueRouter);
Vue.use(modals as any);

export const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

new Vue({
  router,
  store,
  render: h => h(app),
}).$mount("#app");
