
































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import bookmarkCard from "@/components/layouts/bookmarkCard.vue";
import _ from "lodash";

const CollectionModule = namespace("CollectionModule");

@Component({
  components: {
    bookmarkCard,
  },
})
export default class BookmarkTab extends Vue {
  @CollectionModule.State("bookmarkOrder") bookmarkOrder: any;
  @CollectionModule.State("bookmarkList") bookmarkList: any;
  @CollectionModule.Action("loadBookmarks") loadBookmarks: any;

  mounted() {
    this.load(true, "");
    this.addScrollEvent(this.scrollView);
  }

  async load(clear: boolean, order: string) {
    this.loadBookmarks({ clear, order });
  }

  async change(value: any) {
    await this.load(true, value);
  }

  get scrollView() {
    return this.$refs.scrollView as any;
  }

  addScrollEvent(scrollView: any) {
    scrollView.addEventListener("scroll", this.throttle);
  }

  destroyed() {
    this.destroyScrollEvent(this.scrollView);
  }

  destroyScrollEvent(scrollView: any) {
    scrollView.removeEventListener("scroll", this.throttle);
  }

  handleScroll() {
    const scrollTop = (this.scrollView && this.scrollView.scrollTop) || this.scrollView.scrollTop;
    const bottomOfWindow = scrollTop + this.scrollView.clientHeight;

    if (this.scrollView.scrollHeight - bottomOfWindow < 150) {
      this.load(false, this.bookmarkOrder);
    }
  }

  throttle = _.throttle(this.handleScroll, 500);
}
