import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

export interface IShowModalProps {
  maskClosable?: boolean;
  keyboard?: boolean;
  type: string;
  props?: any;
  events?: any;
  onCancel?: any;
  onOk?: any;
  transparent?: boolean;
}

@Module({
  namespaced: true,
})
export default class modal extends VuexModule {
  notice = false;

  modal: any = {
    width: 720,
    visibility: false,
    text: "",
    type: "",
    props: null,
    events: null,
    maskClosable: true,
    keyboard: true,
  };

  @Mutation
  setModal(value: any) {
    this.modal = {
      ...this.modal,
      ...value,
    };
  }

  @Action
  showModal(modalProps: IShowModalProps | string) {
    if (!this.modal.visibility) {
      document.body.classList.add("hidden");
    }

    if (typeof modalProps === "string") {
      this.context.commit("setModal", {
        // width: 720,
        visibility: true,
        type: modalProps,
        props: null,
        maskClosable: true,
        keyboard: true,
        onCancel: undefined,
        onOk: undefined,
      });
    } else {
      this.context.commit("setModal", {
        // width: 720,
        visibility: true,
        type: modalProps.type,
        props: modalProps.props,
        events: modalProps.events,
        maskClosable: modalProps.maskClosable === undefined ? true : modalProps.maskClosable,
        keyboard: modalProps.keyboard === undefined ? true : modalProps.keyboard,
        onCancel: modalProps.onCancel,
        onOk: modalProps.onOk,
        wrapClassName: modalProps.transparent ? "transparent" : "",
      });
    }
  }

  @Action
  handleOk() {
    document.body.classList.remove("hidden");
    this.context.commit("setModal", {
      visibility: false,
    });

    if (this.modal.onOk) {
      this.modal.onOk();
    }
  }

  @Action
  handleCancel({ forceClose }: any) {
    if (!forceClose && this.modal.onCancel) {
      return this.modal.onCancel();
    } else {
      document.body.classList.remove("hidden");
      this.context.commit("setModal", {
        visibility: false,
      });
    }
  }

  // @Action
  // handleModalWidth({ width }: any) {
  //   this.context.commit("setModal", {
  //     width: width,
  //   });
  // }
}
