



























import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Mutation, namespace } from "vuex-class";

@Component({
  components: {},
})
export default class CheckList extends Vue {
  @Prop({ default: () => [] }) data: any;

  moment = moment;

  get value() {
    const complated = this.data.filter((item: any) => item.checked).length;
    const total = this.data.length;
    return Math.floor((complated / total) * 100) + "%";
  }

  createdTime(item: any) {
    return item.updatedTime
      ? moment(item.updatedTime).format("MM/DD HH:mm")
      : moment(item.createdTime).format("MM/DD HH:mm");
  }

  change(item: any) {
    this.$emit("change", item);
  }
}
