



























import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dev from "@/components/dev.vue";
import routes from "./routes";
import HotelLayout from "@/pages/layout/hotelLayout.vue";
import HomeLayout from "@/pages/layout/homeLayout.vue";
import AdminLayout from "@/pages/layout/adminLayout.vue";
import AccountLayout from "@/pages/layout/accountLayout.vue";
import SupportLayout from "@/pages/layout/supportLayout.vue";

const Modal = namespace("modal");

@Component({
  components: {
    dev,
    HotelLayout,
    HomeLayout,
    AdminLayout,
    AccountLayout,
    SupportLayout,
  },
})
export default class App extends Vue {
  @Modal.State("modal") modal: any;
  @Modal.Action("handleOk") handleOk: any;
  @Modal.Action("handleCancel") handleCancel: any;

  get route() {
    return routes.find((item: any) => item.name === this.$route.name);
  }

  get layout() {
    return (this.route && this.route.layout) || "div";
  }

  get layoutOptions() {
    return (this.route && this.route.layoutOptions) || {};
  }
}
