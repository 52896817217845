






































































































































import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Mutation, namespace } from "vuex-class";
import ReadCount from "@/components/issues/readCountLegacy.vue";
import IssueCardTeam from "@/components/issues/issueCardTeam.vue";
import IssueReply from "@/components/issues/issueReply.vue";
import IssueReplyInput from "@/components/issues/issueReplyInput.vue";
import IssueReadonlyContents from "@/components/issues/issueReadonlyContents.vue";
import fileList from "@/components/fileList.vue";
import checkList from "@/components/checkList.vue";
import { textToJson } from "@/utiles/contentsParser";
import { Issue } from "@/http/api/issue";

const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const Modal = namespace("modal");

const dateFormat = "MM/DD HH:mm";

const status = [
  {
    color: "#ff5371",
    text: "새이슈",
    image: require("../../../public/assets/components/icon-issue-new.svg"),
  },
  {
    color: "#ffaa00",
    text: "진행중",
    image: require("../../../public/assets/components/icon-issue-ing.svg"),
  },
  {
    color: "#0acf9d",
    text: "완료",
    image: require("../../../public/assets/components/icon-issue-end.svg"),
  },
  {
    color: "#4c4c4c",
    text: "보류",
    image: require("../../../public/assets/components/icon-issue-holding.svg"),
  },
];

@Component({
  components: {
    ReadCount,
    IssueCardTeam,
    IssueReply,
    IssueReplyInput,
    IssueReadonlyContents,
    fileList,
    checkList,
  },
})
export default class IssueCard extends Vue {
  @Prop() data: any;
  @Prop({ default: false }) defaultOpen: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @IssueModule.State("issueReplyMap") issueReplyMap: any;

  @IssueModule.Action("updateRead") updateRead: any;
  @IssueModule.Action("updateStatus") updateStatus: any;
  @IssueModule.Action("updateImportantAsync") updateImportantAsync: any;
  @IssueModule.Action("updateComplainAsync") updateComplainAsync: any;
  @IssueModule.Action("updateBookmar") updateBookmar: any;
  @IssueModule.Action("issueBookmark") issueBookmark: any;
  @IssueModule.Action("updateChecklist") updateChecklist: any;
  @IssueModule.Action("loadIssue") loadIssue: any;
  @IssueModule.Action("loadIssueReplies") loadIssueReplies: any;
  @IssueModule.Action("deleteIssue") deleteIssue: any;
  @IssueModule.Action("loadIssueList") loadIssueList: any;

  @Modal.Action("showModal") showModal: any;

  moment: any = moment;

  show: boolean = false;
  edit: boolean = false;
  open: boolean = false;

  get status() {
    return status[this.data.status];
  }

  get permission() {
    if (this.hotelInfo == null || this.data == null) {
      return false;
    }

    if (this.hotelInfo.userObjectId === this.data.writerObjectId) {
      return true;
    }

    if (this.hotelInfo.userPermission === 3) {
      return true;
    }

    if (this.hotelInfo.userPermission === 2 && this.data.permission !== 3) {
      return true;
    }

    return false;
  }

  get contents() {
    return textToJson([], this.data.contents);
  }

  get date() {
    // FIXME: reserved start time 으로 검사 할 수 있도록 변경
    return !this.data.isChanged
      ? `${moment(this.data.displayingCreatedTime).format(dateFormat)} (${
          this.data.createdTime !== this.data.displayingCreatedTime ? "예약됨" : "등록됨"
        })`
      : `${moment(this.data.updatedTime).format(dateFormat)} (수정됨)`;
  }

  get reservedInfo() {
    const {
      reservedStartDate,
      reservedEndDate,
      reservedStartTime,
      reservedEndTime,
      reservedPlace,
    } = this.data;

    if (!reservedStartDate) {
      return "";
    }

    let result: any[] = [];

    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "HH:mm";

    if (reservedStartDate) {
      result = [...result, moment(reservedStartDate).format(dateFormat)];
    }

    if (reservedStartTime) {
      result = [...result, moment(reservedStartTime, "HH:mm").format(timeFormat)];
    }

    if (reservedEndDate || reservedEndTime) {
      result = [...result, "~"];
    }

    if (reservedEndDate) {
      result = [...result, moment(reservedEndDate).format(dateFormat)];
    }

    if (reservedEndTime) {
      result = [...result, moment(reservedEndTime, "HH:mm").format(timeFormat)];
    }

    if (reservedPlace) {
      result = [...result, reservedPlace];
    }

    return result.join(" ");
  }

  mounted() {
    this.open = this.defaultOpen;

    if (this.open) {
      this.load();
    }
  }

  async load() {
    const { objectId } = this.data;
    await Promise.all([this.loadIssue(objectId), this.loadIssueReplies(objectId)]);
  }

  async toggleOpen() {
    this.open = !this.open;

    if (this.open) {
      this.updateRead(this.data.objectId);
      this.load();
    }
  }

  async toggleBookmark() {
    const { objectId } = this.data;

    await this.issueBookmark({
      objectId,
      value: !this.data.isBookmarked,
    });

    this.$emit("change", objectId);
  }

  duplicateIssue() {
    this.showModal({
      type: "write-issue-modal",
      transparent: true,
      maskClosable: false,
      props: {
        defaultData: {
          ...this.data,
          objectId: null,
        },
      },
      onOk: () => this.$emit("change"),
    });
  }

  updateIssue() {
    this.showModal({
      type: "write-issue-modal",
      transparent: true,
      maskClosable: false,
      props: { defaultData: this.data },
      onOk: () => this.$emit("change"),
    });
  }

  async confirmDeleteIssue() {
    const { objectId } = this.data;
    try {
      await this.deleteIssue(objectId);
      this.$message.success("이슈 삭제에 성공하였습니다.");
      await this.loadIssueList({ filter: {}, clear: false });
    } catch {
      this.$message.error("이슈 삭제에 실패하였습니다.");
    }
  }

  showChangeLog() {
    const { objectId } = this.data;
    this.showModal({
      type: "issue-change-log-modal",
      transparent: true,
      props: { objectId },
    });
  }

  async changeStatus() {
    const { objectId, status } = this.data;
    await this.updateStatus({
      objectId,
      status: (status + 1) % 4,
    });
    this.$emit("change");
  }

  async changeCheckList(item: any) {
    const { objectId, contents, isDone } = item;
    await Issue.updateChecklist(this.data.objectId, objectId, contents, !isDone);
  }

  moveToWork() {
    this.showModal({
      type: "select-work-category-modal",
      props: { data: this.data },
    });
  }
}
