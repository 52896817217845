




























































































import Vue from "vue";
import issueCardTeam from "@/components/issues/issueCardTeam.vue";
import moment from "moment";
import Component from "vue-class-component";
import { State, Action, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import Attachment from "@/components/common/attachment.vue";
import CheckList from "@/components/checkList.vue";
import { Issue } from "@/http/api/issue";

const IssueModule = namespace("IssueModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

const IssueStatus: any = {
  Registered: "새 이슈",
  Notified: "진행 중",
  Solved: "완료",
  Pending: "보류",
};

@Component({
  name: "IssueChangeLogModal",
  components: {
    issueCardTeam,
    Attachment,
    CheckList,
  },
})
export default class IssueChangeLogModal extends Vue {
  @Prop() objectId!: string;

  @IssueModule.State("issueDetail") issueDetail: any;
  @IssueModule.Action("loadIssueChangeLogAsync") loadIssueChangeLogAsync: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  @Modal.State("modal") modal: any;
  @Modal.Action("handleOk") handleOk: any;
  @Modal.Action("handleCancel") handleCancel: any;

  moment: any = moment;

  public dataList: any[] = [];

  mounted() {
    this.load();
  }

  async load() {
    await this.loadMemberListAsync();

    const list = await Issue.changeLogs(this.objectId);
    this.dataList = list.reduce(this.groupedListReducer, []).map(this.groupedListMapper);
  }

  groupedListMapper(item: any) {
    return {
      ...item,
      key: item.key,
      list: item.list.map((i: any) => {
        return { ...i, details: i.details.map(this.detailMapper) };
      }),
    };
  }

  isUsedDetail(detail: any) {
    switch (detail.key) {
      case "BookmarkedMemberObjectIds":
        return false;
      case "DisplayingCreatedTime":
        return false;
      case "SetTopTime":
        return false;
      default:
        return true;
    }
  }

  isUsedDetailGroup(list: any[]) {
    const keys = list[0].details.map((detail: any) => detail.key);
    const filteredKeys = keys.filter(
      (key: string) => key != "BookmarkedMemberObjectIds" && key != "SetTopTime",
    );
    if (filteredKeys.length > 0) {
      return true;
    }
    return false;
  }

  detailMapper(detail: any) {
    if (detail) {
      const text = this.filter(detail);

      switch (detail.key) {
        case "Images":
        case "Teams":
        case "NewReplyImages":
          return {
            ...detail,
            text,
            newValue: JSON.parse(detail.newValue),
          };

        case "Anchors": {
          const anchorObjectIds = JSON.parse(detail.newValue);
          return {
            ...detail,
            text,
            newValue: this.memberList.filter((item: any) =>
              anchorObjectIds.includes(item.objectId),
            ),
          };
        }

        default:
          return {
            ...detail,
            text,
          };
      }
    }

    return detail;
  }

  getMemberName(objectId: string) {
    return this.memberList.find((m: any) => m.objectId == objectId).name;
  }

  filter(detail: any) {
    const { key, newValue, oldValue } = detail;

    switch (key) {
      case "Status":
        return "상태를 '" + IssueStatus[newValue] + "' 로 변경하였습니다.";

      case "Reply":
        return "댓글을 작성하였습니다.";

      case "IsImportant":
        return `중요 상태를 ${newValue === "True" ? "설정" : "취소"} 하였습니다.`;

      case "IsComplain":
        return `긴급 상태를 ${newValue === "True" ? "설정" : "취소"} 하였습니다.`;

      case "Category":
        return `분류를 '${newValue}' 로 설정하였습니다.`;

      case "Contents":
        return "내용을 작성하였습니다.";

      case "Images":
        return "이미지를 등록하였습니다.";

      case "Files":
      case "NewReplyFiles":
        return "파일을 수정하였습니다.";

      case "Teams":
        return "해당 부서를 선택하였습니다.";

      case "Anchors":
        return "해당 유저를 태그하였습니다.";

      case "Mentions":
        return "해당 유저를 태그하였습니다.";

      case "ReservedStartDate":
        return newValue != ""
          ? `예약 시작일을 ${moment(newValue).format("YYYY-MM-DD")}로 설정하였습니다.`
          : "예약 시작일을 제거하였습니다.";

      case "ReservedStartTime":
        return newValue != ""
          ? `예약 시작시간을 ${moment(newValue).format("HH:mm")}로 설정하였습니다.`
          : "예약 시작시간을 제거하였습니다.";

      case "ReservedEndDate":
        return newValue != ""
          ? `예약 종료일을 ${moment(newValue).format("YYYY-MM-DD")}로 설정하였습니다.`
          : "예약 종료일을 제거하였습니다.";

      case "ReservedEndTime":
        return newValue != ""
          ? `예약 종료시간을 ${moment(newValue).format("HH:mm")}로 설정하였습니다.`
          : "예약 종료시간을 제거하였습니다.";

      case "ReservedPlace":
        return `예약 장소를 '${newValue}'(으)로 설정하였습니다.`;

      case "NewReplyContents":
        return "댓글을 작성하였습니다.";

      case "UpdateReplyContents":
        return "댓글을 수정하였습니다.";

      case "NewReplyImages":
        return "댓글에 이미지를 추가하였습니다.";

      case "IsSecret":
        return newValue == "True" ? "'비밀글'로 설정하였습니다." : "'비밀글'을 해제하였습니다.";

      case "Todos":
        return JSON.parse(newValue).length > 0
          ? "체크리스트를 수정하였습니다."
          : "체크리스트를 삭제하였습니다.";

      default:
        return "";
    }
  }

  groupedListReducer(groups: any[], log: any) {
    const key = moment(log.createdTime).format("YYYY/MM/DD dddd");
    const group = groups.find(g => g.key === key);

    if (!group) {
      return [
        ...groups,
        {
          key,
          list: [log],
        },
      ];
    } else {
      group.list = [...group.list, log];
    }

    return groups;
  }
}

Vue.component("IssueChangeLogModal", IssueChangeLogModal);
