import Client from "../client";

export class Advertisement {
  static async List(key: string) {
    return await Client.get(`/advertisement/${key}`);
  }

  static async All() {
    return await Client.get("/advertisement");
  }

  static async Create(form: any) {
    return await Client.post("/advertisement", form);
  }

  static async Update(form: any) {
    return await Client.put("/advertisement", form);
  }

  static async Delete(id: any) {
    return await Client.del(`/advertisement/${id}`);
  }
}
