import RegisterModule from "./registerModule";
import passwordChange from "./passwordChange";
import modal from "./modal";
import HotelModule from "./hotelModule";
import IssueModule from "./issueModule";
import MemberModule from "./memberModule";
import NoticeModule from "./noticeModule";
import TeamModule from "./teamModule";
import SettingModule from "./settingModule";
import WorkModule from "./workModule";
import CollectionModule from "./collectionModule";
import IssueV2Module from "./v2/issueV2Module";
import IssueReplyV2Module from "./v2/issueReplyV2Module";

export default {
  passwordChange,
  modal,

  // renamed modules
  RegisterModule,
  HotelModule,
  IssueModule,
  MemberModule,
  NoticeModule,
  TeamModule,
  SettingModule,
  WorkModule,
  CollectionModule,

  // V2 Modules
  IssueV2Module,
  IssueReplyV2Module,
};
