import { axiosClient } from "@/http/client";
import { File } from "@/http/api/file";
import axios from "axios";

export const baseURL = axiosClient.defaults.baseURL;

export const linkRegex = /^https?:/;
export const imageBaseUrl = "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/";

export const defaultLogoImage = "/assets/components/rumylogo.png";

export const fileUrl = (url: string) => (linkRegex.test(url) ? url : imageBaseUrl + url);
export const logoUrl = (url: string) => (url ? fileUrl(url) : defaultLogoImage);

export const imageExtensions = ["png", "jpeg", "jpg", "bmp", "gif", "bmp", "heif", "heic"];

export const extension = (path: string) => (/[^.]+$/.exec(path) || [])[0].toLowerCase();
export const isImage = (path: string) => imageExtensions.indexOf(extension(path)) !== -1;
export const fileName = (path: string, isUploaded: boolean = false) => {
  const result = (/\/(.+)$/.exec(path) || [])[1];
  if (isUploaded) {
    return result.replace(/^\d{12,13}_?/, "");
  }

  return result;
};

export const icon = (path: string) => {
  if (isImage(path)) {
    return fileUrl(path);
  }

  switch (extension(path)) {
    case "xls":
    case "xlsm":
    case "xlsx":
    case "csv":
      return "/assets/attachment/xls.svg";

    case "ppt":
    case "pptx":
    case "pptm":
      return "/assets/attachment/ppt.svg";

    case "pdf":
      return "/assets/attachment/pdf.svg";

    case "doc":
    case "docx":
      return "/assets/attachment/doc.svg";

    case "hwp":
      return "/assets/attachment/hwp.svg";

    default:
      return "/assets/attachment/etc.svg";
  }
};

export const uploadIcon = (path: string) => {
  if (isImage(path)) {
    return fileUrl(path);
  }

  switch (extension(path)) {
    case "xls":
    case "xlsm":
    case "xlsx":
    case "csv":
      return "/assets/components/xls.svg";

    case "ppt":
    case "pptx":
    case "pptm":
      return "/assets/components/ppt.svg";

    case "pdf":
      return "/assets/components/pdf.svg";

    case "doc":
    case "docx":
      return "/assets/components/word.svg";

    case "hwp":
      return "/assets/components/hwp.svg";

    case "zip":
      return "/assets/components/zip.svg";

    default:
      return "/assets/components/attachment.svg";
  }
};

export const tagStyle = (targetType: any) => {
  switch (targetType) {
    case "issue":
      return {
        border: "1px solid #87e8de",
        background: "#e6fffb",
        color: "#13c2c2",
      };

    case "issueReply":
      return {
        border: "1px solid #adc6ff",
        background: "#f0f5ff",
        color: "#2f54eb",
      };

    case "notice":
      return {
        border: "1px solid #91d5ff",
        background: "#e6f7ff",
        color: "#1890ff",
      };

    case "noticeReply":
      return {
        border: "1px solid #91d5ff",
        background: "#e6f7ff",
        color: "#1890ff",
      };
  }

  return {};
};

export const tagText = (targetType: any) => {
  switch (targetType) {
    case "issue":
      return "이슈";

    case "issueReply":
      return "댓글";

    case "notice":
      return "게시글";

    case "noticeReply":
      return "댓글";
  }

  return "알수없음";
};

export const fileUpload = async (file: any, maxFileSize?: number) => {
  try {
    if (maxFileSize && file.size >= maxFileSize) {
      throw Error("Large Size");
    }

    const { presignedUrl, url } = await File.getPreSignedUrl(file.name);

    await axios.request({
      url: presignedUrl,
      method: "PUT",
      data: file,
      headers: { "Access-Control-Allow-Origin": "*" },
    });

    return {
      url: url,
      fileName: file.name,
      size: file.size,
    };
  } catch (ex) {
    throw ex;
  }
};
