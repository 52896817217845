












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({
  components: {},
})
export default class SettingItem extends Vue {
  @Prop({}) title: any;
  @Prop({}) content: any;
  @Prop({ default: false }) tooltip: any;
}
