




















import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment, { Moment } from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class WorkFilter extends Vue {
  @Prop() value: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  changeKeyword(e: any) {
    this.$emit("input", {
      ...this.value,
      keyword: e.target.value,
    });

    this.search();
  }

  reset() {
    this.$emit("input", {
      ...this.value,
      keyword: "",
    });

    this.search();
  }

  search() {
    this.$emit("search");
  }
}
