








import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import $ from "jquery";
import { File as FileUpload } from "@/http/api/file";

import "expose-loader?$!expose-loader?jQuery!jquery";
import "summernote/dist/summernote-lite";
import "summernote/dist/summernote-lite.css";
import "summernote/dist/lang/summernote-ko-KR";
import axios from "axios";

const maxFileSize = 1024 * 1024 * 20;

const jquery = $ as any;
jquery.extend(jquery.summernote.lang["ko-KR"].font, {
  sizeunit: "글자 크기 단위",
});

@Component({})
export default class Summernote extends Vue {
  @Prop({ default: () => ({}) }) config: any;
  @Prop() readonly: any;
  @Prop() value: any;

  files: any[] = [];

  summernote: any;

  imageUploadButton = () => {
    const self = this;

    return function (context: any) {
      var jquery = $ as any;
      var ui = jquery.summernote.ui;

      var button = ui.button({
        contents: '<i class="note-icon-picture" />',
        tooltip: "이미지",
        container: "button.note-btn:eq(0)",
        click: () => {
          (self.$refs.fileInput as HTMLElement).click();
        },
      });

      return button.render();
    };
  };

  mounted() {
    const defaultConfig = {
      dialogsInBody: true,
      lang: "ko-KR",
      toolbar: [
        ["style", ["style"]],
        ["fontsize", ["fontname", "fontsize", "fontsizeunit", "color", "clear"]],
        ["table", ["table"]],
        ["media", ["imageUpload", "video", "link"]],
        ["font", ["strikethrough", "superscript", "subscript"]],
        ["para", ["paragraph", "height"]],
        ["list", ["ul", "ol"]],
        ["decoration", ["bold", "italic", "underline", "clear"]],
        ["undo", ["undo", "redo"]],
      ],
      buttons: {
        imageUpload: this.imageUploadButton(),
      },
      fontNames: [
        "Noto Sans KR",
        "Arial",
        "Arial Black",
        "Comic Sans MS",
        "Courier New",
        "Helvetica",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      callbacks: {
        onInit: () => {
          this.$emit("load");
          this.summernote.summernote("code", this.value);
        },
        onChange: (contents: any) => {
          this.$emit("input", contents);
        },
      },
    };

    this.summernote = $(this.$refs.editor);
    this.summernote.summernote({ ...defaultConfig, ...(this.config || {}) });

    if (this.readonly) {
      this.summernote.summernote("disable");
    }
  }

  beforeDestroy() {
    if (this.summernote) {
      this.summernote.summernote("destroy");
      this.summernote = null;
    }
  }

  code() {
    return this.summernote.summernote("code");
  }

  text() {
    const result = this.code()
      .replace(/<\/p>/gi, "\n")
      .replace(/<br\/?>/gi, "\n")
      .replace(/<\/?[^>]+(>|$)/g, "");

    return result;
  }

  async onFileChange(e: Event) {
    const input = e.target as HTMLInputElement;

    try {
      if (!input.files || !input.files[0]) {
        return;
      }

      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];

        if (file.size > maxFileSize) {
          throw { message: "파일 용량이 20MB를 초과하였습니다." };
        }
      }

      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];

        const { presignedUrl, url } = await FileUpload.getPreSignedUrl(file.name);

        await axios.request({
          url: presignedUrl,
          method: "PUT",
          data: file,
          headers: { "Access-Control-Allow-Origin": "*" },
        });

        this.files = [
          ...this.files,
          {
            url: url,
            fileName: file.name,
            size: file.size,
          },
        ];

        this.$emit("changeFile", this.files);

        this.summernote.summernote("insertNode", $(`<img src="${url}" />`)[0]);
      }
    } catch (ex) {
      this.$message.error("파일 업로드에 실패하였습니다.");
    } finally {
      input.value = "";
    }
  }
}
