





































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment, { Moment } from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class IssueFilter extends Vue {
  @Prop() value: any;
  @Prop() isCalendar: any;
  @Prop({ default: false }) isReport: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  open: boolean = false;

  status: any[] = [];
  statusList: any[] = [
    { label: "새이슈", value: 0 },
    { label: "진행중", value: 1 },
    { label: "완료", value: 2 },
    { label: "보류", value: 3 },
  ];

  get checkAll() {
    return this.value.status.length === this.statusList.length;
  }

  get indeterminate() {
    return !!this.value.status.length && this.value.status.length < this.statusList.length;
  }

  disabledStartDate(date: Moment) {
    if (!this.isCalendar && !this.isReport) {
      const tomorrow = moment().startOf("day").add(1, "d");
      return (this.value.endDate && this.value.endDate <= date) || date > tomorrow;
    }
    return null;
  }

  disabledEndDate(date: Moment) {
    if (!this.isCalendar && !this.isReport) {
      const tomorrow = moment().startOf("day").add(1, "d");
      return (this.value.startDate && this.value.startDate >= date) || date > tomorrow;
    }
    return null;
  }

  toggleAll(e: any) {
    this.value.status = e.target.checked ? this.statusList.map((item: any) => item.value) : [];
    this.changeStatus();
  }

  changeStatus() {
    this.$emit("input", {
      ...this.value,
      status: this.value.status,
    });

    this.search();
  }

  toggleNew() {
    this.$emit("input", {
      ...this.value,
      isNew: !this.value.isNew,
    });

    this.search();
  }

  toggleIsMine() {
    this.$emit("input", {
      ...this.value,
      isMine: !this.value.isMine,
    });

    this.search();
  }

  toggleReserved() {
    this.$emit("input", {
      ...this.value,
      isReserved: !this.value.isReserved,
    });

    this.search();
  }

  changeKeyword(e: any) {
    this.$emit("input", {
      ...this.value,
      keyword: e.target.value,
    });
  }

  reset() {
    this.status = [];

    this.$emit("input", {
      ...this.value,
      isNew: false,
      status: [],
      keyword: "",
      keywordType: "contents",
      teamObjectIds: [],
      category: [],
      startDate: null,
      endDate: null,
    });

    this.search();
  }

  search() {
    this.$emit("search");
  }

  toReportIssue() {
    const routeData = this.$router.resolve({
      name: "report-issue",
      query: {
        ...this.value,
        startDate: this.value.startDate?.toISOString(),
        endDate: this.value.endDate?.toISOString(),
      },
    });
    window.open(routeData.href, "_blank");
  }
}
