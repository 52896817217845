import issue from "./issue.vue";
import issueDetail from "./issueDetail.vue";
import reportIssue from "./reportIssue.vue";

export default [
  {
    name: "issue",
    path: "/issue",
    component: issue,
  },
  {
    path: "/teamIssue",
    redirect: "/issue",
  },
  {
    name: "issue-detail",
    path: "/issue/:objectId",
    component: issueDetail,
  },
  {
    layout: null,
    name: "report-issue",
    path: "/reportIssue",
    component: reportIssue,
  },
];
