export enum HotelMemberPermission {
  ANONYMOUS = 0,
  MEMBER = 1,
  MANAGER = 2,
  OWNER = 3,
}

export type MemberDto = {
  objectId: string;
  name: string;
  permission: HotelMemberPermission;
  team?: string;
  teamObjectId?: string;
  teamObjectIds?: string[];
};
