import { render, staticRenderFns } from "./workCard.vue?vue&type=template&id=13b6edfe&scoped=true&"
import script from "./workCard.vue?vue&type=script&lang=ts&"
export * from "./workCard.vue?vue&type=script&lang=ts&"
import style0 from "./workCard.vue?vue&type=style&index=0&id=13b6edfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b6edfe",
  null
  
)

export default component.exports