








































































































































































import Vue from "vue";
import { State, Action, namespace } from "vuex-class";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment, { Moment } from "moment";
import organizationTree from "@/components/layouts/organizationTree.vue";
import { IFileData } from "@/interfaces/File";
import { fileUpload, uploadIcon } from "@/constants";
import Summernote from "@/components/summernote.vue";

const maxFileSize = 1024 * 1024 * 20;

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const NoticeModule = namespace("NoticeModule");
const Modal = namespace("modal");

@Component({
  name: "writeNoticeModal",
  components: {
    organizationTree,
    Summernote,
  },
})
export default class writeNoticeModal extends Vue {
  @Prop() defaultData: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  @NoticeModule.Action("writeNotice") writeNotice: any;
  @NoticeModule.Action("loadNoticeList") loadNoticeList: any;
  @NoticeModule.Action("loadNoticeTopList") loadNoticeTopList: any;

  @Modal.State("modal") modal: any;
  @Modal.Action("handleOk") handleOk: any;
  @Modal.Action("handleCancel") handleCancel: any;

  options: any[] = [
    {
      key: "mention",
      name: "멤버 선택",
      images: {
        on: "/assets/components/mention-active.png",
        off: "/assets/components/mention.png",
      },
    },
    {
      key: "file",
      name: "첨부파일",
      images: {
        on: "/assets/components/file-active.svg",
        off: "/assets/components/file.png",
      },
    },
  ];

  loading: boolean = false;
  selectedOptionKey: string = "";

  files: IFileData[] = [];
  editorFiles: IFileData[] = [];
  mentions: any[] = [];

  moment = moment;
  uploadIcon = uploadIcon;

  data: any = {};

  created() {
    this.loadMemberListAsync();
  }

  get isSelectedAllTeam() {
    const { teamObjectIds } = this.data;
    const { teams } = this.hotelInfo;
    return teamObjectIds && teams && teamObjectIds.length === teams.length;
  }

  set isSelectedAllTeam(value: boolean) {
    if (value) {
      const teamObjectIds = this.hotelInfo.teams.map((item: any) => item.objectId);
      this.data = {
        ...this.data,
        teamObjectIds,
      };
    } else {
      this.data = {
        ...this.data,
        teamObjectIds: [],
      };
    }
  }

  get selectedOption() {
    return (
      this.selectedOptionKey &&
      this.options.find((item: any) => this.selectedOptionKey === item.key)
    );
  }

  get optionMentions() {
    const result: any[] = [];

    if (this.mentions.length) {
      result.push({
        key: "mention",
        icon: "/assets/components/tag-mention.svg",
        label: `멘션(${this.mentions.length}명)`,
        style: {
          border: "solid 1px #8270ff",
          backgroundColor: "rgba(130, 112, 255, 0.2)",
          color: "#8270ff",
        },
      });
    }

    if (this.files.length) {
      result.push({
        key: "file",
        icon: "/assets/components/tag-file.svg",
        label: `첨부파일(${this.files.length}개)`,
        style: {
          border: "solid 1px #87e8de",
          backgroundColor: "#e6fffb",
          color: "#87e8de",
        },
      });
    }

    return result;
  }

  get selectedPeriod() {
    if (!this.data.isTop) {
      return "";
    }

    if (!this.data.topStartTime) {
      return "";
    }

    if (moment(new Date()).diff(this.data.topStartTime, "days") >= 1) {
      return "";
    }

    if (moment().format("L") == moment(this.data.topStartTime).format("L")) {
      const diff = this.data.topEndTime?.diff(this.data.topStartTime, "days");
      switch (diff) {
        case 7:
          return "week-1";

        case 14:
          return "week-2";

        case 30:
          return "month-1";
      }
    }

    return "";
  }

  set selectedPeriod(value: any) {
    if (!this.data.isTop) {
      return;
    }

    let topStartTime = moment().startOf("day");
    let topEndTime = moment();

    switch (value) {
      case "week-1":
        topStartTime = moment().startOf("day");
        topEndTime = moment().add(7, "days").startOf("day");
        break;

      case "week-2":
        topStartTime = moment().startOf("day");
        topEndTime = moment().add(14, "days").startOf("day");
        break;

      case "month-1":
        topStartTime = moment().startOf("day");
        topEndTime = moment().add(30, "days").startOf("day");
        break;
    }

    this.data = {
      ...this.data,
      topStartTime,
      topEndTime,
    };
  }

  mounted() {
    this.data = {
      teamObjectIds: this.hotelInfo?.teamObjectId ? [this.hotelInfo.teamObjectId] : [],
    };

    if (this.defaultData) {
      const { mentions, files, teams, topStartTime, topEndTime, ...defaultData } = this.defaultData;

      this.data = {
        ...defaultData,
        topStartTime: topStartTime && moment(topStartTime),
        topEndTime: topEndTime && moment(topEndTime),
        teamObjectIds: teams ? teams.map((item: any) => item.objectId) : this.data.teamObjectIds,
      };

      this.mentions = mentions || [];
      this.files = files || [];
    }

    this.modal.onCancel = () => {
      this.$confirm({
        title: "게시글 작성창을 닫으시겠습니까?",
        content: "작성하시던 게시글 내용은 삭제됩니다.",
        okText: "작성창 닫기",
        cancelText: "계속작성",
        onOk: () => {
          this.close();
        },
        onCancel: () => {},
      });
    };
  }

  async source(searchTerm: string, renderList: Function) {
    searchTerm = searchTerm.toLowerCase();
    const list = this.memberList
      .filter((item: any) => item.name.toLowerCase().indexOf(searchTerm) != -1)
      .map((item: any) => ({ id: item.objectId, value: item.name }));

    renderList(list, searchTerm);
  }

  selectOption(item: any) {
    this.selectedOptionKey = item.key;
  }

  optionStyle(item: any) {
    const selected = this.selectedOptionKey === item.key;

    return {
      backgroundColor: selected ? "#e9f1fd" : "white",
      backgroundImage: `url('${selected ? item.images.on : item.images.off}')`,
    };
  }

  async fileUpload({ file, onSuccess, onError }: any) {
    try {
      const result = await fileUpload(file, maxFileSize);
      onSuccess(result);
    } catch (ex) {
      onError(ex);
    }
  }

  changeFile(event: any) {
    const { status, response } = event.file;

    switch (status) {
      case "uploading":
        break;

      case "done":
        this.$message.success("파일 업로드에 성공하였습니다.");
        this.files = [...this.files, response];
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }

  deleteFile(index: number) {
    this.files = this.files.filter((file: any, i: number) => index != i);
    this.$message.info("파일을 삭제하였습니다.");
  }

  disabledDate(current: Moment) {
    return current && current <= moment().subtract(1, "day").endOf("day");
  }

  code() {
    return (this.$refs.editor as any).code();
  }

  text() {
    return (this.$refs.editor as any).text();
  }

  async submit() {
    if (!this.data.teamObjectIds) {
      this.$message.warning("부서를 선택해주세요.");
      return;
    }

    if (!this.data.category) {
      this.$message.warning("카테고리를 선택해주세요.");
      return;
    }

    if (!this.data.title) {
      this.$message.warning("제목을 입력해주세요.");
      return;
    }

    const code = this.code();
    const text = this.text();
    if (!text.replace(/\s/gi, "") && code == "<p><br></p>") {
      this.$message.warning("내용을 입력해주세요.");
      return;
    }

    const { isTop } = this.data;

    let details: any = {
      isTop,
      contents: code,
      plainContents: text,
      files: [...this.files, ...this.editorFiles],
      mentions: this.mentions,
    };

    if (isTop) {
      const { topStartTime, topEndTime } = this.data;

      details = {
        ...details,
        topStartTime: topStartTime && topStartTime.format("YYYY-MM-DD"),
        topEndTime: topEndTime && topEndTime.format("YYYY-MM-DD"),
      };
    } else {
      details = {
        ...details,
        topStartTime: null,
        topEndTime: null,
      };
    }

    const title = this.data && this.data.objectId ? "수정" : "등록";

    try {
      this.loading = true;

      await this.writeNotice({
        ...this.data,
        ...details,
      });

      this.$message.success(`게시물이 ${title} 되었습니다.`);

      this.handleOk();
    } catch {
      this.$message.error(`게시물 ${title}에 실패하였습니다.`);
    } finally {
      this.$emit("load");
      this.loading = false;
    }
  }

  changeTeam(teamObjectIds: any) {
    this.data = {
      ...this.data,
      teamObjectIds,
    };
  }

  filterOption(input: any, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  close() {
    this.handleCancel({ forceClose: true });
  }

  closeOption() {
    this.selectedOptionKey = "";
  }

  editorChangeFile(files: any[]) {
    this.editorFiles = files;
  }

  editorLoad() {
    if (this.defaultData) {
      (this.$refs.editor as any).summernote.summernote("code", this.defaultData.contents);
    }
  }
}

Vue.component("writeNoticeModal", writeNoticeModal);
