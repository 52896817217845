var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issue-log-modal-wrapper"},[_c('div',{staticClass:"issue-write-header",attrs:{"df":"","fdr":"","fjb":"","fic":""}},[_c('div',[_vm._v("수정 이력")]),_c('img',{attrs:{"src":"/assets/components/close-white.png"},on:{"click":_vm.handleCancel}})]),_c('div',{staticClass:"issue-log-wrap"},[_vm._l((_vm.dataList),function(group,index){return _c('div',{key:index,staticClass:"issue-log"},[(_vm.isUsedDetailGroup(group.list))?_c('div',{staticClass:"issue-log-title"},[_vm._v(_vm._s(group.key))]):_vm._e(),_vm._l((group.list),function(item,index){return _c('div',{key:index},_vm._l((item.details),function(detail,index){return _c('div',{key:index},[(_vm.isUsedDetail(detail))?_c('div',{staticClass:"issue-log-detail-wrap",attrs:{"df":"","fdc":""}},[_c('div',{attrs:{"df":"","fdr":"","fic":""}},[(detail.key == 'Status')?_c('div',{staticClass:"status",class:{
                  registered: detail.newValue == 'Registered',
                  notified: detail.newValue == 'Notified',
                  solved: detail.newValue == 'Solved',
                  pending: detail.newValue == 'Pending',
                }}):_vm._e(),_c('div',{staticClass:"issue-log-detail",attrs:{"df":"","fdc":"","flex":""}},[_c('div',[_vm._v(" '"+_vm._s(item.updaterName)+"("+_vm._s(item.updaterTeam ? item.updaterTeam : "미분류")+")'님이 "+_vm._s(detail.text)+" ")]),(detail.key == 'Files' || detail.key == 'NewReplyFiles')?_c('div',{staticClass:"detail-file-wrap",attrs:{"df":"","fdr":""}},_vm._l((JSON.parse(detail.newValue)),function(file,index){return _c('attachment',{key:index,staticClass:"detail-file",attrs:{"file":file}})}),1):_vm._e(),(
                    detail.key == 'Contents' ||
                      detail.key == 'NewReplyContents' ||
                      detail.key == 'UpdateReplyContents'
                  )?_c('div',{staticClass:"detail-content"},[_vm._v(" "+_vm._s(detail.newValue)+" ")]):_vm._e(),(detail.key == 'Teams')?_c('div',{staticClass:"detail-team",attrs:{"df":"","fdr":""}},[_c('issue-card-team',{attrs:{"teams":detail.newValue}})],1):_vm._e(),(detail.key == 'Todos' && JSON.parse(detail.newValue).length > 0)?_c('div',{staticClass:"detail-content"},[_c('check-list',{attrs:{"data":JSON.parse(detail.newValue)}})],1):_vm._e(),(detail.key == 'Anchors')?_c('div',{staticClass:"anchor-item"},_vm._l((detail.newValue),function(item,index){return _c('div',{key:index},[_vm._v(_vm._s(item.name))])}),0):_vm._e(),(detail.key == 'Mentions')?_c('div',{staticClass:"anchor-item"},_vm._l((JSON.parse(detail.newValue)),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.getMemberName(item))+" ")])}),0):_vm._e()])])]):_vm._e(),(_vm.isUsedDetail(detail))?_c('div',{staticClass:"issue-log-time",attrs:{"df":"","fdc":"","fie":""}},[_vm._v(" "+_vm._s(_vm.moment(item.createdTime).format("YYYY/MM/DD HH:mm"))+" ")]):_vm._e()])}),0)})],2)}),(!_vm.dataList.length)?_c('div',{staticClass:"issue-log"},[_c('div',{staticClass:"issue-log-title"},[_vm._v("수정 이력이 존재하지 않습니다.")])]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }