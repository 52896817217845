









import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";

@Component
export default class LayoutHeader extends Vue {}
