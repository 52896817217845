


































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Mutation, namespace } from "vuex-class";
import { fileName, fileUrl, icon, isImage } from "@/constants";

const Modal = namespace("modal");

@Component({
  components: {},
})
export default class FileList extends Vue {
  @Prop({ default: () => [] }) data!: any[];

  @Modal.Action("showModal") showModal: any;

  isImage = isImage;
  fileName = fileName;
  icon = icon;

  get isLegacyFile() {
    return this.data.length !== 0 && typeof this.data[0].fileURL === "undefined";
  }

  get Legacyimages() {
    return this.data.filter((item: any) => this.isImage(item.url));
  }

  get images() {
    return this.data.filter((item: any) => this.isImage(item.fileURL));
  }

  clickLegacy(item: any) {
    if (this.isImage(item.url)) {
      this.detailImageLegacy(item);
    } else {
      this.downloadLegacy(item);
    }
  }

  click(item: any) {
    if (this.isImage(item.fileURL)) {
      this.detailImage(item);
    } else {
      this.download(item);
    }
  }

  detailImageLegacy(item: any) {
    const index = this.Legacyimages.findIndex((_: any) => _.url === item.url);
    this.showModal({
      type: "image-detail-modal",
      props: {
        data: this.Legacyimages,
        initIndex: index,
      },
    });
  }

  detailImage(item: any) {
    const index = this.images.findIndex((_: any) => _.fileURL === item.fileURL);
    this.showModal({
      type: "image-detail-modal",
      props: {
        data: this.images.map((m) => ({ ...m, url: m.fileURL })),
        initIndex: index,
      },
    });
  }

  downloadLegacy(file: any) {
    const a = document.createElement("a");
    a.target = "_blank";
    a.download = file.fileName;
    a.href = fileUrl(file.url);
    a.click();
  }

  download(file: any) {
    const a = document.createElement("a");
    a.target = "_blank";
    a.download = file.fileName;
    a.href = fileUrl(file.fileURL);
    a.click();
  }

  style(item: any) {
    return {
      backgroundImage: `url('${icon(item)}')`,
    };
  }
}
