import Client from "../client";

const urlEncodeExceptionCharacters: any = {
  "!": "%21",
  "#": "%23",
  $: "%24",
  "%": "%25",
  "&": "%26",
  "(": "%28",
  ")": "%29",
  "*": "%2A",
  "+": "%2B",
  ",": "%2C",
  "/": "%2F",
  ":": "%3A",
  ";": "%3B",
  "=": "%3D",
  "?": "%3F",
  "@": "%40",
};

export class Hotel {
  static async registerHotel(data: any) {
    return await Client.put(`/hotel`, data);
  }

  static async list() {
    return await Client.get<any[]>(`/hotel/`);
  }

  static async info() {
    return await Client.get(`/hotel/info`);
  }

  static async updateHotelAsync(data: any) {
    return await Client.post(`/hotel`, data);
  }

  static async createTeamAsync(data: any) {
    return await Client.put(`/hotel/team`, data);
  }

  static async updateTeamAsync(data: any) {
    return await Client.post(`/hotel/team`, data);
  }

  static async teamListAsync() {
    return await Client.get<any[]>(`/hotel/team`);
  }

  static async deleteTeamAsync(objectId: string) {
    return await Client.del(`/hotel/team/${objectId}`);
  }

  static async categoryListAsync() {
    return await Client.get<any[]>(`/hotel/category`);
  }

  static async noticeCategoryListAsync() {
    return await Client.get<any[]>(`/hotel/noticeCategory`);
  }

  static async createCategoryAsync(data: any) {
    return await Client.put(`/hotel/category`, data);
  }

  static async deleteCategoryAsync(data: any) {
    const value = Object.keys(urlEncodeExceptionCharacters).reduce((name, character) => {
      const regexp = new RegExp(`\\${character}`, "g");
      const code = urlEncodeExceptionCharacters[character];
      return name.replace(regexp, code);
    }, data);
    const name = encodeURI(value);

    return await Client.del(`/hotel/category/${name}`);
  }

  static async createNoticeCategoryAsync(data: any) {
    return await Client.put(`/hotel/noticeCategory`, data);
  }

  static async deleteNoticeCategoryAsync(data: any) {
    const value = Object.keys(urlEncodeExceptionCharacters).reduce((name, character) => {
      const regexp = new RegExp(`\\${character}`, "g");
      const code = urlEncodeExceptionCharacters[character];
      return name.replace(regexp, code);
    }, data);
    const name = encodeURI(value);

    return await Client.del(`/hotel/noticeCategory/${name}`);
  }

  static async updateTeamCategoryOrders(teamObjectId: string, categories: string[]) {
    return await Client.post(`/hotel/team/${teamObjectId}/categoryOrders`, {
      categories,
    });
  }

  static async updateTeamNoticeCategoryOrders(teamObjectId: string, categories: string[]) {
    return await Client.post(`/hotel/team/${teamObjectId}/noticeCategoryOrders`, {
      categories,
    });
  }

  static async updateTeamMember(teamObjectId: string, members: any[]) {
    return await Client.post(`/hotel/team/${teamObjectId}/member`, {
      members,
    });
  }

  static async updateCategoryOrders(categories: string[]) {
    return await Client.post(`/hotel/category/order`, {
      categories,
    });
  }

  static async updateNoticeCategoryOrders(categories: string[]) {
    return await Client.post(`/hotel/noticeCategory/order`, {
      categories,
    });
  }
}

export interface RegisterHotelModel {
  name: string;
  url: string;
  zipCode: string;
  areaLocation: string;
  detailLocation: string;
  file?: File;
}
