













































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { cloneDeep } from "lodash";
import { Favorites } from "@/http/api/favorites";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");

@Component({
  components: {},
})
export default class FavoriteSettings extends Vue {
  @Prop({}) modal: any;

  treeData: any[] = [];
  keyword: string = "";
  selectedUser: string[] = [];
  expandedKeys: string[] = [];
  filteredMember: any[] = [];
  showTree: boolean = true;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @MemberModule.State("memberList") memberList: any;

  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  async created() {
    await this.loadHotelAsync();
    await this.loadMemberListAsync();

    this.resetTree();
    this.expandedKeys.push("hotel");
  }

  resetTree() {
    this.treeData = [
      {
        title: this.hotelInfo.name,
        key: "hotel",
        children: [],
      },
    ];

    // 팀생성
    if (this.hotelInfo.favoriteUserObjectIds?.length > 0) {
      (this.treeData[0].children as any[]).push({
        title: "즐겨찾기",
        key: "favorites",
        children: [],
        slot: "customTeam",
        scopedSlots: { title: "customTeam" },
      });
      this.expandedKeys.push("favorites");
    }

    for (let team of this.hotelInfo.teams) {
      (this.treeData[0].children as any[]).push({
        title: team.name,
        key: team.objectId,
        children: [],
        slot: "customTeam",
        scopedSlots: { title: "customTeam" },
      });
      this.expandedKeys.push(team.objectId);
    }

    (this.treeData[0].children as any[]).push({
      title: "미소속",
      key: "noParent",
      children: [],
      slot: "customTeam",
      scopedSlots: { title: "customTeam" },
    });
    this.expandedKeys.push("noParent");

    // 팀에 유저 추가
    for (let member of this.memberList) {
      let wasFound = false;

      (this.treeData[0].children as any[]).map((team: any) => {
        if (team.key == member.teamObjectId) {
          team.children.push({
            title: member.name,
            key: member.objectId,
            profile: member.profile,
            phone: member.phone,
            rank: member.rank,
            email: member.email,
            team: team.title,
            slot: "customTitle",
            scopedSlots: { title: "customTitle" },
          });
          wasFound = true;
        }
      });
      if (
        this.hotelInfo.favoriteUserObjectIds?.length > 0 &&
        this.hotelInfo.favoriteUserObjectIds?.find(
          (objectId: string) => objectId == member.objectId,
        )
      ) {
        this.treeData[0].children
          .find((t: any) => t.key == "favorites")
          .children.push({
            title: member.name,
            key: member.objectId,
            profile: member.profile,
            phone: member.phone,
            rank: member.rank,
            email: member.email,
            team:
              this.hotelInfo.teams.find((t: any) => t.objectId == member.teamObjectId)?.name ??
              "미소속",
            slot: "customTitle",
            scopedSlots: { title: "customTitle" },
          });
      }
      if (!wasFound) {
        this.treeData[0].children[this.treeData[0].children.length - 1].children.push({
          title: member.name,
          key: member.objectId,
          profile: member.profile,
          phone: member.phone,
          rank: member.rank,
          email: member.email,
          team: "미소속",
          slot: "customTitle",
          scopedSlots: { title: "customTitle" },
        });
      }
    }
  }

  isFavorites(key: string) {
    return this.hotelInfo.favoriteUserObjectIds?.includes(key) ? true : false;
  }

  async updateFavorites(key: string) {
    await Favorites.update(key);
    await this.loadInfoAsync();
    this.resetTree();
  }

  onSelect(selectedKeys: any, info: any) {
    const selectedNodeKey = info.node.eventKey;
    const isTeam = this.hotelInfo.teams.find((team: any) => team.objectId == selectedNodeKey)
      ? true
      : false;

    if (isTeam || selectedNodeKey == "noParent" || selectedNodeKey == "favorites") {
      const isExistedInExpanedKeys = this.expandedKeys.find((key: string) => key == selectedNodeKey)
        ? true
        : false;
      if (isExistedInExpanedKeys) {
        this.expandedKeys = this.expandedKeys.filter((key: string) => key != selectedNodeKey);
      } else {
        this.expandedKeys.push(selectedNodeKey);
      }
    } else {
      const isFound = this.selectedUser.find((u) => u == info.node.dataRef.key);
      if (!isFound) {
        this.selectedUser.push(info.node.dataRef.key);
      } else {
        this.selectedUser = this.selectedUser.filter((key) => key != info.node.dataRef.key);
      }
      this.updateAnchor();
    }
  }

  getChecked(key: string) {
    return this.selectedUser.find((u) => u == key) ? true : false;
  }

  onExpand(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
  }

  onSearch() {
    this.filteredMember = this.memberList.filter((member: any) =>
      member.name.includes(this.keyword),
    );
    if (this.keyword.length > 0) {
      this.showTree = false;
    } else {
      this.showTree = true;
    }
  }

  updateAnchor() {
    this.$emit("update", this.selectedUser);
  }
}
