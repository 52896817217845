























































import { Vue, Component } from "vue-property-decorator";
import { AntdValidateStatus } from "@/interfaces/Antd";

import LayoutHeader from "@/components/layouts/layoutHeader.vue";

import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";

import { namespace } from "vuex-class";
import RawHotelModule from "@/store/modules/hotelModule";
import { router } from "@/main";
const HotelModule = namespace("HotelModule");

@Component({
  components: {
    LayoutHeader,
  },
})
export default class ManagerLogin extends Vue {
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  loading = false;

  data = {
    id: "",
    password: "",
  };

  get rules() {
    return {
      id: [
        {
          required: true,
          message: "아이디를 입력해주세요.",
        },
      ],
      password: [
        {
          required: true,
          message: "비밀번호를 입력해주세요.",
        },
      ],
    };
  }

  mounted() {
    this.check();
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.loading = true;

        await this.login();
        await this.check();
      } catch {
        this.$message.error("로그인에 실패하였습니다. 아이디 및 비밀번호를 확인해주세요.");
      } finally {
        this.loading = false;
      }
    });
  }

  async login() {
    await Auth.login(this.data.id, this.data.password);
  }

  async check() {
    const info = await Auth.loadSelfInfo();
    await Auth.hasHotel();
    const hotels = await Hotel.list();

    if (info.role === 4 && (!hotels || !hotels.length)) {
      return router.push("/admin/registerHotel");
    }

    if (info.role === 4) {
      this.toHotelUrl({ url: hotels[0].url, path: "admin/team" });
      return;
    } else {
      this.toHotelUrl({ url: hotels[0].url, path: "" });
      return;
    }
  }
}
