import { Moment } from "moment";
import Client from "../client";

export class Issue {
  static async list(filter: any) {
    const { startDate, endDate, category, ...issueFilter } = filter;

    return await Client.get<any[]>(`/issue`, {
      ...issueFilter,
      categories: category ? [...category] : [],
      startDate: startDate && startDate.format("YYYY-MM-DD"),
      endDate: endDate && endDate.format("YYYY-MM-DD"),
    });
  }

  static async singleIssue(objectId: string) {
    return await Client.get<any>(`/issue/${objectId}`);
  }

  static async createIssue(issue: any) {
    return await Client.put(`/issue`, issue);
  }

  static async updateIssue(issue: any) {
    return await Client.post(`/issue/${issue.objectId}`, issue);
  }

  static async updateStatus(objectId: string, status: any) {
    return await Client.post(`/issue/${objectId}/status`, { status });
  }

  static async readIssue(objectId: string) {
    return await Client.post<any>(`/issue/${objectId}/read`);
  }

  static async deleteIssue(objectId: string) {
    return await Client.del(`/issue/${objectId}`);
  }

  static async readLogList(objectId: string) {
    return await Client.get<any[]>(`/issue/${objectId}/readLog`);
  }

  static async bookmark(objectId: string, value: boolean) {
    return await Client.post(`/issue/${objectId}/bookmark`, {
      value,
    });
  }

  static async updateChecklist(
    issueObjectId: string,
    objectId: string,
    contents: string,
    isDone: boolean,
  ) {
    return await Client.put(`/issue/${issueObjectId}/todo`, {
      objectId,
      contents,
      isDone,
    });
  }

  static async changeLogs(objectId: string) {
    return await Client.get<any[]>(`issue/${objectId}/changeLog`);
  }

  static async updateReservation({ objectId, reservedStartDate, reservedEndDate }: any) {
    return await Client.post(`issue/${objectId}/reservation`, {
      reservedStartDate: reservedStartDate && reservedStartDate.format("YYYY-MM-DD"),
      reservedEndDate: reservedEndDate && reservedEndDate.format("YYYY-MM-DD"),
    });
  }
}
