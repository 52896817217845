






















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { Support } from "@/http/api/support";
import moment from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class HotelManagement extends Vue {
  @HotelModule.State("hotelObjectId") hotelObjectId: any;

  get hotelObjectIdValue() {
    return this.hotelObjectId;
  }

  @Watch("hotelObjectIdValue")
  hotelObjectIdChanged() {
    this.load();
  }

  month = moment();

  loading = false;
  data = {};

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;

      const startDate = this.month?.startOf("month")?.format("YYYY-MM-DD");
      const endDate = this.month?.endOf("month")?.format("YYYY-MM-DD");

      this.data = await Support.count({ startDate, endDate, hotelObjectId: this.hotelObjectId });
    } catch {
    } finally {
      this.loading = false;
    }
  }
}
