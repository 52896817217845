


















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import noticeTable from "@/components/notice/noticeTable.vue";
import moment from "moment";
import noticeFilter from "@/components/notice/noticeFilter.vue";
import noticeReply from "@/components/notice/noticeReply.vue";
import noticeReplyInput from "@/components/notice/noticeReplyInput.vue";
import fileList from "@/components/fileList.vue";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const NoticeModule = namespace("NoticeModule");
const Modal = namespace("modal");

const dateFormat = "YYYY/MM/DD hh:mm";

@Component({
  components: {
    noticeTable,
    noticeFilter,
    noticeReply,
    noticeReplyInput,
    fileList,
  },
})
export default class NoticeDetail extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @MemberModule.State("memberList") memberList: any;

  @NoticeModule.State("noticeMap") noticeMap: any;
  @NoticeModule.State("noticeReplyMap") noticeReplyMap: any;
  @NoticeModule.Action("loadNotice") loadNotice: any;
  @NoticeModule.Action("loadNoticeReplies") loadNoticeReplies: any;
  @NoticeModule.Action("updateBookmark") updateBookmark: any;
  @NoticeModule.Action("deleteNotice") deleteNotice: any;

  @Modal.Action("showModal") showModal: any;

  @Watch("$route", { deep: true })
  routeChaned(n: any, o: any) {
    const oldValue = o.params.objectId;
    const newValue = n.params.objectId;

    if (oldValue !== newValue) {
      this.load(newValue);
    }
  }

  get permission() {
    return (
      this.hotelInfo &&
      (this.data.writerObjectId === this.hotelInfo.userObjectId ||
        this.hotelInfo.userPermission === 3 ||
        this.hotelInfo.userPermission === 2)
    );
  }

  moment = moment;

  data: any = {};

  get date() {
    return !this.data.isChanged
      ? `${moment(this.data.createdTime).format(dateFormat)}`
      : `${moment(this.data.updatedTime).format(dateFormat)} (수정됨)`;
  }

  mounted() {
    this.load();
  }

  async load(objectId?: string) {
    if (!objectId) {
      objectId = this.$route.params.objectId;
    }

    await Promise.all([this.loadNotice(objectId), this.loadNoticeReplies(objectId)]);
    this.data = this.noticeMap[objectId!];
  }

  async source(searchTerm: string, renderList: Function) {
    searchTerm = searchTerm.toLowerCase();
    const list = this.memberList
      .filter((item: any) => item.name.toLowerCase().indexOf(searchTerm) != -1)
      .map((item: any) => ({ id: item.objectId, value: item.name }));

    renderList(list, searchTerm);
  }

  toList() {
    this.$router.push({ name: "notice" });
  }

  async del(objectId: string) {
    await this.deleteNotice(objectId);
    this.toList();
  }

  checkFiletype(type: string) {
    switch (type) {
      case "image":
        break;

      default:
        break;
    }
  }

  async toggleBookmark() {
    const { objectId, isBookmarked } = this.data;

    await this.updateBookmark({ objectId, isBookmarked: !isBookmarked });
    await this.load(objectId);
  }

  noticeChangeLog() {
    this.showModal({
      type: "notice-change-log-modal",
      props: { objectId: this.data.objectId },
    });
  }

  duplicateNotice() {
    this.showModal({
      type: "write-notice-modal",
      maskClosable: false,
      props: {
        defaultData: {
          ...this.data,
          objectId: null,
        },
      },
      onOk: () => this.load(),
    });
  }

  updateNotice() {
    this.showModal({
      type: "write-notice-modal",
      maskClosable: false,
      props: { defaultData: this.data },
      onOk: () => this.load(this.data.objectId),
    });
  }

  print() {
    window.print();
  }
}
