






































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import bookmarkCard from "@/components/layouts/bookmarkCard.vue";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");

@Component({
  components: {
    bookmarkCard,
  },
})
export default class OrganizationTree extends Vue {
  @Prop({ default: () => [] }) value: any;
  @Prop({ default: false }) tooltip: any;

  treeData: any[] = [];
  keyword: string = "";
  expandedKeys: string[] = [];
  showTree: boolean = true;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @MemberModule.State("memberList") memberList: any;

  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  get search() {
    return !!(this.keyword && this.keyword.length);
  }

  get extendedMemberList() {
    return this.memberList.map((item: any) => {
      let options: any[] = [];

      if (item.team) {
        options = [...options, `• 부서 : ${item.team}`];
      }

      if (item.extension) {
        options = [...options, `• 내선번호 : ${item.extension}`];
      }

      if (item.phone) {
        options = [...options, `• 핸드폰 : ${item.phone}`];
      }

      if (item.email) {
        options = [...options, `• 이메일 : ${item.email}`];
      }

      return {
        ...item,
        options,
      };
    });
  }

  get filteredMember() {
    if (!this.search) {
      return [];
    }

    return this.extendedMemberList.filter((item: any) => item.name.indexOf(this.keyword) !== -1);
  }

  mounted() {
    this.load();
  }

  async load() {
    await this.loadHotelAsync();
    await this.loadMemberListAsync();

    const temas = [
      ...(this.hotelInfo.favoriteUserObjectIds?.length > 0
        ? [
            {
              key: "favorite",
              title: "즐겨찾기",
              scopedSlots: { title: "team" },
            },
          ]
        : []),
      ...this.hotelInfo.teams.map((team: any) => ({
        key: team.objectId,
        title: team.name,
        scopedSlots: { title: "team" },
      })),
      {
        key: "no-team",
        title: "미소속",
        scopedSlots: { title: "team" },
      },
    ].map((team: any) => {
      const children = this.extendedMemberList
        .filter((item: any) => {
          switch (team.key) {
            case "no-team":
              return !item.teamObjectId;

            case "favorite":
              return this.hotelInfo.favoriteUserObjectIds.indexOf(item.objectId) !== -1;
          }

          return item.teamObjectId === team.key;
        })
        .map((item: any) => ({
          key: item.objectId,
          title: item.name,
          profile: item.profile,
          parent: team.key,
          options: item.options,
          rank: item.rank,
          isLeaf: true,
          scopedSlots: { title: "member" },
        }));

      return { ...team, children };
    });

    this.treeData = [
      {
        title: this.hotelInfo.name,
        key: "hotel",
        children: temas,
      },
    ];

    this.expandedKeys = ["hotel", ...temas.map((item: any) => item.key)];
  }

  onSelect(_: any, info: any) {
    const key = info.node.dataRef.key;

    if (!info.node.dataRef.isLeaf) {
      if (this.expandedKeys.indexOf(key) !== -1) {
        this.expandedKeys = this.expandedKeys.filter((item: any) => item != key);
      } else {
        this.expandedKeys = [...this.expandedKeys, key];
      }
      return;
    }

    this.onChange(key);
  }

  checked(key: string) {
    return this.value.indexOf(key) !== -1;
  }

  onExpand(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
  }

  onChange(key: string) {
    if (this.checked(key)) {
      this.input(this.value.filter((objectId: string) => objectId != key));
    } else {
      this.input([...this.value, key]);
    }
  }

  input(value: any) {
    this.$emit("input", value);
  }
}
