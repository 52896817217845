


















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import moment from "moment";

const HotelModule = namespace("HotelModule");
const NoticeModule = namespace("NoticeModule");
const Modal = namespace("modal");

@Component({
  components: {},
})
export default class NoticeTable extends Vue {
  @Prop() value: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @NoticeModule.State("noticeMap") noticeMap: any;
  @NoticeModule.State("noticeCount") noticeCount: any;
  @NoticeModule.Action("loadNotice") loadNotice: any;
  @NoticeModule.State("noticeList") noticeList: any;
  @NoticeModule.State("topNoticeList") topNoticeList: any;
  @NoticeModule.Action("updateBookmark") updateBookmark: any;
  @NoticeModule.Action("loadNoticeList") loadNoticeList: any;
  @NoticeModule.Action("updateRead") updateRead: any;
  @NoticeModule.Action("deleteNotice") deleteNotice: any;

  @Modal.Action("showModal") showModal: any;

  moment = moment;
  current: number = 1;

  get notices() {
    return this.topNoticeList
      .filter((item: any) => this.noticeMap[item.objectId])
      .map((item: any) => ({ ...this.noticeMap[item.objectId], top: true }))
      .concat(
        this.noticeList
          .filter((item: any) => item != null)
          .filter((item: any) => this.noticeMap[item.objectId])
          .map((item: any) => ({ ...this.noticeMap[item.objectId] })),
      );
  }

  load() {
    this.$emit("load");
  }

  permission(item: any) {
    if (this.hotelInfo == null || item == null) {
      return false;
    }

    if (this.hotelInfo.userObjectId === item.writerObjectId) {
      return true;
    }

    if (this.hotelInfo.userPermission === 3) {
      return true;
    }

    if (this.hotelInfo.userPermission === 2 && item.permission !== 3) {
      return true;
    }

    return false;
  }

  async del(objectId: string) {
    await this.deleteNotice(objectId);
    this.load();
  }

  setRowClass(record: any, index: number) {
    return record.top ? "top-tr" : "";
  }

  async noticeBookmark(item: any) {
    const { objectId, isBookmarked } = item;
    await this.updateBookmark({ objectId, isBookmarked: !isBookmarked });
  }

  async readNotice(objectId: string) {
    this.updateRead(objectId);
    await this.loadNotice(objectId);
  }

  loadPageNotice() {
    this.loadNoticeList();
  }

  columns = [
    {
      title: "",
      dataIndex: "top",
      scopedSlots: { customRender: "top" },
      width: "5%",
      ellipsis: true,
    },
    {
      title: "제목",
      dataIndex: "noticeTitle",
      scopedSlots: { customRender: "noticeTitle" },
      width: "53%",
      ellipsis: true,
    },
    {
      title: "작성자",
      dataIndex: "writerName",
      width: 100,
      ellipsis: true,
      customRedner: (value: any) => value || "이름없음",
    },
    {
      title: "소속부서",
      dataIndex: "writerTeam",
      width: 120,
      ellipsis: true,
      customRedner: (value: any) => value || "미소속",
    },
    {
      align: "center",
      title: "작성날짜",
      dataIndex: "createdTime",
      width: 100,
      ellipsis: true,
      customRender: (value: any) => moment(value).format("YYYY/MM/DD"),
    },
    {
      align: "center",
      dataIndex: "readCount",
      width: 100,
      title: "조회수",
    },
    {
      title: "",
      dataIndex: "more",
      scopedSlots: { customRender: "more" },
      width: "3%",
    },
    {
      title: "",
      dataIndex: "bookmark",
      scopedSlots: { customRender: "bookmark" },
      width: "5%",
    },
  ];

  noticeChangeLog(objectId: string) {
    this.showModal({
      type: "notice-change-log-modal",
      props: { objectId: objectId },
    });
  }

  duplicateNotice(data: any) {
    this.showModal({
      type: "write-notice-modal",
      maskClosable: false,
      props: { defaultData: {
        ...data,
        objectId: null,
      } },
      onOk: () => this.load(),
    });
  }

  updateNotice(data: any) {
    this.showModal({
      type: "write-notice-modal",
      maskClosable: false,
      props: { defaultData: data },
    });
  }

  customRow(record: any) {
    return {
      props: {
        record,
      },
      on: {
        click: (event: any) => {
          this.readNotice(record.objectId);
          this.$router.push({
            name: "notice-detail",
            params: {
              objectId: record.objectId,
            },
          });
        },
      },
    };
  }
}
