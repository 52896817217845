


























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import moment from "moment";
import { tagStyle, tagText } from "@/constants";
import { AlertType } from "@/utiles/signalr";

const HotelModule = namespace("HotelModule");
const NoticeModule = namespace("NoticeModule");
const IssueModule = namespace("IssueModule");

const CollectionModule = namespace("CollectionModule");

@Component
export default class NotificationTabCard extends Vue {
  @Prop() data: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @NoticeModule.Action("updateRead") noticeUpdateRead: any;
  @IssueModule.Action("updateRead") issueUpdateRead: any;

  @CollectionModule.Action("readNotification") readNotification: any;

  get fromNow() {
    return moment(this.data.createdTime).fromNow();
  }

  get profileStyle() {
    const url = this.data.userProfile || "/assets/settings/default-user.svg";
    return {
      backgroundImage: `url('${url}')`,
    };
  }

  get tagStyle() {
    return tagStyle(this.data.targetType);
  }

  get tagText() {
    return tagText(this.data.targetType) + this.tagTextDetail;
  }

  get writerName() {
    return this.data.writerName || "이름없음";
  }

  get writerTeam() {
    return this.data.writerTeam || "미소속";
  }

  get contents() {
    const result = [];

    if (this.data.category) {
      result.push(`[${this.data.category}]`);
    }

    if (this.data.contents) {
      result.push(this.data.contents);
    }

    return result.join(" ");
  }

  get tagTextDetail() {
    switch (this.data.notificationType) {
      case AlertType.NewIssue:
      case AlertType.NewReply:
      case AlertType.NewNotice:
      case AlertType.NewNote:
      case AlertType.NewReservedIssue:
        return "(생성)";
      case AlertType.UpdateNotice:
      case AlertType.ChangeReply:
      case AlertType.IssueChange:
        return "(수정)";
      case AlertType.DeleteReply:
      case AlertType.DeleteIssue:
      case AlertType.DeleteNotice:
        return "(삭제)";
      case AlertType.IssueStatus:
        return "(상태)";
      case AlertType.MentionChanged:
        return "(멘션)";
      case AlertType.IssueTodo:
        return "(체크리스트)";
      case AlertType.FileChanged:
        return "(파일)";
      default:
        return "";
    }
  }

  get isChanged() {
    return (
      this.data.notificationType != AlertType.NewIssue &&
      this.data.notificationType != AlertType.NewReply &&
      this.data.notificationType != AlertType.NewNotice
    );
  }

  get isRead() {
    return this.data.readUserObjectIds.indexOf(this.hotelInfo.userObjectId) !== -1;
  }

  async click() {
    await this.readNotification(this.data.objectId);

    const objectId = this.data.targetObjectId;

    switch (this.data.targetType) {
      case "issue":
      case "issueReply":
        await this.toIssue(objectId);
        break;

      case "notice":
      case "noticeReply":
        await this.toNotice(objectId);
        break;
    }
  }

  async toIssue(objectId: any) {
    try {
      await this.issueUpdateRead(objectId);

      this.$router.push({
        name: "issue-detail",
        params: {
          objectId,
        },
      });
    } catch (err) {
      this.$message.error("해당 이슈는 삭제되었습니다.");
      this.$router.push({ name: "issue" });
    }
  }

  async toNotice(objectId: any) {
    try {
      await this.noticeUpdateRead(objectId);

      this.$router.push({
        name: "notice-detail",
        params: {
          objectId,
        },
      });
    } catch (err) {
      this.$message.error("해당 게시글은 삭제되었습니다.");
      this.$router.push({ name: "notice" });
    }
  }
}
