





























































import { Vue, Component, Prop } from "vue-property-decorator";
import { Auth } from "@/http/api/auth";
import { CodeModel, RequestCodeType } from "@/interfaces/Auth";

const phoneRegex = /^[0-9]{9,}$/;
const codeRegex = /^[0-9]{6}$/;

@Component
export default class FindAccountForm extends Vue {
  @Prop({ type: String, required: true, default: "id" }) type?: "id" | "password";

  sendCodeLoading = false;
  submitLoading = false;
  sendCodeResult: any = null;

  get canSubmit() {
    return !!this.data.code;
  }

  get canVerify() {
    return !!this.sendCodeResult;
  }

  get canSendCode() {
    return !!this.data.phone !== this.canVerify;
  }

  get rules() {
    return {
      ...(this.type === "password"
        ? {
            id: [
              {
                required: true,
                message: "아이디를 입력해주세요.",
              },
            ],
          }
        : {}),
      name: [
        {
          required: true,
          message: "이름을 입력해주세요.",
        },
      ],
      phone: [
        {
          trigger: "change",
          validator: async (rule: any, value: any, callback: any) => {
            try {
              if (!value) {
                callback(new Error("휴대폰 번호를 입력해주세요."));
                return;
              }

              if (!phoneRegex.test(value.replace(/-/g, ""))) {
                callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                return;
              }

              callback();
            } catch (ex) {
              callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
            }
          },
        },
      ],
      ...(this.canVerify
        ? {
            code: [
              {
                trigger: "change",
                validator: async (rule: any, value: any, callback: any) => {
                  try {
                    if (!value) {
                      callback(new Error("인증번호를 입력해주세요."));
                    }

                    if (!codeRegex.test(value)) {
                      callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                    }

                    callback();
                  } catch (ex) {
                    callback(new Error("올바른 번호를 입력해주시기 바랍니다."));
                  }
                },
              },
            ],
          }
        : {}),
      ...(this.canVerify && this.type === "password"
        ? {
            password: [
              {
                required: true,
                message: "변경 할 비밀번호를 입력해주세요.",
              },
            ],
            confirm: [
              {
                trigger: "change",
                validator: async (rule: any, value: any, callback: any) => {
                  try {
                    if (!value) {
                      callback(new Error("비밀번호를 한 번 더 입력해주세요."));
                      return;
                    }

                    if (this.data.password !== value) {
                      callback(new Error("입력하신 비밀번호가 다릅니다."));
                      return;
                    }

                    callback();
                  } catch (ex) {
                    callback(new Error("입력하신 비밀번호가 다릅니다."));
                  }
                },
              },
            ],
          }
        : {}),
    };
  }

  data: any = {
    name: "",
    id: "",
    phone: "",
    code: "",
    password: "",
    confirm: "",
  };

  sendCode() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.sendCodeLoading = true;

        const data = {
          codeType: this.type === "id" ? RequestCodeType.FindId : RequestCodeType.FindPassword,
          id: this.type === "id" ? null : this.data.id,
          name: this.data.name,
          phone: this.data.phone,
        };

        this.sendCodeResult = await Auth.sendCode(data);

        this.$message.success("인증번호가 발송되었습니다.");
      } catch (err) {
        const message = err?.response?.data?.message || "인증번호 발송에 실패하였습니다.";
        this.$message.error(message);
      } finally {
        this.sendCodeLoading = false;
      }
    });
  }

  submit() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        this.submitLoading = true;

        const result = this.type === "id" ? await this.findId() : await this.findPassword();
        this.$emit("verified", result);
      } catch (err) {
        this.$message.error(err.message);
      } finally {
        this.submitLoading = false;
      }
    });
  }

  async findId() {
    try {
      return await Auth.findId({
        objectId: this.sendCodeResult,
        code: this.data.code,
      });
    } catch (err) {
      throw { message: err?.response?.data?.message || "아이디 찾기에 실패했습니다." };
    }
  }

  async findPassword() {
    try {
      return await Auth.findPassword({
        objectId: this.sendCodeResult,
        code: this.data.code,
        password: this.data.password,
      });
    } catch (err) {
      throw { message: err?.response?.data?.message || "비밀번호 찾기에 실패했습니다." };
    }
  }
}
