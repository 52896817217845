import { Reply } from "@/http/api/reply";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface IIssueReplyState {
  repliesMap: Record<string, unknown>;
}

@Module({
  namespaced: true,
})
export default class IssueReplyV2Module extends VuexModule implements IIssueReplyState {
  public repliesMap: Record<string, Record<string, unknown>[]> = {};

  @Mutation
  private SET_REPLIES_MAP(repliesMap: Record<string, Record<string, unknown>[]>) {
    this.repliesMap = repliesMap;
  }

  @Action
  public async fetchIssueReply(payload: { hotelObjectId: string; issueObjectId: string }) {
    const list = await Reply.replyList(payload.issueObjectId);

    this.context.commit("SET_REPLIES_MAP", { ...this.repliesMap, [payload.issueObjectId]: list });
  }
}
