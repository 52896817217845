






































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import adminOrganizationTree from "@/components/admin/adminOrganizationTree.vue";
import { File } from "@/http/api/file";
import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";
import axios from "axios";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

const maxFileSize = 1024 * 1024 * 20;

@Component({
  components: {
    adminOrganizationTree,
  },
})
export default class HotelManagement extends Vue {
  @HotelModule.Action("loadHotelListAsync") loadHotelListAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.State("hotelList") hotelList: any;
  @Modal.Action("showModal") showModal: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;

  activeKey: any = "";
  tabs: any = [];

  get tab() {
    return (
      (this.tabs &&
        this.hotelInfo &&
        this.tabs.find((item: any) => item.data.url === this.hotelInfo.url)) ||
      {}
    );
  }

  rules(tab: any) {
    return {
      name: [
        {
          required: true,
          message: "이름을 입력해주세요.",
        },
      ],
      ...(tab.key === "_"
        ? {
            url: [
              {
                required: true,
                message: "접속주소를 입력해주세요.",
              },
              {
                trigger: "blur",
                validator: async (rule: any, value: any, callback: any) => {
                  try {
                    await Auth.checkUrl(value);
                    callback();
                  } catch {
                    callback(new Error("이미 사용중인 접속주소입니다."));
                  }
                },
              },
            ],
          }
        : {}),
    };
  }

  async mounted() {
    await this.load();
    this.activeKey = this.tabs[0].key;
  }

  async load() {
    await this.loadInfoAsync();
    await this.loadHotelListAsync();

    const list = await Hotel.list();

    this.tabs = [
      ...list.map((item: any) => ({
        closable: false,
        key: item.objectId,
        title: item.name,
        data: { ...item },
      })),
      ...this.tabs.filter((item: any) => !list.some((_: any) => item.key === _.objectId)),
    ];
  }

  submit(tab: any) {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        await Hotel.updateHotelAsync(tab.data);

        this.tabs = this.tabs.filter((item: any) => item.key !== "_");

        this.$message.success(`호텔 정보가 수정 되었습니다.`);

        await this.load();
      } catch (ex) {
        this.$message.error(`호텔 정보 수정 실패하였습니다.`);
      }
    });
  }

  async presignedRequest({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: any;
    onError: any;
  }) {
    try {
      if (file.size >= maxFileSize) {
        throw Error("Large Size");
      }
      const { presignedUrl, url } = await File.getPreSignedUrl(file.name);

      await axios.request({
        url: presignedUrl,
        method: "PUT",
        data: file,
        headers: { "Access-Control-Allow-Origin": "*" },
      });

      onSuccess({
        url,
        fileName: file.name,
        size: file.size,
      });
    } catch (err) {
      this.$message.error("파일의 용량이 20MB가 넘어 파일 업로드에 실패하였습니다.");
      onError(err);
    }
  }

  async handleFileChange(event: any, tab: any) {
    switch (event.file.status) {
      case "uploading":
        break;

      case "done":
        {
          const { response } = event.file;
          tab.data.logo = response.url;
          tab.data.file = response;

          this.$message.success("파일 업로드에 성공하였습니다.");
        }
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }

  changeTab() {
    this.load();
  }

  async editTab() {
    this.tabs = [
      ...this.tabs.filter((item: any) => item.key !== "_"),
      {
        closable: false,
        key: "_",
        title: "새 호텔",
        data: {},
      },
    ];

    this.activeKey = "_";
  }
}
