




import { Component, Emit, Vue } from "vue-property-decorator";

@Component({})
export default class Observer extends Vue {
  readonly option: IntersectionObserverInit = {
    root: null,
    threshold: 1,
  };

  observer?: IntersectionObserver;

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      this.handleIntersect(entries[0]);
    }, this.option);

    this.observer.observe(this.trigger as Element);
  }

  get trigger() {
    return this.$refs.trigger;
  }

  @Emit("trigger")
  onTrigger(value: IntersectionObserver) {
    return value;
  }

  handleIntersect(target: IntersectionObserverEntry) {
    if (this.observer != null && target.isIntersecting) {
      this.onTrigger(this.observer);
    }
  }

  beforeDestroy() {
    if (this.observer != null) {
      this.observer.disconnect();
    }
  }
}
