



































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Mutation, Getter, namespace } from "vuex-class";
import hotelHeader from "@/components/layouts/hotelHeader.vue";
import leftTab from "@/components/layouts/leftTab.vue";
import rightTab from "@/components/layouts/rightTab.vue";
import moment from "moment";
import { axiosClient } from "@/http/client";
import { Advertisement } from "@/http/api/advertisement";
import { Popup } from "@/http/api/popup";
import { AlertType, NotificationModel, SignalRStart } from "@/utiles/signalr";

import IssueV2ModuleClass from "@/store/modules/v2/issueV2Module";
import { Issue as LegacyIssueApi } from "@/http/api/issue";
import { Issue as IssueApi } from "@/http/api/v2/issue";

declare let Notification: any;

const Modal = namespace("modal");
const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const NoticeModule = namespace("NoticeModule");
const CollectionModule = namespace("CollectionModule");
const SettingModule = namespace("SettingModule");

const IssueV2Module = namespace("IssueV2Module");

@Component({
  components: {
    hotelHeader,
    leftTab,
    rightTab,
  },
})
export default class HotelLayout extends Vue {
  @Prop({ default: false }) dark: any;
  @Prop({ default: false }) wide: any;
  @Prop({ default: "" }) title: any;
  @Prop({ default: null }) filter: any;

  @Modal.Action("showModal") showModal: any;
  @Modal.Action("handleCancel") handleCancel: any;
  @Modal.State("notice") notice: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.State("hotelList") hotelList: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @HotelModule.Action("loadHotelListAsync") loadHotelListAsync: any;

  // @IssueModule.Action("loadIssue") loadIssue: any;
  // @IssueModule.Action("loadIssueReplies") loadIssueReplies: any;
  // @IssueModule.Action("deleteSingleAsync") deleteSingleAsync: any;

  @NoticeModule.Action("loadNoticeList") loadNoticeList: any;
  @NoticeModule.Action("loadNoticeCount") loadNoticeCount: any;
  @NoticeModule.Action("loadNoticeTopList") loadNoticeTopList: any;
  @NoticeModule.Action("loadNotice") loadNotice: any;
  @NoticeModule.Action("loadNoticeReplies") loadNoticeReplies: any;

  @CollectionModule.State("bookmarkOrder") bookmarkOrder: any;
  @CollectionModule.Action("loadNotifications") loadNotifications: any;
  @CollectionModule.Action("loadMentions") loadMentions: any;
  @CollectionModule.Action("loadFiles") loadFiles: any;
  @CollectionModule.Action("loadBookmarks") loadBookmarks: any;

  @SettingModule.Action("loadSettingData") loadSettingData: any;
  @SettingModule.Action("updateSettingAsync") updateSettingAsync: any;

  @IssueV2Module.Action("insertIssue") insertIssue!: IssueV2ModuleClass["insertIssue"];
  @IssueV2Module.Action("deleteIssue") deleteIssue!: IssueV2ModuleClass["deleteIssue"];

  selectedTab: string = "notification";
  open = false;

  baseUrl: string = axiosClient.defaults.baseURL || "";
  ads: any[] = [];

  async mounted() {
    this.load();
    this.loadChromeNotification();

    SignalRStart(this.onNotified);

    this.showNotice();
    this.loadAds();
  }

  async loadAds() {
    this.ads = (await Advertisement.List("hotel")) as any;
  }

  search() {
    this.$emit("search");
  }

  async load() {
    try {
      await Promise.all([this.loadHotelAsync(), this.loadInfoAsync(), this.loadHotelListAsync()]);

      if (this.hotelInfo) {
        const subdomain = location.hostname.split(".")[0];
        if (this.hotelInfo.hotelUrls.findIndex((item: any) => item === subdomain) === -1) {
          throw "unacceptable hotel.";
        }
      }
    } catch {
      this.showModal({
        type: "errorModal",
        maskClosable: false,
        keyboard: false,
        props: {},
      });
    }
  }

  chromeAlarm(model: NotificationModel) {
    if (!Notification) {
      return;
    }

    if (Notification.permission === "granted") {
      new Notification("새로운 이슈가 등록되었습니다.", {
        body: model.data.category + ", " + model.data.contents,
        icon: "/assets/home/logo.png",
      });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  }

  // async loadIssueInfo(targetObjectId: any) {
  //   await Promise.all([this.loadIssue(targetObjectId), this.loadIssueReplies(targetObjectId)]);
  // }

  async loadNoticeInfo(targetObjectId: any) {
    await Promise.all([this.loadNoticeTopList(), this.loadNoticeReplies(targetObjectId)]);
  }

  async insertNotifiedIssue(objectId: string) {
    if (this.hotelInfo == null || this.hotelInfo.objectId == null) {
      return;
    }

    const issue = await IssueApi.getIssue(this.hotelInfo.objectId, objectId);

    this.insertIssue({
      issue: {
        objectId: issue.objectId,
        category: issue.category,
        content: issue.content,
        action: issue.action,
        status: issue.status,
        readCount: issue.readCount,
        totalRead: issue.totalRead,
        bookmarked: issue.bookmarked,
        read: issue.read,
        replyCount: issue.replyCount ?? 0,
        fileAttached: issue.files.length !== 0,
        checklistAttached: issue.checklists.length !== 0,
        teams: issue.teams,
        secret: issue.secret,
        writer: issue.writer,
        createdAt: issue.createdAt,
        updatedAt: issue.updatedAt,
      },
    });
  }

  deleteNotifiedIssue(objectId: string) {
    this.deleteIssue({ objectId });
  }

  onNotified(model: NotificationModel) {
    const { type, targetObjectId } = model;
    const { targetType } = model.data;

    this.loadNotifications({ clear: true });

    switch (type) {
      case AlertType.NewIssue:
        this.$message.info("새 이슈가 작성되었습니다.");
        this.chromeAlarm(model);
        this.insertNotifiedIssue(targetObjectId);
        break;

      case AlertType.IssueStatus:
        // case AlertType.IssueImportant:
        // case AlertType.IssueComplain:
        this.$message.info("이슈 상태가 변경되었습니다.");
        this.insertNotifiedIssue(targetObjectId);
        break;

      case AlertType.IssueChange:
        this.$message.info("이슈가 수정되었습니다.");
        this.insertNotifiedIssue(targetObjectId);
        break;

      case AlertType.DeleteIssue:
        this.$message.info("이슈가 삭제되었습니다.");
        this.deleteNotifiedIssue(targetObjectId);
        break;

      case AlertType.NewNotice:
        this.$message.info("새 공지사항이 작성되었습니다.");
        this.loadNoticeInfo(targetObjectId);
        break;

      case AlertType.UpdateNotice:
        this.$message.info("공지사항이 수정되었습니다.");
        this.loadNoticeInfo(targetObjectId);
        break;

      case AlertType.DeleteNotice:
        this.$message.info("공지사항이 삭제되었습니다.");
        this.loadNoticeInfo(targetObjectId);

        break;

      case AlertType.NewReply:
        this.$message.info("새 댓글이 작성되었습니다.");
        switch (targetType) {
          case "issue":
            this.insertNotifiedIssue(targetObjectId);
            break;

          case "notice":
            this.loadNoticeInfo(targetObjectId);
            break;
        }
        break;

      case AlertType.ChangeReply:
        this.$message.info("댓글이 수정되었습니다.");
        switch (targetType) {
          case "issue":
            this.insertNotifiedIssue(targetObjectId);
            break;

          case "notice":
            this.loadNoticeInfo(targetObjectId);
            break;
        }
        break;

      case AlertType.DeleteReply:
        this.$message.info("댓글이 삭제되었습니다.");
        switch (targetType) {
          case "issue":
            this.insertNotifiedIssue(targetObjectId);
            break;

          case "notice":
            this.loadNoticeInfo(targetObjectId);
            break;
        }
        break;

      case AlertType.BookmarkChanged:
        switch (targetType) {
          case "issue":
            this.insertNotifiedIssue(targetObjectId);
            break;

          case "notice":
            this.loadNoticeInfo(targetObjectId);
            break;
        }
        this.loadBookmarks({ clear: true, order: this.bookmarkOrder });
        break;

      case AlertType.FileChanged:
        this.loadFiles(true);
        break;

      case AlertType.MentionChanged:
        this.loadMentions(true);
        break;

      case AlertType.IssueTodo:
        this.insertNotifiedIssue(targetObjectId);
        break;
    }
  }

  async loadChromeNotification() {
    if (Notification) {
      Notification.requestPermission();
    }
  }

  async showNotice() {
    let popups: any = [];

    try {
      popups = await Popup.All();
    } catch {}

    const lastPopup = localStorage.getItem("lastPopup");
    if (lastPopup) {
      popups = popups.filter((popup: any) => moment(lastPopup).isBefore(moment(popup.updatedTime)));
    }

    if (popups.length == 0) return;

    this.showModal({
      type: "notice-modal",
      maskClosable: false,
      props: { data: popups },
      onCancel: async () => {
        localStorage.setItem("lastPopup", moment().toString());
        this.handleCancel({ forceClose: true });
      },
    });
  }

  change(value: any) {
    if (this.selectedTab !== value) {
      this.open = true;
    } else {
      this.toggle();
    }

    this.selectedTab = value;
  }

  toggle() {
    this.open = !this.open;
  }

  imageUrl(fileUrl: string) {
    if (fileUrl.startsWith("http")) {
      return fileUrl;
    } else return `https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/${fileUrl}`;
  }
}
