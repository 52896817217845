
































import { Component, Prop, Vue } from "vue-property-decorator";
import FullCalendar, { VerboseFormattingArg } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import moment, { now } from "moment";
import { namespace } from "vuex-class";
import IssueCardLegacy from "@/components/issues/issueCardLegacy.vue";
import fixedButtonContainer from "@/components/basic/fixedButtonContainer.vue";
import issueWriteButton from "@/components/issues/issueWriteButton.vue";
import ScrollUp from "@/components/basic/scrollUp.vue";
import IssueFilter from "@/components/issues/issueFilter.vue";

const IssueModule = namespace("IssueModule");
const HotelModule = namespace("HotelModule");
const Modal = namespace("modal");

@Component({
  components: {
    FullCalendar,
    IssueCardLegacy,
    ScrollUp,
    issueWriteButton,
    fixedButtonContainer,
    IssueFilter,
  },
})
export default class Calendar extends Vue {
  @Modal.Action("showModal") showModal: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @IssueModule.State("issueMap") issueMap: any;
  @IssueModule.State("periodIssueList") periodIssueList: any;
  @IssueModule.Action("writeIssue") writeIssue: any;
  @IssueModule.Action("loadReservePeriodList") loadReservePeriodList: any;
  @IssueModule.Action("deleteIssue") deleteIssue: any;
  @IssueModule.Action("updateReservation") updateReservation: any;

  issueStatusColor = ["#ff5371", "#ffaa00", "#0acf9d", "#4c4c4c"];

  filter: any = {
    skip: 0,
    take: 20,
    isNew: false,
    status: [],
    keyword: "",
    keywordType: "contents",
    teamObjectIds: [],
    category: [],
    startDate: null,
    endDate: null,
  };

  open = true;
  selectedDay = moment().add(1, "days");
  month: any = {};

  todayButtonActive() {
    document.querySelector(".fc-today-button")?.removeAttribute("disabled");
  }

  get calendarOptions() {
    return {
      dateClick: async (dateInfo: DateClickArg) => {
        const date = moment(dateInfo.dateStr);

        if (this.selectedDay.isSame(date)) {
          this.write();
        }

        this.selectedDay = date;
      },
      titleFormat: ({ date }: VerboseFormattingArg) => {
        return moment(date.marker).format("YYYY년 M월");
      },
      viewDidMount: () => {
        document
          .querySelectorAll(".fc-button")
          .forEach((el) => el.classList.remove("fc-button-primary"));
      },
      select: ({ startStr }: any) => {
        const calendarDate = this.api.getDate();
        if (!moment(startStr).isSame(calendarDate, "month")) {
          this.api.gotoDate(startStr);
          this.api.select(startStr);
        }
      },
      eventResize: this.upadteEvent,
      eventDrop: this.upadteEvent,
      eventClick: ({ event }: any) => this.edit(event.extendedProps.issue.objectId),
      eventDidMount: () => this.todayButtonActive(),
      customButtons: {
        today: {
          text: "오늘",
          click: () => {
            const now_date: any = now();
            this.api.gotoDate(now_date);
            this.api.select(now_date);
          },
        },
      },
      eventSources: [{ events: this.events }],
      height: "auto",
      displayEventTime: false,
      editable: true,
      selectable: true,
      unselectAuto: false,
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        start: "input",
        center: "prev title next",
        end: "today",
      },
    };
  }

  get api() {
    const calendar: any = this.$refs.fullCalendar;
    return calendar.getApi();
  }

  get openCalendarImage() {
    return this.open
      ? "/assets/components/calendar-open.svg"
      : "/assets/components/calendar-close.svg";
  }

  get issues() {
    return this.periodIssueList.map(this.issueToEvent);
  }

  get selectedDayIssues() {
    const issues = this.periodIssueList.filter((issue: any) => {
      const startTime = moment(issue.reservedStartDate);
      const endTime = moment(issue.reservedEndDate);

      return (
        this.selectedDay.isSame(startTime, "day") ||
        this.selectedDay.isSame(endTime, "day") ||
        (this.selectedDay.isBefore(endTime, "day") && this.selectedDay.isAfter(startTime, "day"))
      );
    });

    return this.reservedIssueSort(issues);
  }

  async upadteEvent({ oldEvent, event }: any) {
    const issue = oldEvent.extendedProps.issue;

    const reservedStartDate = moment(event.start);
    const reservedEndDate = moment(event.end).subtract(1, "days");

    await this.updateReservation({
      objectId: issue.objectId,
      reservedStartDate,
      reservedEndDate,
    });

    this.refetchEvents();
  }

  reservedIssueSort(unSortedIssues: any[]) {
    const nonStartTimeIssues = unSortedIssues.filter((i) => i.reservedStartTime == null);
    const hasStartTimeIssues = unSortedIssues.filter((i) => i.reservedStartTime != null);
    const result = hasStartTimeIssues
      .sort((a: any, b: any) => this.compareTime(a, b))
      .concat(nonStartTimeIssues);
    return result;
  }

  compareTime(first: any, second: any) {
    const firstSplit: any[] = first.reservedStartTime.split(":");
    const secondSplit: any[] = second.reservedStartTime.split(":");
    if (firstSplit[0] * 1 > secondSplit[0] * 1) {
      return 1;
    }
    if (firstSplit[0] * 1 == secondSplit[0] * 1) {
      if (firstSplit[1] * 1 > secondSplit[1] * 1) {
        return 1;
      } else if (firstSplit[1] * 1 == secondSplit[1] * 1) {
        return 0;
      }
      return -1;
    }
    return -1;
  }

  async load() {
    await this.loadReservePeriodList({
      start: this.filter.startDate ?? this.month.start ?? moment().startOf("month"),
      end: this.filter.endDate ?? this.month.end ?? moment().endOf("month"),
      teamObjectIds: this.filter.teamObjectIds ? [...this.filter.teamObjectIds] : [],
      categories: this.filter.category ? [...this.filter.category] : [],
      keyword: this.filter.keyword ?? undefined,
      keywordType: this.filter.keywordType,
      status: this.filter.status ? [...this.filter.status] : [],
      isNew: this.filter.isNew,
    });
  }

  async filteredLoad() {
    await this.load();
    this.refetchEvents();
  }

  async events(month: any, callback: (args: any) => void) {
    this.month = month;
    await this.load();
    callback(this.issues);
  }

  refetchEvents() {
    this.api.refetchEvents();
  }

  write() {
    const date = moment().isBefore(this.selectedDay, "days")
      ? this.selectedDay
      : moment().add(1, "days");

    this.showModal({
      type: "write-issue-modal",
      transparent: true,
      maskClosable: false,
      props: { defaultData: { reservedStartDate: date }, openReserved: true },
      onOk: this.refetchEvents,
    });
  }

  edit(objectId: any) {
    const data = this.periodIssueList.find((item: any) => item.objectId === objectId);

    this.showModal({
      type: "write-issue-modal",
      transparent: true,
      maskClosable: false,
      props: { defaultData: data },
      onOk: this.refetchEvents,
    });
  }

  issueToEvent(issue: any) {
    const color = this.issueStatusColor[issue.status];

    const { reservedStartDate, reservedEndDate, reservedStarTime, reservedEndTime } = issue;

    const startTime = moment(reservedStartDate);
    let endTime = reservedEndDate ? moment(reservedEndDate) : moment(reservedStartDate);

    if (startTime.diff(endTime, "days") < 1) {
      endTime = endTime.add(1, "days");
    }

    const title = `[${issue.category}] ${issue.contents}`;
    const event = {
      title,
      start: startTime.startOf("day").format("YYYY-MM-DD"),
      end: endTime.endOf("day").format("YYYY-MM-DD"),
      backgroundColor: color,
      borderColor: color,
      durationEditable: true,
      startEditable: true,
      issue,
    };

    return event;
  }
}
