












import Vue from "vue";
import Component from "vue-class-component";
import { State, Action, namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import { Auth } from "@/http/api/auth";

const Modal = namespace("modal");
const HotelModule = namespace("HotelModule");

@Component({
  name: "ErrorModal",
})
export default class ErrorModal extends Vue {
  @Modal.Action("handleCancel") handleCancel: any;
  @Modal.Action("handleOk") handleOk: any;
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  toSelectHotel() {
    this.toHotelUrl({ path: "selectHotel" });
  }

  async logout() {
    try {
      await Auth.logout();
    } catch {}

    this.toHotelUrl({});
  }
}
Vue.component("ErrorModal", ErrorModal);
