




















import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";

@Component
export default class HomeLayout extends Vue {
  @Prop({ default: false }) fullwidth!: boolean;

  mounted() {}
}
