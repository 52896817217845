














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";

const Modal = namespace("modal");

const baseUrl = "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/";
const pattern = /^https?:\/\//;

@Component({
  name: "imageDetailModal",
})
export default class ImageDetailModal extends Vue {
  @Prop({ default: () => [] }) data!: any[];
  @Prop({ default: 0 }) initIndex: any;

  @Modal.State("modal") modal: any;
  @Action("modal/handleCancel") handleCancel: any;
  @Action("modal/handleOk") handleOk: any;

  copiedData: any[] = [];

  index = 0;
  show = false;

  get images() {
    return this.copiedData.map((item: any) => ({
      ...item,
      url: (item.url as string).startsWith("http") ? item.url : baseUrl + item.url,
    }));
  }

  get currentImage() {
    if (!this.show) {
      return "";
    }

    return this.images[this.index].url;
  }

  created() {
    this.index = this.initIndex;
  }

  mounted() {
    setTimeout(() => {
      this.onDataChange(this.data);
    }, 150);

    setTimeout(() => {
      this.show = true;
    }, 200);
  }

  prev() {
    this.index = (this.index + this.data.length - 1) % this.data.length;
  }

  next() {
    this.index = (this.index + 1) % this.data.length;
  }

  @Watch("data", { deep: true })
  onDataChange(val: any[]) {
    this.copiedData = [...val];
  }
}
Vue.component("ImageDetailModal", ImageDetailModal);
