















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import adminOrganizationTree from "@/components/admin/adminOrganizationTree.vue";
import { Hotel } from "@/http/api/hotel";

const HotelModule = namespace("HotelModule");
const MemberModule = namespace("MemberModule");
const Modal = namespace("modal");

@Component({
  components: {
    adminOrganizationTree,
  },
})
export default class Team extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @Modal.Action("showModal") showModal: any;
  @MemberModule.State("memberList") memberList: any;
  @MemberModule.Action("loadMemberListAsync") loadMemberListAsync: any;

  get permission() {
    if (this.hotelInfo.userPermission == 3) {
      return true;
    }
    return false;
  }

  get rules() {
    return {
      name: [
        {
          required: true,
          message: "부서명을 입력해주세요.",
        },
      ],
    };
  }

  get columns() {
    return [
      {
        width: 150,
        dataIndex: "team",
        title: "소속 부서",
        customRender: (_: any, row: any) =>
          row.teamObjectId
            ? this.hotelInfo?.teams.find((a: any) => a.objectId === row.teamObjectId).name
            : "",
      },
      {
        title: "이름",
        dataIndex: "name",
      },
      {
        title: "직급",
        dataIndex: "rank",
      },
      {
        title: "휴대폰번호",
        dataIndex: "phone",
      },
      {
        width: 60,
        align: "center",
        title: "소속",
        scopedSlots: { customRender: "mainStatus" },
      },
      {
        width: 60,
        align: "center",
        title: "구독",
        scopedSlots: { customRender: "status" },
      },
    ];
  }

  editTeam: any = null;
  editMember: any = null;

  isDisabled(row: any) {
    if (row.teamObjectId == this.hotelInfo.teamObjectId || !row.teamObjectId) {
      return false;
    }
    return true;
  }

  mounted() {
    this.load();
  }

  async load() {
    await this.loadMemberListAsync();
    (this.$refs.tree as any)?.load();
  }

  showEditTeam(team: any) {
    this.editTeam = {
      ...(team || {}),
      title: team ? "부서 수정" : "부서 추가",
      okText: team ? "수정" : "추가",
    };
  }

  closeEditTeam() {
    this.editTeam = null;
  }

  applyEditTeam() {
    const form: any = this.$refs.form;
    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      const { okText, objectId, name } = this.editTeam;

      try {
        if (objectId) {
          await Hotel.updateTeamAsync({ objectId, name });
        } else {
          await Hotel.createTeamAsync({ name });
        }

        this.$message.success(`부서가 ${okText} 되었습니다.`);
        this.load();
        this.closeEditTeam();
      } catch {
        this.$message.error(`부서가 ${okText} 되었습니다.`);
      }
    });
  }

  showEditMember(team: any) {
    const { name, objectId } = team;
    const members = this.memberList.map(
      ({ objectId, name, rank, phone, teamObjectId, teamObjectIds }: any) => ({
        objectId,
        name,
        rank,
        phone,
        teamObjectId,
        teamObjectIds: [...teamObjectIds],
        origin: {
          teamObjectId,
        },
      }),
    );

    this.editMember = {
      name,
      objectId,
      members,
    };
  }

  hasMember(item: any) {
    if (!this.editMember) {
      return false;
    }

    const { objectId } = this.editMember;
    return item.teamObjectIds.indexOf(objectId) !== -1;
  }

  isMain(item: any) {
    if (!this.editMember) {
      return false;
    }

    const { objectId } = this.editMember;
    return item.teamObjectId === objectId;
  }

  toggleMember(item: any) {
    if (!this.editMember) {
      return;
    }

    const { objectId } = this.editMember;
    if (this.hasMember(item)) {
      item.teamObjectIds = item.teamObjectIds.filter((a: any) => a !== objectId);

      if (this.isMain(item)) {
        item.teamObjectId = item.origin.teamObjectId === objectId ? null : item.origin.teamObjectId;
      }
    } else {
      item.teamObjectIds = [...item.teamObjectIds, objectId];
    }
  }

  toggleMain(item: any) {
    if (!this.editMember) {
      return;
    }

    const { objectId } = this.editMember;
    if (this.isMain(item)) {
      item.teamObjectId = item.origin.teamObjectId === objectId ? null : item.origin.teamObjectId;
    } else {
      item.teamObjectId = objectId;
      item.teamObjectIds = [...item.teamObjectIds, objectId].reduce(
        (result: any, item: any) => (result.indexOf(item) !== -1 ? result : [...result, item]),
        [],
      );
    }
  }

  rowClassName = (item: any, index: any) => {
    return this.hasMember(item) ? "member-row member-row-selected" : "member-row";
  };

  closeEditMember() {
    this.editMember = null;
  }

  async applyEditMember() {
    const { objectId, members } = this.editMember;

    try {
      await Hotel.updateTeamMember(objectId, members);

      this.$message.success(`부서 구성원이 수정 되었습니다.`);
      this.load();
      this.closeEditMember();
    } catch {
      this.$message.error(`부서 구성원 수정에 실패하였습니다.`);
    }
  }
}
