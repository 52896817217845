






























import Vue from "vue";

import { State, Action, namespace } from "vuex-class";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

const WorkModule = namespace("WorkModule");
const Modal = namespace("modal");

@Component({
  name: "WorkModal",
  components: {},
})
export default class WorkModal extends Vue {
  @Prop({ default: () => ({}) }) data: any;

  @WorkModule.State("work") work: any;
  @WorkModule.Action("loadWork") loadWork: any;
  @WorkModule.Action("updateWorkCardSubject") updateWorkCardSubject: any;
  @WorkModule.Action("deleteWorkCard") deleteWorkCard: any;
  @Modal.Action("handleCancel") handleCancel: any;

  card: any = {};
  category: any;
  showEdit = false;
  textareaLine = 4;
  newSubject = "";

  mounted() {
    this.category = this.work.categories.find((c: any) => c.objectId == this.data.categoryId);
    this.card = { ...this.data.card };
  }

  deleteCard() {
    this.onClose();
    const categoryId = this.category.objectId;
    const cardId = this.card.objectId;
    this.deleteWorkCard({ categoryId, cardId });
  }

  plusMaxLine() {
    this.textareaLine++;
  }

  toggleEdit() {
    this.showEdit = !this.showEdit;
  }

  updateCard() {
    const categoryId = this.category.objectId;
    const cardId = this.card.objectId;
    const subject = this.card.subject;
    this.work.categories
      .find((c: any) => c.objectId == categoryId)
      .cards.find((c: any) => c.objectId == cardId).subject = subject;
    this.updateWorkCardSubject({ categoryId, cardId, subject });
    this.onClose();
  }

  onClose() {
    this.handleCancel({ forceClose: true });
    this.loadWork();
  }
}
Vue.component("WorkModal", WorkModal);
