var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-wrap"},[_c('div',{staticClass:"hotel-info-contents",attrs:{"df":"","fw":"","fdr":"","fjb":""}},[_c('div',{staticClass:"hotel-infos",attrs:{"flex":"","df":"","fdc":""}},[_c('setting-item',{attrs:{"title":"회사명","content":_vm.hotelInfo.name}}),_c('setting-item',{attrs:{"title":"사이트 URL","content":_vm.hotelInfo.url + '.rumykorea.co.kr'}}),_c('setting-item',{attrs:{"title":"총 사용자 수","content":_vm.hotelInfo.memberCount}})],1),_c('div',{staticClass:"hotel-infos",attrs:{"flex":"","df":"","fdc":""}},[_c('setting-item',{attrs:{"title":"도입일자","content":_vm.rumyStartDate}}),_c('setting-item',{attrs:{"title":"담당자명(ID)","content":_vm.hotelOwnerInfo}}),_c('setting-item',{attrs:{"title":"접속브라우저/운영체제","content":_vm.browser,"tooltip":true}})],1)]),_c('a-divider',{staticClass:"divider"}),_c('div',{staticClass:"notification-body",attrs:{"df":"","fdr":""}},[_c('div',{staticClass:"notification-title",attrs:{"flex":""}},[_vm._v("알림 설정")]),_c('div',{attrs:{"flex":"3"}},[_c('a-collapse',{model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('a-collapse-panel',{key:"1",attrs:{"header":"이슈"}},[_c('div',{staticClass:"title-checkboxes-gap",attrs:{"df":"","fdr":"","fic":""}},[_c('div',{staticClass:"create-checkboxes-title"},[_vm._v("등록")]),_c('div',{staticClass:"create-checkboxes",attrs:{"df":"","fdr":"","fic":""}},[_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_team_create == 'true' || !_vm.settingData.push_team_create
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_team_create')}}},[_vm._v("내 부서가 멘션된 이슈")]),_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_mention_create == 'true' || !_vm.settingData.push_mention_create
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_mention_create')}}},[_vm._v("1:1 멘션된 이슈")]),_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_reply_create == 'true' || !_vm.settingData.push_reply_create
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_reply_create')}}},[_vm._v("댓글")])],1)]),_c('div',{staticClass:"title-checkboxes-gap",attrs:{"df":"","fdr":"","fic":""}},[_c('div',{staticClass:"update-checkboxes-title"},[_vm._v("수정")]),_c('div',{staticClass:"update-checkboxes",attrs:{"df":"","fdr":"","fic":""}},[_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_team_update == 'true' || !_vm.settingData.push_team_update
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_team_update')}}},[_vm._v("내 부서가 멘션된 이슈")]),_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_mention_update == 'true' || !_vm.settingData.push_mention_update
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_mention_update')}}},[_vm._v("1:1 멘션된 이슈")]),_c('a-checkbox',{attrs:{"checked":_vm.settingData.push_scrap_update == 'true' || !_vm.settingData.push_scrap_update
                    ? true
                    : false},on:{"change":function($event){return _vm.onChange($event, 'push_scrap_update')}}},[_vm._v("스크랩한 이슈")])],1)])]),_c('a-collapse-panel',{key:"2",attrs:{"header":"게시글"}},[_c('div',{staticClass:"title-checkboxes-gap",attrs:{"df":"","fdr":"","fic":""}},[_c('div',{staticClass:"create-checkboxes-title"},[_vm._v("등록")]),_c('a-checkbox',{staticClass:"create-checkboxes",attrs:{"checked":_vm.settingData.push_notice_create == 'true' || !_vm.settingData.push_notice_create
                  ? true
                  : false},on:{"change":function($event){return _vm.onChange($event, 'push_notice_create')}}},[_vm._v("전체 게시글")])],1),_c('div',{staticClass:"title-checkboxes-gap",attrs:{"df":"","fdr":"","fic":""}},[_c('div',{staticClass:"update-checkboxes-title"},[_vm._v("수정")]),_c('a-checkbox',{staticClass:"update-checkboxes",attrs:{"checked":_vm.settingData.push_notice_update == 'true' || !_vm.settingData.push_notice_update
                  ? true
                  : false},on:{"change":function($event){return _vm.onChange($event, 'push_notice_update')}}},[_vm._v("전체 게시글")])],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }