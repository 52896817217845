import Client from "../client";

export class Reply {
  static async replyList(parentObjectId: string) {
    return await Client.get<any[]>(`/issueReply/${parentObjectId}`);
  }

  static async createReply({ parentObjectIds, contents, files }: any) {
    return await Client.put(`/issueReply/${parentObjectIds}`, {
      contents,
      files,
    });
  }

  static async updateReply({ parentObjectIds, objectId, contents, files }: any) {
    return await Client.post(`/issueReply/${parentObjectIds}/${objectId}`, {
      contents,
      files,
    });
  }

  static async deleteReply(parentObjectIds: string, objectId: string) {
    return await Client.del(`/issueReply/${parentObjectIds}/${objectId}`);
  }
}
