import Client from "../client";
import {
  SendCodeModel,
  CodeModel,
  FindIdModel,
  FindPasswordModel,
  FindPasswordChangeModel,
} from "@/interfaces/Auth";

export class Auth {
  static async login(id: string, password: string) {
    return await Client.post(`/auth/login`, {
      id,
      password,
    });
  }

  static async register(data: any) {
    return await Client.put(`/auth/register`, data);
  }

  static async hasHotel() {
    return await Client.get<any>(`/auth/hasHotel`, {});
  }

  static async checkId(id: string) {
    return await Client.get(`/auth/CheckId`, {
      id,
    });
  }

  static async checkEmail(email: string) {
    return await Client.get(`/auth/CheckEmail`, {
      email,
    });
  }

  static async sendCode(options: any): Promise<CodeModel> {
    return await Client.post<CodeModel>(`/auth/sendCode`, options);
  }

  static async verifyCode(codeModel: CodeModel) {
    return await Client.put<boolean>(`/auth/verifyCode/${codeModel.objectId}`, codeModel);
  }

  static async checkUrl(url: string) {
    return await Client.get(`/auth/CheckUrl`, {
      url,
    });
  }

  static async findId(data: any) {
    return await Client.post<string>(`/auth/find/id`, data);
  }

  static async findPassword(data: any) {
    return await Client.post<string>(`/auth/find/password`, data);
  }

  static async passwordChange(password: string, newPassword: string) {
    return await Client.post(`/auth/password`, {
      password,
      newPassword,
    });
  }

  static async findPasswordChange(findPasswordChangeModel: FindPasswordChangeModel) {
    return await Client.put<boolean>(
      `/auth/findPassword/change/${findPasswordChangeModel.objectId}`,
      findPasswordChangeModel,
    );
  }

  static async logout() {
    return await Client.get(`/auth/logout`);
  }

  static async loadSelfInfo() {
    return await Client.get<any>(`/auth/self`);
  }
}

export interface RegisterModel {
  id: string;
  password: string;
  name: string;
  email: string;
}

export interface RegisterForm {
  id: string;
  password: string;
  passwordConfirm: string;
  name: string;
  email: string;

  validateStatus: {
    id: string;
    password: string;
    passwordConfirm: string;
    name: string;
    email: string;
  };

  error: {
    id: string;
    password: string;
    passwordConfirm: string;
    name: string;
    email: string;
  };
}
