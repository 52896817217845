




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import SweetScroll from "sweet-scroll";
import { logoUrl } from "@/constants";

const HotelModule = namespace("HotelModule");
const IssueModule = namespace("IssueModule");
const NoticeModule = namespace("NoticeModule");

const scroller = new SweetScroll();

@Component
export default class LeftTab extends Vue {
  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;

  @HotelModule.State("hotelList") hotelList: any;
  @HotelModule.Action("loadHotelListAsync") loadHotelListAsync: any;

  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  @IssueModule.Action("loadIssueList") loadIssueList: any;

  @NoticeModule.Action("loadNoticeList") loadNoticeList: any;

  get activeKey() {
    const key: any = this.$route.name;
    const item = this.menu.find(
      (item) => ((item.key === key || item.children?.indexOf(key)) ?? -1) !== -1,
    );
    return item?.key;
  }

  get hotelUrl() {
    const domains = location.hostname.split(".");
    return domains.length >= 3 ? domains[0] : "";
  }

  get logo() {
    return this.hotelInfo && logoUrl(this.hotelInfo.logo);
  }

  get permission() {
    if (this.hotelInfo.userPermission == 3 || this.hotelInfo.userPermission == 2) {
      return true;
    }
    return false;
  }

  get supporter() {
    return this.hotelInfo?.userRole?.toLowerCase() === "supporter";
  }

  menu = [
    {
      key: "issue",
      title: "이슈",
      image: "/assets/components/issue.svg",
      hoverImage: "/assets/components/issue-white.svg",
      children: ["root", "issue-detail"],
    },
    {
      key: "work",
      title: "내 업무",
      image: "/assets/components/work.svg",
      hoverImage: "/assets/components/work-white.svg",
    },
    {
      key: "notice",
      title: "게시글",
      image: "/assets/components/noticeManage.svg",
      hoverImage: "/assets/components/noticeManage-white.svg",
      children: ["notice-detail"],
    },
    {
      key: "calendar",
      title: "캘린더",
      image: "/assets/components/calendar.png",
      hoverImage: "/assets/components/calendar-white.png",
    },
    {
      key: "settings",
      title: "설정",
      image: "/assets/components/settings.svg",
      hoverImage: "/assets/components/settings-white.svg",
    },
  ];

  async mounted() {
    this.load();
  }

  async load() {
    await Promise.all([this.loadInfoAsync(), this.loadHotelListAsync()]);
  }

  async changeActiveIndex(item: any) {
    switch (item.key) {
      case "issue":
        await this.loadIssueList();
        scroller.to(0);
        break;

      case "work":
        await this.loadNoticeList();
        scroller.to(0);
        break;

      case "notice":
        scroller.to(0);
        break;

      case "settings":
        // await this.loadNoteListAsync();
        // await this.loadTotalCountAsync();
        scroller.to(0);
        break;
    }
  }

  changeHotel(url: any) {
    this.toHotelUrl({ url });
  }
}
