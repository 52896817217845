import Client from "@/http/client";
import { PaginatedDto } from "@/http/dto/paginated";
import {
  AppVersionCreateDto,
  AppVersionDto,
  AppVersionFilterParams,
  AppVersionUpdateDto,
} from "@/http/dto/app";

export class App {
  static async getAppVersions(params?: AppVersionFilterParams) {
    return await Client.get<PaginatedDto<AppVersionDto>>("/v2/supporter/app/versions", params);
  }

  static async createAppVersion(version: AppVersionCreateDto) {
    return await Client.post("/v2/supporter/app/versions", version);
  }

  static async updateAppVersion(objectId: string, version: AppVersionUpdateDto) {
    return await Client.put(`/v2/supporter/app/versions/${objectId}`, version);
  }

  static async deleteAppVersion(objectId: string) {
    return await Client.del(`/v2/supporter/app/versions/${objectId}`);
  }
}
