import Client from "../client";

export class Work {
  static async getWork() {
    return await Client.get<any>(`/work`);
  }

  static async createWorkCategory(value: any) {
    return await Client.put<number>(`/work/category`, value);
  }

  static async updateWorkCategory(categoryId: string, value: any) {
    return await Client.post<number>(`/work/category/${categoryId}`, value);
  }

  static async updateWorkCategoryTitle(categoryId: string, value: any) {
    return await Client.post<number>(`/work/category/${categoryId}/title`, value);
  }

  static async updateWorkCategoryColor(categoryId: string, value: any) {
    return await Client.post<number>(`/work/category/${categoryId}/color`, value);
  }

  static async deleteWorkCategory(categoryId: string) {
    return await Client.del<number>(`/work/category/${categoryId}`);
  }

  static async createWorkCard(categoryId: string, value: any) {
    return await Client.put(`/work/category/${categoryId}/card`, value);
  }

  static async updateWorkCard(categoryId: string, cardId: string, value: any) {
    return await Client.post(`/work/category/${categoryId}/card/${cardId}`, value);
  }

  static async updateWorkCardSubject(categoryId: string, cardId: string, value: any) {
    return await Client.post(`/work/category/${categoryId}/card/${cardId}/subject`, value);
  }

  static async deleteWorkCard(categoryId: string, cardId: string) {
    return await Client.del(`/work/category/${categoryId}/card/${cardId}`);
  }
}
