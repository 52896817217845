







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

import draggable from "vuedraggable";

import { Hotel } from "@/http/api/hotel";

const HotelModule = namespace("HotelModule");
const TeamModule = namespace("TeamModule");
const Modal = namespace("modal");

@Component({
  components: {
    draggable,
  },
})
export default class Category extends Vue {
  get rules() {
    return {
      name: [
        {
          required: true,
          message: "카테고리명을 입력해주세요.",
        },
      ],
    };
  }

  activeKey: any = "issue";

  createCategory: any = null;

  tabs: any = [
    {
      key: "issue",
      title: "이슈 카테고리 관리",
      closable: false,

      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },

      loading: false,
      createLoading: false,
      isMovingToBack: false,
      sortChangeLoading: false,
      data: [],
    },
    {
      key: "notice",
      title: "게시글 카테고리 관리",
      closable: false,

      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },

      loading: false,
      createLoading: false,
      isMovingToBack: false,
      sortChangeLoading: false,
      data: [],
    },
  ];

  mounted() {
    this.load();
  }

  async load() {
    const result = await Promise.all([Hotel.categoryListAsync(), Hotel.noticeCategoryListAsync()]);

    this.tabs[0].data = result[0];
    this.tabs[1].data = result[1];
  }

  async changeCategoriesOrder(tab: any) {
    if (tab.sortChangeLoading) {
      return;
    }

    try {
      tab.sortChangeLoading = true;

      switch (tab.key) {
        case "issue":
          await Hotel.updateCategoryOrders(tab.data);
          break;

        case "notice":
          await Hotel.updateNoticeCategoryOrders(tab.data);
          break;
      }

      this.$message.success("카테고리 순서가 적용되었습니다.");
      await this.load();
    } catch {
    } finally {
      tab.sortChangeLoading = false;
    }
  }

  showCreateCategory(type: any) {
    this.createCategory = { type };
  }

  closeCreateCategory() {
    this.createCategory = null;
  }

  async applyCreateCategory() {
    try {
      const { type } = this.createCategory;

      switch (type) {
        case "issue":
          await Hotel.createCategoryAsync({ name: this.createCategory.name });
          break;

        case "notice":
          await Hotel.createNoticeCategoryAsync({ name: this.createCategory.name });
          break;
      }

      this.$message.success(`카테고리를 추가하였습니다.`);
      this.closeCreateCategory();
      await this.load();
    } catch {
      this.$message.error(`카테고리 추가에 실패하였습니다.`);
      return;
    }
  }

  async deleteCategory(type: string, name: string) {
    try {
      switch (type) {
        case "issue":
          await Hotel.deleteCategoryAsync(name);
          break;

        case "notice":
          await Hotel.deleteNoticeCategoryAsync(name);
          break;
      }

      this.$message.success("카테고리 삭제에 성공하였습니다.");
      await this.load();
    } catch {
      this.$message.error("카테고리 삭제에 실패하였습니다.");
    }
  }
}
