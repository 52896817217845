import { render, staticRenderFns } from "./baseTerm.vue?vue&type=template&id=c895c4de&scoped=true&"
import script from "./baseTerm.vue?vue&type=script&lang=ts&"
export * from "./baseTerm.vue?vue&type=script&lang=ts&"
import style0 from "./baseTerm.vue?vue&type=style&index=0&id=c895c4de&lang=scss&scoped=true&"
import style1 from "./baseTerm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c895c4de",
  null
  
)

export default component.exports