













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { Support } from "@/http/api/support";
import moment from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class NoticeManagement extends Vue {
  @HotelModule.State("hotelObjectId") hotelObjectId: any;

  get hotelObjectIdValue() {
    return this.hotelObjectId;
  }

  @Watch("hotelObjectIdValue")
  hotelObjectIdChanged() {
    this.load();
  }

  columns = [
    {
      title: "카테고리",
      dataIndex: "category",
    },
    {
      title: "작성자",
      dataIndex: "writer",
    },
    {
      title: "제목",
      dataIndex: "title",
      ellipsis: true,
    },
    {
      title: "내용",
      dataIndex: "plainContents",
      ellipsis: true,
    },
    {
      align: "right",
      title: "생성일",
      dataIndex: "createdTime",
      customRender: (value: any) => moment(value).format("YYYY/MM/DD HH:mm:ss"),
    },
  ];

  get pagination() {
    return {
      total: this.total,
      pageSize: 20,
      current: this.current,
    };
  }

  loading = false;
  data = [];
  total = 0;
  current = 1;

  mounted() {
    this.load();
  }

  async load(pagination?: any) {
    try {
      this.loading = true;

      const { current = 1 } = pagination || {};
      const { count, list } = await Support.notices({
        page: current,
        hotelObjectId: this.hotelObjectId,
      });
      this.current = current;
      this.total = count;
      this.data = list;
    } catch {
    } finally {
      this.loading = false;
    }
  }
}
