import moment from "moment";

export function midnightTimer(func: Function, snooze: number, millisecond: number) {
    const now = moment().valueOf();
    const midnight = moment().add(1, "days").startOf("day").valueOf();

    for (let i = snooze; i > 0; i--) {
        if (i == 1) {
            setTimeout(() => {
                func();
                midnightTimer(func, snooze, millisecond);
            }, midnight - now);
        }
        else {
            setTimeout(func, midnight - now + (millisecond * i));
        }
    }
}