






import { Vue, Component } from "vue-property-decorator";

import FindAccountForm from "@/components/account/findAccountForm.vue";

enum Screens {
  FindPasswordForm,
  ChangePasswordForm,
}

@Component({
  components: {
    FindAccountForm,
  },
})
export default class FindPassword extends Vue {
  verified() {
    this.$router.push({ name: "root" });
  }
}
