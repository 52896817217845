import login from "./login.vue";
import registerHotel from "./registerHotel.vue";
import selectHotel from "./selectHotel.vue";
import findId from "./findId.vue";
import findPassword from "./findPassword.vue";

export default [
  {
    name: "login",
    path: "/login",
    component: login,
    layoutOptions: {
      fullwidth: true,
    },
  },
  {
    layout: "account-layout",
    name: "register-hotel",
    path: "/hotel/register",
    component: registerHotel,
    layoutOptions: {
      header: "호텔을 생성해주세요",
    },
  },
  {
    layout: "account-layout",
    name: "find-id",
    path: "/find/id",
    component: findId,
    layoutOptions: {
      header: "아이디 찾기",
      description: "",
    },
  },
  {
    layout: "account-layout",
    name: "find-password",
    path: "/find/password",
    component: findPassword,
    layoutOptions: {
      header: "비밀번호 찾기",
      description: "찾으시는 아이디는 아래와 같습니다.",
    },
  },
];
