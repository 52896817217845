import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@aspnet/signalr";
import { host } from "@/http/client";

export interface NotificationModel {
  type: number;
  hotelUrl: string;
  hotelObjectId: string;
  targetObjectId: string;
  targetType: string;
  data: any;
}

export const AlertType = {
  NewIssue: 101,
  NewRoom: 102,
  NewUser: 103,
  NewRequestedUser: 104,
  RefusedUser: 105,
  NewNote: 106,
  NewReply: 107,
  IssueStatus: 108,
  IssueChange: 109,
  IssueImportant: 110,
  IssueComplain: 111,
  ChangeReply: 112,
  DeleteReply: 113,
  DeleteIssue: 114,
  NewReservedIssue: 115,
  NewNotice: 116,
  UpdateNotice: 117,
  DeleteNotice: 118,
  BookmarkChanged: 200,
  FileChanged: 201,
  MentionChanged: 202,
  IssueTodo: 203,
};

const methods: Function[] = [];
let timeout: any = null;

const url = `${location.protocol}//${host}/notification`;

const connection = new HubConnectionBuilder()
  .withUrl(url)
  .configureLogging(LogLevel.Information)
  .build();

const connect = async () => {
  if (connection.state == HubConnectionState.Connected) {
    return;
  }

  await connection.start();
};

const tryReconnect = async () => {
  if (connection.state != HubConnectionState.Connected) {
    timeout = setTimeout(async () => {
      connect();
      tryReconnect();
    }, 5000);
  } else {
    if (timeout != null) {
      clearTimeout(timeout);
      timeout = null;
    }
  }
};

connection.onclose(async () => {
  await connect();
  tryReconnect();
});

connection.on("notified", (model: NotificationModel) => {
  const hotelUrl = window.location.host.split(".")[0];
  if (hotelUrl === model.hotelUrl) {
    methods.forEach(func => func(model));
  }
});

export const SignalRStart = async (method: any) => {
  methods.push(method);
  await connect();
};
