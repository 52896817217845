import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Member } from "@/http/api/member";
import { Auth } from "@/http/api/auth";
import moment from "moment";

const idRegex = /^\S{3,}$/;
const nameRegex = /^.{2,}$/;
const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{6,}$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Module({
  namespaced: true,
})
export default class MemberModule extends VuexModule {
  shouldBeLoaded = true;

  memberDetail: any = {
    validateStatus: {
      password: "",
      passwordConfirm: "",
      email: "",
      name: "",
    },
    error: {
      password: "",
      passwordConfirm: "",
      email: "",
      name: "",
    },
  };

  memberList: any[] = [];

  filter = {
    keyword: "",
  };

  privacyChangeForm: any = {
    id: "",
    password: "",
    passwordConfirm: "",
    name: "",
    email: "",
    privacy: false,

    validateStatus: {
      id: "",
      password: "",
      passwordConfirm: "",
      email: "",
      name: "",
      privacy: "",
    },

    error: {
      id: "",
      password: "",
      passwordConfirm: "",
      email: "",
      name: "",
      privacy: "",
    },
  };

  @Mutation
  setShouldBeLoaded(value: boolean) {
    this.shouldBeLoaded = value;
  }

  @Mutation
  setMemberList(value: any[]) {
    this.memberList = value;
  }

  @Mutation
  setMemberDetail(value: any) {
    this.memberDetail = value;
  }

  @Mutation
  setPrivacyChangeForm(value: any) {
    this.privacyChangeForm = value;
  }

  @Mutation
  resetPrivacyChangeForm(value: any) {
    const defaultValue = {
      id: "",
      password: "",
      passwordConfirm: "",
      name: "",
      email: "",
      teamObjectId: undefined,

      validateStatus: {
        id: "",
        password: "",
        passwordConfirm: "",
        email: "",
        name: "",
      },

      error: {
        id: "",
        password: "",
        passwordConfirm: "",
        email: "",
        name: "",
      },
    };

    if (value && !value.objectId) {
      this.privacyChangeForm = defaultValue;
    } else {
      this.privacyChangeForm = {
        ...defaultValue,
        ...value,
      };
    }
  }

  @Action({ rawError: true })
  async loadMemberListAsync() {
    // if (this.shouldBeLoaded === true) {
    const memberList = await Member.list();
    this.context.commit("setMemberList", memberList);

    //   this.context.commit("setShouldBeLoaded", false);
    // }
  }

  @Action
  async loadMemberSingleAsync(objectId: string) {
    const memberDetail = await Member.single(objectId);
    this.context.commit("setPrivacyChangeForm", {
      ...this.privacyChangeForm,
      ...memberDetail,
      prevName: memberDetail.name,
      prevEmail: memberDetail.email,
    });
  }

  @Action
  async updateMemberAsync(member: any) {
    const memberDetail = await Member.update(member);
    this.context.commit("setPrivacyChangeForm", {
      ...this.privacyChangeForm,
      ...memberDetail,
      prevName: memberDetail.name,
      prevEmail: memberDetail.email,
      password: "",
      passwordConfirm: "",
    });
  }

  @Action
  async updatePrivacyAsync(member: any) {
    await Member.update(member);
  }

  @Action
  async updateMemberTeamAsync(data: any) {
    const result = await Member.updateTeam(data.member, data.team);
    return result;
  }

  @Action
  async createMemberAsync(member: any) {
    await Member.create(member);
  }

  @Action
  async deleteMemberAsync(member: any) {
    await Member.delete(member);
  }

  @Action
  async checkName() {
    const { name, prevName } = this.privacyChangeForm;

    if (!nameRegex.test(name)) {
      this.privacyChangeForm.validateStatus.name = "warning";
      this.privacyChangeForm.error.name = "이름 2글자 이상 영문, 숫자로 입력해주세요.";
      return;
    } else {
      this.privacyChangeForm.validateStatus.name = "success";
      this.privacyChangeForm.error.name = "";
    }

    if (prevName === name) {
      this.privacyChangeForm.validateStatus.name = "";
      this.privacyChangeForm.error.name = "";
      return;
    }

    this.privacyChangeForm.validateStatus.name = "validating";

    try {
      await Member.checkUserName(name);
      this.privacyChangeForm.validateStatus.name = "success";
      this.privacyChangeForm.error.name = "";
    } catch {
      this.privacyChangeForm.error.name = "이미 가입된 이름 입니다.";
      this.privacyChangeForm.validateStatus.name = "error";
    }
  }

  @Action
  async checkId() {
    const { id } = this.privacyChangeForm;

    if (!idRegex.test(id)) {
      this.privacyChangeForm.validateStatus.id = "warning";
      this.privacyChangeForm.error.id = "아이디는 영문, 숫자로 4글자 이상 입력해주세요.";
      return;
    } else {
      this.privacyChangeForm.validateStatus.id = "success";
      this.privacyChangeForm.error.id = "";
    }

    this.privacyChangeForm.validateStatus.id = "validating";
    try {
      await Auth.checkId(id);
      this.privacyChangeForm.validateStatus.id = "success";
      this.privacyChangeForm.error.id = "";
    } catch {
      this.privacyChangeForm.error.id = "이미 가입된 아이디 입니다.";
      this.privacyChangeForm.validateStatus.id = "error";
    }
  }

  @Action
  checkPassword() {
    const { password, passwordConfirm, permission } = this.privacyChangeForm;

    if (!permission) {
      this.privacyChangeForm.validateStatus.password = "warning";
      this.privacyChangeForm.error.password = "권한을 먼저 선택해주세요.";
      this.privacyChangeForm.validateStatus.passwordConfirm = "warning";
      return;
    }

    if (!password) {
      return;
    }

    if ((permission === 3 || permission === "3") && !passwordRegex.test(password)) {
      this.privacyChangeForm.validateStatus.password = "warning";
      this.privacyChangeForm.error.password =
        "문자 + 숫자 + 특수문자가 포함 된 6자리 이상을 입력해주세요.";

      this.privacyChangeForm.validateStatus.passwordConfirm = "";
      this.privacyChangeForm.error.passwordConfirm = "";

      return;
    } else {
      this.privacyChangeForm.validateStatus.password = "success";
      this.privacyChangeForm.error.password = "";
    }

    if (password !== passwordConfirm) {
      this.privacyChangeForm.validateStatus.passwordConfirm = "warning";
      this.privacyChangeForm.error.passwordConfirm = "비밀번호가 일치하지 않습니다.";
    } else {
      this.privacyChangeForm.validateStatus.passwordConfirm = "success";
      this.privacyChangeForm.error.passwordConfirm = "";
    }
  }

  @Action
  async checkEmail() {
    const { email, prevEmail } = this.privacyChangeForm;

    if (!email) {
      return;
    }

    if (!emailRegex.test(email)) {
      this.privacyChangeForm.validateStatus.email = "warning";
      this.privacyChangeForm.error.email = "이메일 양식을 확인해주세요.";
      return;
    } else {
      this.privacyChangeForm.validateStatus.email = "";
      this.privacyChangeForm.error.email = "";
    }

    if (prevEmail && email === prevEmail) {
      this.privacyChangeForm.validateStatus.email = "";
      this.privacyChangeForm.error.email = "";
      return;
    }

    this.privacyChangeForm.validateStatus.id = "validating";
    try {
      await Auth.checkEmail(email);
      this.privacyChangeForm.validateStatus.email = "success";
      this.privacyChangeForm.error.email = "";
    } catch {
      this.privacyChangeForm.error.email = "이미 가입된 이메일 입니다.";
      this.privacyChangeForm.validateStatus.email = "error";
    }
  }

  @Action
  async checkPrivacy() {
    const { privacy } = this.privacyChangeForm;

    if (!privacy) {
      this.privacyChangeForm.validateStatus.privacy = "warning";
      this.privacyChangeForm.error.privacy = "약관에 동의해주세요.";

      return;
    } else {
      this.privacyChangeForm.validateStatus.privacy = "";
      this.privacyChangeForm.error.privacy = "";
    }

    this.privacyChangeForm.validateStatus.privacy = "validating";
  }

  @Action({ rawError: true })
  async changeOrder(changeOrder: any) {
    await Member.changeOrder(changeOrder);
  }
}
