






import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Modal = namespace("modal");
const WorkModule = namespace("WorkModule");

@Component({
  components: {},
})
export default class WorkCard extends Vue {
  @Prop() categoryId: any;
  @Prop() card: any;
  editSubject = "";

  @WorkModule.State("work") work: any;
  @WorkModule.Action("deleteWorkCard") deleteWorkCard: any;
  @WorkModule.Action("updateWorkCardSubject") updateWorkCardSubject: any;
  @Modal.Action("showModal") showModal: any;

  mounted() {}

  deleteCard() {
    const categoryId = this.categoryId;
    const cardId = this.card.objectId;
    this.deleteWorkCard({ categoryId, cardId });
  }

  onDetail() {
    this.showModal({
      type: "work-modal",
      maskClosable: false,
      props: {
        data: {
          categoryId: this.categoryId,
          card: this.card,
        },
      },
    });
  }
}
