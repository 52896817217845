import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

import { Settings } from "@/http/api/settings";

@Module({
  namespaced: true,
})
export default class SettingModule extends VuexModule {
  @Action
  async loadSettingData(key: any) {
    return await Settings.loadSettingDataAsync(key);
  }

  @Action
  async updateSettingAsync(data: any) {
    const { key, value } = data;
    await Settings.updateSettingDataAsync(key, value);
  }

  @Action
  async setHotelOrdersAsync(hotels: string[]) {
    await Settings.setHotelOrdersAsync(hotels);
  }
}
