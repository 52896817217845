































































import { Issue } from "@/http/api/issue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({})
export default class ReadLogLegacy extends Vue {
  @Prop() objectId: any;
  @Prop() count: any;
  @Prop() totalCount: any;
  @Prop() color: any;
  @Prop() teams: any;

  loading: boolean = false;
  open: boolean = false;

  readLogs: any[] = [];

  async load() {
    if (!this.open) {
      this.loading = true;

      try {
        this.readLogs = await Issue.readLogList(this.objectId);
      } catch {
      } finally {
        this.loading = false;
      }
    }
  }
}
