import dashboard from "./dashboard.vue";
import hotel from "./hotel.vue";
import user from "./user.vue";
import issue from "./issue.vue";
import notice from "./notice.vue";
import advertisement from "./advertisement.vue";
import popup from "./popup.vue";
import version from "./version.vue";

export default [
  {
    name: "support-root",
    path: "/support",
    redirect: "/support/dashboard",
  },
  {
    name: "support-dashboard",
    path: "/support/dashboard",
    component: dashboard,
  },
  {
    name: "support-hotel",
    path: "/support/hotel",
    component: hotel,
  },
  {
    name: "support-user",
    path: "/support/user",
    component: user,
  },
  {
    name: "support-issue",
    path: "/support/issue",
    component: issue,
  },
  {
    name: "support-notice",
    path: "/support/notice",
    component: notice,
  },
  {
    name: "support-advertisement",
    path: "/support/advertisement",
    component: advertisement,
  },
  {
    name: "support-popup",
    path: "/support/popup",
    component: popup,
  },
  {
    name: "support-version",
    path: "/support/version",
    component: version,
  },
];
