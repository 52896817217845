

























import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action, namespace } from "vuex-class";
import { Auth } from "@/http/api/auth";
import draggable from "vuedraggable";

const HotelModule = namespace("HotelModule");
const SettingModule = namespace("SettingModule");
const RegisterModule = namespace("RegisterModule");
const Modal = namespace("modal");

const hotelOrders = "hotel_orders";

@Component({
  components: {
    draggable,
  },
})
export default class SelectHotel extends Vue {
  @HotelModule.State("hotelList") hotelList: any;
  @HotelModule.Mutation("setHotelList") setHotelList: any;
  @HotelModule.Action("loadHotelListAsync") loadHotelListAsync: any;
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;

  @SettingModule.Action("setHotelOrdersAsync") setHotelOrdersAsync: any;
  @SettingModule.Action("loadSettingData") loadSettingData: any;

  @RegisterModule.State("selfInfo") selfInfo: any;
  @RegisterModule.Action("loadSelfInfo") loadSelfInfo: any;

  @Modal.Action("showModal") showModal: any;

  isLoading = false;

  dragOptions = {
    animation: 0,
    group: "description",
    disabled: this.isLoading,
    ghostClass: "ghost",
  };

  get isMaster() {
    return this.selfInfo && this.selfInfo.role === 4;
  }

  get sortedHotelList() {
    return this.hotelList;
  }

  set sortedHotelList(value: any[]) {
    this.setHotelList(value);
  }

  async created() {
    try {
      await this.loadHotelListAsync();
      await this.loadSelfInfo();
    } catch (err) {
      this.showModal({
        type: "errorModal",
        maskClosable: false,
        keyboard: false,
        props: {},
      });
    }
  }

  changeHotelListOrder(e: any) {
    const movedData = e.moved;
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;

      const orders = this.sortedHotelList.map((hotel: any) => hotel.objectId);
      const newOrders = orders.filter((item) => item != movedData.element.objectId);
      const front = newOrders.filter((item: string, index: number) => index < movedData.newIndex);
      const back = newOrders.filter((item: any, index: number) => index >= movedData.newIndex);
      front.push(movedData.element.objectId);
      const newOne = front.concat(back);

      this.setHotelOrdersAsync(newOne);
    } catch {
    } finally {
      this.isLoading = false;
    }
  }

  toRegisterHotel() {
    const redirectPath = this.$route.query.redirectPath;
    const query = redirectPath ? { redirectPath } : {};

    this.$router.push({ path: "/registerhotel", query });
  }
  toHotel(hotel: any) {
    this.toHotelUrl({ url: hotel.url });
  }

  async logout() {
    try {
      await Auth.logout();
    } catch {}

    this.toHotelUrl({});
  }
}
