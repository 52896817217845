import writeIssueModal from "./writeIssueModal.vue";
import writeNoticeModal from "./writeNoticeModal.vue";
import imageDetailModal from "./imageDetailModal.vue";
import issueChangeLogModal from "./issueChangeLogModal.vue";
import noticeChangeLogModal from "./noticeChangeLogModal.vue";
import errorModal from "./errorModal.vue";
import logoPreviewModal from "./logoPreviewModal.vue";
import noticeModal from "./noticeModal.vue";
import workModal from "./workModal.vue";
import selectWorkCategoryModal from "./selectWorkCategoryModal.vue";

export default {
  writeIssueModal,
  writeNoticeModal,
  imageDetailModal,
  issueChangeLogModal,
  noticeChangeLogModal,
  errorModal,
  logoPreviewModal,
  noticeModal,
  workModal,
  selectWorkCategoryModal,
};
