













































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class HelpCenter extends Vue {
  helpCenterMainCards: any[] = [
    {
      icon: "/assets/settings/manual.svg",
      href: "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/images/20210314/1615727218932_RUMY 3.1 Web 사용매뉴얼.pdf",
      fileName: "루미 3.1 웹 메뉴얼",
      firstTitle: "Web 매뉴얼",
      secondTitle: "다운로드",
    },
    {
      icon: "/assets/settings/manual.svg",
      href: "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/images/20210314/1615727223051_RUMY 3.1 App 사용매뉴얼.pdf",
      fileName: "루미 3.1 웹 메뉴얼",
      firstTitle: "App 매뉴얼",
      secondTitle: "다운로드",
    },
    {
      icon: "/assets/settings/report.svg",
      href: "https://forms.gle/Rpjg5vD5nyss4V3B7",
      firstTitle: "오류 리포트",
      secondTitle: "보내기",
    },
  ];

  helpCenterInfos: any[] = [
    {
      icon: "/assets/settings/call.svg",
      title: "대표전화",
      contents: "032-710-1510",
    },
    {
      icon: "/assets/settings/email.svg",
      title: "이메일",
      contents: "rumy@jinjoosoft.com",
    },
    {
      icon: "/assets/settings/time.svg",
      title: "운영시간",
      contents: "10:00~18:00",
    },
    {
      icon: "/assets/settings/kakao.svg",
      title: "카카오채널",
      contents: "rumy",
    },
  ];

  help(item: any) {
    if (item.fileName) {
      const a = document.createElement("a");
      a.download = "루미 3.1 웹 메뉴얼";
      a.href = item.href;
      a.click();
    } else {
      window.open(item.href);
    }
  }
}
