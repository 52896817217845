







































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import moment from "moment";
import noticeReplyInput from "@/components/notice/noticeReplyInput.vue";
import { textToJson } from "@/utiles/contentsParser";
import noticeReadonlyContents from "@/components/notice/noticeReadonlyContents.vue";
import ProfileImage from "@/components/common/profileImage.vue";
import Attachment from "@/components/common/attachment.vue";
import { NoticeReply as NoticeReplyApi } from "@/http/api/noticeReply";

const HotelModule = namespace("HotelModule");
const NoticeModule = namespace("NoticeModule");
const Modal = namespace("modal");

@Component({
  name: "NoticeReply",
  components: {
    noticeReplyInput,
    noticeReadonlyContents,
    ProfileImage,
    Attachment,
  },
})
export default class NoticeReply extends Vue {
  @Prop() noticeObjectId: any;
  @Prop() parentObjectId: any;
  @Prop() data: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;

  @NoticeModule.State("loadNotice") loadNotice: any;
  @NoticeModule.Action("loadNoticeReplies") loadNoticeReplies: any;

  @Modal.Action("showModal") showModal: any;

  moment: any = moment;

  edit: boolean = false;
  reply: boolean = false;

  get parentObjectIds() {
    return [this.noticeObjectId, ...(this.parentObjectId ? [this.parentObjectId] : [])].join(",");
  }

  get editPermission() {
    return (
      this.hotelInfo &&
      (this.data.writerObjectId === this.hotelInfo.userObjectId ||
        this.hotelInfo.userPermission === 3)
    );
  }

  get deletePermission() {
    return (
      this.hotelInfo &&
      (this.data.writerObjectId === this.hotelInfo.userObjectId ||
        this.hotelInfo.userPermission === 3 ||
        this.hotelInfo.userPermission === 2)
    );
  }

  get contents() {
    return textToJson([], this.data.contents);
  }

  async confirmDeleteReply() {
    const { parentObjectIds } = this.data;
    const objectId = this.data.objectId;
    const rootObjectId = parentObjectIds.split(",")[0];

    await NoticeReplyApi.deleteReply(parentObjectIds, objectId);
    this.loadNotice(rootObjectId);
    this.loadNoticeReplies(rootObjectId);
  }

  upsert(reply: boolean = false) {
    if (reply) {
      this.reply = false;
    }

    this.$emit("upsert");
  }
}
