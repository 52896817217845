import { MemberDto } from "./member";
import { TeamDto } from "./team";

export enum IssueAction {
  RESERVED = 0,
  CREATED = 1,
  UPDATED = 2,
}

export enum IssueStatus {
  REGISTERED = 0,
  NOTIFIED = 1,
  SOLVED = 2,
  PENDING = 2,
}

export type IssueDto = {
  objectId: string;
  category: string;
  content?: string;
  action?: IssueAction;
  status?: IssueStatus;
  readCount?: number;
  totalRead?: number;
  bookmarked?: boolean;
  read?: boolean;
  replyCount: number;
  fileAttached: boolean;
  checklistAttached: boolean;
  teams?: TeamDto[];
  secret?: boolean;
  writer?: MemberDto;
  createdAt: Date;
  updatedAt: Date;
};

export type IssueDetailDto = {
  objectId: string;
  category: string;
  content?: string;
  action?: IssueAction;
  status?: IssueStatus;
  read?: boolean;
  readCount?: number;
  totalRead?: number;
  bookmarked?: boolean;
  replyCount?: number;
  mentions: string[];
  teams: TeamDto[];
  files: Record<string, unknown>[];
  checklists: Record<string, unknown>[];
  reservedStartDate?: Date;
  reservedStartTime?: string;
  reservedEndDate?: Date;
  reservedEndTime?: string;
  reservedPlace?: string;
  allDay?: boolean;
  secret?: boolean;
  writer?: MemberDto;
  createdAt: Date;
  updatedAt: Date;
};

export type IssueSummaryDto = {
  category: string;
  content: string;
  status: IssueStatus;
  writer: string;
  date: Date;
};

export type IssueFilterParams = {
  skip?: number;
  take?: number;
  keyword?: string;
  from?: Date | string;
  to?: Date | string;
  bookmarked?: boolean;
  mine?: boolean;
  new?: boolean;
  keywordType?: string;
  categories?: string[];
  teams?: string[];
  statuses?: IssueStatus[];
};
