






















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import workCard from "@/components/work/workCard.vue";
import draggable from "vuedraggable";
import WorkFilter from "@/components/work/workFilter.vue";

const WorkModule = namespace("WorkModule");

@Component({
  components: {
    workCard,
    draggable,
    WorkFilter,
  },
})
export default class Work extends Vue {
  @WorkModule.State("work") work: any;
  @WorkModule.Mutation("setWork") setWork: any;
  @WorkModule.Action("loadWork") loadWork: any;
  @WorkModule.Action("createWorkCategory") createWorkCategory: any;
  @WorkModule.Action("updateWorkCategory") updateWorkCategory: any;
  @WorkModule.Action("updateWorkCategoryTitle") updateWorkCategoryTitle: any;
  @WorkModule.Action("updateWorkCategoryColor") updateWorkCategoryColor: any;
  @WorkModule.Action("deleteWorkCategory") deleteWorkCategory: any;
  @WorkModule.Action("createWorkCard") createWorkCard: any;
  @WorkModule.Action("updateWorkCard") updateWorkCard: any;

  showWorkCategory = false;
  categoryTitle: string = "";
  selectedCategoryId: string = "";
  selectedCategoryTitleId: string = "";
  cardSubject: string = "";

  filter: any = {
    keyword: "",
  };

  parseHex(key: string) {
    let keys = [];
    for (let index = 0; index < 3; index++) {
      const value = parseInt(key.substr(-2 * (index + 1), 2), 16);
      let result;
      if (index <= 0) {
        result = (value * 1) % 3;
      } else {
        result = (value * 1) % 10;
      }
      keys.push(result);
    }
    return this.parseKey(keys);
  }

  parseKey(values: number[]) {
    let result = 0;
    for (let index = 0; index < values.length; index++) {
      switch (index) {
        case 0:
          result += values[index] * 100;
          break;
        case 1:
          result += values[index] * 10;

          break;

        case 2:
          result += values[index] * 1;

          break;

        default:
          result += values[index] * 100;
          break;
      }
    }
    return result;
  }

  createHSV(objectId: string) {
    const key = this.parseHex(objectId.substr(-6));
    return "hsl(" + key + ",100%, 50%)";
  }

  categoryFilter(category: any) {
    if (this.filter.keyword != "") {
      const categoryCheck = category.title.includes(this.filter.keyword);
      const cardsCheck =
        category.cards.filter((card: any) => card.subject.includes(this.filter.keyword)).length > 0;
      return cardsCheck || categoryCheck;
    }
    return true;
  }

  cardFilter(card: any) {
    if (this.filter.keyword != "") {
      return card.subject.includes(this.filter.keyword);
    }
    return true;
  }

  mounted() {
    this.loadWorkAsync();
  }

  async loadWorkAsync() {
    await this.loadWork();
  }

  toggleWorkCategoryInput() {
    this.showWorkCategory = !this.showWorkCategory;
    if (this.showWorkCategory) {
      setTimeout(() => {
        const categoryTitleElement = this.$refs.categoryTitle as any;
        categoryTitleElement.focus();
      }, 0);
    }
    this.categoryTitle = "";
  }

  toggleWorkCardInput(categoryId: string) {
    this.selectedCategoryId = categoryId;
    setTimeout(() => {
      const cardSubjectRefElement = this.$refs.cardSubjectRef as any;
      cardSubjectRefElement[0].focus();
    }, 0);
  }

  toggleWorkCategoryTitle(categoryId: string) {
    this.selectedCategoryTitleId = categoryId;
    this.categoryTitle = this.work.categories.find((c: any) => c.objectId == categoryId).title;
  }

  resetWorkCategoryTitle() {
    this.selectedCategoryTitleId = "";
  }

  resetSelectedCategoryId() {
    this.selectedCategoryId = "";
    this.cardSubject = "";
  }

  categoryFocusOut() {
    const categoryTitleInput = this.$refs.categoryTitle as any;
    categoryTitleInput.blur();
  }

  createCategory() {
    this.showWorkCategory = false;
    if (this.categoryTitle != "") {
      this.createWorkCategory(this.categoryTitle);
    }
    this.categoryTitle = "";
  }

  updateCategory(event: any) {
    const categoryId = this.work.categories[event.moved.newIndex].objectId;
    const currentCategoryIndex = event.moved.newIndex;
    this.updateWorkCategory({ categoryId, currentCategoryIndex });
  }

  updateCategoryTitle(event: any) {
    const categoryId = this.selectedCategoryTitleId;
    const title = this.categoryTitle;
    this.categoryTitle = "";
    this.selectedCategoryTitleId = "";
    const newWork = this.work;
    const newCategories = newWork.categories.map((c: any) => {
      if (categoryId == c.objectId) {
        c.title = title;
      }
      return c;
    });
    newWork.categories = newCategories;
    this.setWork(newWork);
    this.updateWorkCategoryTitle({ categoryId, title });
  }

  deleteCategory(categoryId: string) {
    const newWork = this.work;
    const newCategories = newWork.categories.filter((c: any) => categoryId != c.objectId);
    newWork.categories = newCategories;
    this.setWork(newWork);

    this.deleteWorkCategory(categoryId);
  }

  updateCard(event: any, categoryId: string) {
    if (event.added) {
      const cardId = event.added.element.objectId;
      const currentCardIndex = event.added.newIndex;
      this.updateWorkCard({ categoryId, cardId, currentCardIndex });
    } else if (event.moved) {
      const cardId = event.moved.element.objectId;
      const currentCardIndex = event.moved.newIndex;
      this.updateWorkCard({ categoryId, cardId, currentCardIndex });
    }
  }

  createCard() {
    if (this.cardSubject.length > 0) {
      const categoryId = this.selectedCategoryId;
      const subject = this.cardSubject;
      this.createWorkCard({ categoryId, subject });
    }
    this.resetSelectedCategoryId();
  }
}
