import axios, { Method, AxiosRequestConfig } from "axios";
import moment from "moment";

const port = 443;
export const host = `${window.location.hostname}:${port}`;

export const axiosClient = axios.create({
  baseURL: `https://${host}/Api`,
  withCredentials: true,
  headers: {
    Pragma: "no-cache",
    "Content-Type": "application/json",
  },
});

const getKeyValue = (value: any, key?: string): any => {
  if (typeof value === "object") {
    if (!value) {
      return { [key || ""]: value };
    }

    if (key && key.toString() === "images") {
      return { [key]: value };
    }

    if (key && key.toString() === "files") {
      return { [key]: value };
    }

    const constructorName = value.constructor && value.constructor.name;
    switch (constructorName) {
      case "Moment":
        return { [key || ""]: moment(value).format() };

      case "Date":
        return { [key || ""]: moment(value).format() };

      default:
        return Object.keys(value).reduce((obj, _key) => {
          let generatedKey;

          if (!key) {
            generatedKey = _key;
          } else if (constructorName == "Array") {
            generatedKey = `${key}[${_key}]`;
          } else {
            generatedKey = `${key}.${_key}`;
          }

          return {
            ...obj,
            ...getKeyValue(value[_key], generatedKey),
          };
        }, {});
    }
  } else {
    return { [key || ""]: value };
  }
};

export default class Client {
  static request = async <T>(
    method: Method,
    path: string,
    params?: any,
    data?: any,
    config?: AxiosRequestConfig,
  ) => {
    config = {
      method,
      url: path,
      params,
      data,
      ...config,
    };
    const response = await axiosClient.request(config);
    return response.data as T;
  };

  static get = async <T extends any>(path: string, params?: any) => {
    return await Client.request<T>("GET", path, params, null);
  };

  static post = async <T extends any>(path: string, data?: any) => {
    return await Client.request<T>("POST", path, null, data);
  };

  static put = async <T extends any>(path: string, data?: any) => {
    return await Client.request<T>("PUT", path, null, data);
  };

  static del = async <T extends any>(path: string, params?: any) => {
    return await Client.request<T>("DELETE", path, params, null);
  };

  static form = async <T>(path: string, data?: any, method?: "POST" | "PUT") => {
    const formData = new FormData();
    const keyValues = getKeyValue(data);

    if (keyValues) {
      Object.keys(keyValues).forEach(key => {
        if (
          typeof keyValues[key] === "string" ||
          typeof keyValues[key] === "number" ||
          typeof keyValues[key] === "boolean"
        ) {
          keyValues[key] && formData.append(key, keyValues[key]);
        } else {
          if (keyValues[key]) {
            switch (keyValues[key].constructor) {
              case Date:
                formData.append(key, keyValues[key]);
                break;

              default:
                formData.append(key + "Json", JSON.stringify(keyValues[key]));
                break;
            }
          }
        }
      });
    }
    return await Client.request<T>(method || "POST", path, null, formData, {
      headers: {
        "Content-Type": null,
      },
    });
  };
}
