import Client from "../client";

export class File {
  static async getPreSignedUrl(fileName: string) {
    return await Client.get<any>(`/file/images/${fileName}`);
  }

  static async list(currentPage: number, keyword: string) {
    return await Client.get<any[]>(`/file`, { currentPage, keyword });
  }
}
