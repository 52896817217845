

















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import issueFilter from "@/components/issues/issueFilter.vue";
import issueReadonlyContents from "@/components/issues/issueReadonlyContents.vue";
import moment, { isMoment } from "moment";
import _ from "lodash";
import { textToJson } from "@/utiles/contentsParser";
import { host, axiosClient } from "@/http/client";
import { IssueSummaryDto } from "@/http/dto/issue";
import { Issue as IssueApi } from "@/http/api/v2/issue";

const Modal = namespace("modal");
const IssueModule = namespace("IssueModule");
const HotelModule = namespace("HotelModule");

const issueStatus = ["새 이슈", "진행 중", "완료", "보류"] as const;

@Component({
  components: {
    issueFilter,
    issueReadonlyContents,
  },
})
export default class ReportIssue extends Vue {
  /**
   * @deprecated WHAT A MESS.
   */

  moment = moment;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;
  @Modal.Action("showModal") showModal: any;

  issueSummaries: IssueSummaryDto[] = [];

  filter: any = {
    category: [],
    teamObjectIds: [],
    keywordType: "contents",
    keyword: "",
    status: [],
    skip: 0,
    take: undefined,
    startDate: null,
    endDate: null,
  };

  get textToJson() {
    return textToJson;
  }

  get date() {
    const dates: string[] = [];

    if (this.filter.startDate == null) {
      dates.push("첫 이슈");
    } else {
      dates.push(moment(this.filter.startDate).format("YYYY/MM/DD"));
    }

    if (this.filter.endDate == null) {
      dates.push("마지막 이슈");
    } else {
      dates.push(moment(this.filter.startDate).format("YYYY/MM/DD"));
    }

    return dates.join(" ~ ");
  }

  get columns() {
    return [
      {
        width: "20%",
        title: "작성일",
        dataIndex: "date",
        align: "center",
        customRender: (text: string) => moment(text).format("YYYY/MM/DD HH:mm"),
      },
      {
        width: "10%",
        title: "작성자",
        dataIndex: "writer",
        align: "center",
      },
      {
        width: "15%",
        title: "카테고리",
        dataIndex: "category",
        align: "center",
      },
      {
        width: "45%",
        title: "내용",
        dataIndex: "content",
        key: "content",
        slots: { contents: "content" },
        scopedSlots: { customRender: "customContent" },
      },
      {
        width: "10%",
        title: "상태",
        dataIndex: "status",
        align: "center",
        customRender: (text: string) => issueStatus[parseInt(text)],
      },
    ];
  }

  async download() {
    if (this.hotelInfo.objectId == null) {
      return;
    }

    const { startDate, endDate, category, status, isNew, isMine, isReserved, teamObjectIds } =
      this.filter;

    const response = await axiosClient.request({
      url: `v2/${this.hotelInfo.objectId}/issue-summaries/excel`,
      responseType: "arraybuffer",
      method: "GET",
      params: {
        categories: category,
        statuses: status,
        new: isNew,
        mine: isMine,
        reserved: isReserved,
        teams: teamObjectIds,
        from: startDate && moment(startDate).toDate(),
        to: endDate && moment(endDate).toDate(),
      },
    });

    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const filename = "RUMY 기간내 이슈 보고서";

    if (window.navigator.msSaveOrOpenBlob) {
      // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // not IE
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_self";
      if (filename) link.download = filename;
      link.click();
    }
  }

  loading: boolean = false;

  created() {
    const query = this.$route.query as Record<string, unknown>;

    this.filter = {
      ...this.filter,
      status: query.status
        ? Array.isArray(query.status)
          ? query.status.map((s: string) => parseInt(s))
          : [parseInt(query.status as any)]
        : [],
      keyword: query.keyword,
      keywordType: query.keywordType,
      isMine: query.isMine === "true",
      isNew: query.isNew === "true",
      isReserved: query.isReserved === "true",
      startDate: query.startDate,
      endDate: query.endDate,
      teamObjectIds: query.teamObjectIds
        ? Array.isArray(query.teamObjectIds)
          ? query.teamObjectIds
          : [query.teamObjectIds]
        : [],
      category: query.category
        ? Array.isArray(query.category)
          ? query.category
          : [query.category]
        : [],
    };
  }

  // async filterReset() {
  //   this.filter = {
  //     category: [],
  //     teamObjectIds: [],
  //     keywordType: "contents",
  //     keyword: "",
  //     status: [],
  //     skip: 0,
  //     take: undefined,
  //     startDate: moment().add(-1, "w"),
  //     endDate: moment(),
  //   };
  // }

  async mounted() {
    await this.fetchHotelInfo();
    await this.fetchIssueSummaries();
  }

  async fetchHotelInfo() {
    if (this.hotelInfo.objectId == null) {
      await this.loadInfoAsync();
    }
  }

  async fetchIssueSummaries() {
    if (this.hotelInfo.objectId == null) {
      return;
    }

    try {
      this.loading = true;
      this.issueSummaries = await IssueApi.getIssueSummaries(this.hotelInfo.objectId, {
        keyword: this.filter.keyword,
        from:
          this.filter.startDate == null
            ? undefined
            : moment(this.filter.startDate).format("YYYY-MM-DD"),
        to:
          this.filter.endDate == null
            ? undefined
            : moment(this.filter.endDate).format("YYYY-MM-DD"),
        mine: this.filter.isMine,
        new: this.filter.isNew,
        keywordType: this.filter.keywordType,
        teams: this.filter.teamObjectIds,
        categories: this.filter.category,
        statuses: this.filter.status,
      });
    } finally {
      this.loading = false;
    }
  }

  refresh() {
    this.fetchIssueSummaries();
  }
  // onSearch() {
  //   if (!this.filter.startDate) {
  //     this.filter.startDate = moment().startOf("month");
  //   }
  //   if (!this.filter.endDate) {
  //     this.filter.endDate = moment();
  //   }
  //   this.loadAsync();
  // }

  onPrint() {
    window.print();
  }
}
