






import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";

@Component
export default class IssueWriteButton extends Vue {
  click(e: any) {
    this.$emit("click", e);
  }
}
