


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";
import { Support } from "@/http/api/support";
import moment from "moment";

const HotelModule = namespace("HotelModule");

@Component({
  components: {},
})
export default class HotelManagement extends Vue {
  @HotelModule.State("hotelObjectId") hotelObjectId: any;

  deleted = false;

  get hotelObjectIdValue() {
    return this.hotelObjectId;
  }

  @Watch("hotelObjectIdValue")
  hotelObjectIdChanged() {
    this.load();
  }

  get columns() {
    return [
      {
        title: "호텔명",
        dataIndex: "name",
      },
      {
        title: "접속주소",
        dataIndex: "url",
      },
      {
        title: "이슈",
        dataIndex: "issueCount",
      },
      {
        title: "게시글",
        dataIndex: "noticeCount",
      },
      {
        title:"유저",
        dataIndex: "userCount",
      },
      {
        title: "마스터 아이디",
        dataIndex: "masterIds",
      },
      {
        title: "생성일",
        dataIndex: "createdTime",
        customRender: (value: any) => moment(value).format("YYYY/MM/DD"),
      },
      ...(this.deleted
        ? [
            {
              title: "삭제일",
              dataIndex: "deletedTime",
              customRender: (value: any) => value && moment(value).format("YYYY/MM/DD"),
            },
            {
              title: "기능",
              dataIndex: "recovery",
              scopedSlots: { customRender: "recovery" },
            },
          ]
        : [
            {
              title: "기능",
              dataIndex: "delete",
              scopedSlots: { customRender: "delete" },
            },
          ]),
    ];
  }

  get pagination() {
    return {
      total: this.total,
      pageSize: 20,
      current: this.current,
    };
  }

  loading = false;
  data = [];
  total = 0;
  current = 1;
  keyword = "";

  selectedHotels = [];

  get hotelCount() {
    return this.selectedHotels.length;
  }

  get rowSelection() {
    return { selectedRowKeys: this.selectedHotels, onChange: this.onSelectChange };
  }

  onSelectChange(selectedRowKeys: any) {
    this.selectedHotels = selectedRowKeys;
  }

  async deleteHotel(objectId: string) {
    await Support.deleteHotel(objectId);
    this.load();
  }

  async recoveryHotel(objectId: string) {
    await Support.recoveryHotel(objectId);
    this.load();
  }

  mounted() {
    this.load();
  }

  async load(pagination?: any) {
    try {
      this.loading = true;

      const { current = 1 } = pagination || {};
      const { count, list } = await Support.hotels({
        page: current,
        keyword: this.keyword,
        deleted: this.deleted,
      });
      this.current = current;
      this.total = count;
      this.data = list;
    } catch {
    } finally {
      this.loading = false;
    }
  }
}
