import { VuexModule, Module, Mutation, Action, MutationAction } from "vuex-module-decorators";
import { Notice } from "@/http/api/notice";
import moment from "moment";
import { NoticeReply } from "@/http/api/noticeReply";

@Module({
  namespaced: true,
})
export default class NoticeModule extends VuexModule<any> {
  noticeMap: any = {};

  topNoticeList: any[] = [];
  noticeList: any[] = [];
  noticeCount = 0;

  noticeReplyMap: any = {};

  @MutationAction
  async loadNoticeList(options: any) {
    const { filter = {} } = options || {};
    const current = filter.current || 1;
    const take = filter.take || 10;
    const skip = (current - 1) * take;

    const list = await Notice.list({
      ...filter,
      skip,
      take,
    });

    return upsertNotices({
      notices: list,
      clear: true,
      onlyTop: false,
      noticeMap: this.state.noticeMap,
      noticeList: list,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async loadNoticeTopList() {
    const topNoticeList = await Notice.topList();

    return upsertNotices({
      notices: topNoticeList,
      clear: false,
      onlyTop: true,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: topNoticeList,
    });
  }

  @MutationAction
  async loadNotice(objectId: string) {
    const notice = await Notice.singleNotice(objectId);

    return upsertNotices({
      notices: [notice],
      clear: false,
      onlyTop: false,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async loadNoticeCount(options: any) {
    const { filter = {} } = options || {};
    const noticeCount = await Notice.listCount(filter);

    return { noticeCount };
  }

  @MutationAction
  async writeNotice(data: any) {
    const notice = data.objectId
      ? await Notice.updateNotice({
          ...data,
          teamObjectId: data.teamObjectId,
        })
      : await Notice.createNotice({ ...data });

    return upsertNotices({
      notices: [notice],
      clear: false,
      onlyTop: false,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async updateRead(objectId: string) {
    const notice = await Notice.readNotice(objectId);

    return upsertNotices({
      notices: [notice],
      clear: false,
      onlyTop: false,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async updateBookmark({ objectId, isBookmarked }: { objectId: string; isBookmarked: boolean }) {
    const notice = await Notice.bookmarkNotice(objectId, isBookmarked);

    return upsertNotices({
      notices: [notice],
      clear: false,
      onlyTop: false,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async deleteNotice(objectId: string) {
    await Notice.deleteNotice(objectId);

    return deleteNotice({
      objectId,
      noticeMap: this.state.noticeMap,
      noticeList: this.state.noticeList,
      topNoticeList: this.state.topNoticeList,
    });
  }

  @MutationAction
  async loadNoticeReplies(objectId: string) {
    const list = await NoticeReply.replyList(objectId);
    const noticeReplyMap = { ...this.state.noticeReplyMap, [objectId]: list };
    return { noticeReplyMap };
  }
}

const upsertNotices = ({
  notices,
  clear,
  onlyTop,
  noticeMap,
  noticeList,
  topNoticeList,
}: {
  notices: any[];
  clear: boolean;
  onlyTop: boolean;
  noticeMap: any;
  noticeList: any[];
  topNoticeList: any[];
}) => {
  noticeMap = notices.reduce((result, item) => ({ ...result, [item.objectId]: item }), noticeMap);

  if (!onlyTop) {
    noticeList = notices.reduce(
      (result, item) => {
        const index = result.findIndex((_: any) => _.objectId === item.objectId);

        if (index !== -1) {
          result.splice(index, 1, item);
        } else if (clear) {
          result = [...result, item];
        }

        return result;
      },
      clear ? [] : noticeList,
    );
  }

  const today = new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
  topNoticeList = notices
    .filter(
      item =>
        item.isTop &&
        (!item.topStartTime || moment(item.topStartTime).isBefore(today)) &&
        (!item.topEndTime || moment(item.topEndTime).isAfter(today)),
    )
    .reduce(
      (result, item) =>
        result.find((_: any) => _.objectId === item.objectId) ? result : [...result, item],
      topNoticeList,
    );

  return { noticeMap, noticeList, topNoticeList };
};

const deleteNotice = ({
  objectId,
  noticeMap,
  noticeList,
  topNoticeList,
}: {
  objectId: any;
  noticeMap: any;
  noticeList: any[];
  topNoticeList: any[];
}) => {
  delete noticeMap[objectId];
  noticeList = noticeList.filter(item => item.objectId !== objectId);
  topNoticeList = topNoticeList.filter(item => item.objectId !== objectId);

  return { noticeMap, noticeList, topNoticeList };
};
