












































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Action, Mutation, Getter, namespace } from "vuex-class";

import adminMenu from "@/components/admin/adminMenu.vue";
import { Auth } from "@/http/api/auth";
import { Hotel } from "@/http/api/hotel";
import { File } from "@/http/api/file";
import axios from "axios";

const HotelModule = namespace("HotelModule");

const linkRegex = /(^https?:)/;
const maxFileSize = 1024 * 1024 * 20;

const Modal = namespace("modal");

@Component({
  components: {
    adminMenu,
  },
})
export default class AdminLayout extends Vue {
  @Prop({ default: false }) dark: any;
  @Prop({ default: false }) wide: any;
  @Prop({ default: "" }) title: any;

  @HotelModule.State("hotelInfo") hotelInfo: any;
  @HotelModule.Action("loadHotelAsync") loadHotelAsync: any;
  @HotelModule.Action("loadInfoAsync") loadInfoAsync: any;

  @HotelModule.Action("loadHotelListAsync") loadHotelListAsync: any;
  @HotelModule.Action("toHotelUrl") toHotelUrl: any;
  @HotelModule.State("hotelList") hotelList: any;

  @Modal.Action("showModal") showModal: any;

  url = location.host.split(".")[0];

  addHotel: boolean = false;
  addHotelData: any = {};

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  rules = {
    url: [
      {
        required: true,
        message: "접속주소를 입력해주세요.",
      },
      {
        trigger: "blur",
        validator: async (rule: any, value: any, callback: any) => {
          try {
            await Auth.checkUrl(value);
            callback();
          } catch {
            callback(new Error("이미 사용중인 접속주소입니다."));
          }
        },
      },
    ],
  };

  get logo() {
    return this.hotelInfo && this.hotelInfo.logo
      ? linkRegex.test(this.hotelInfo.logo)
        ? this.hotelInfo.logo
        : "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/" + this.hotelInfo.logo
      : "/assets/components/rumylogo.png";
  }

  get permission() {
    if (this.hotelInfo.userPermission == 3) {
      return true;
    }
    return false;
  }

  async created() {
    this.load();
    this.loadHotelListAsync();
  }

  async load() {
    try {
      await this.loadHotel();
      await this.loadInfo();

      if (this.hotelInfo) {
        const subdomain = location.hostname.split(".")[0];
        if (this.hotelInfo.hotelUrls.findIndex((item: any) => item === subdomain) === -1) {
          throw "unacceptable hotel.";
        }
      }
    } catch {
      this.showModal({
        type: "errorModal",
        maskClosable: false,
        keyboard: false,
        props: {},
      });
    }
  }

  hotelChange(value: any) {
    if (value) {
      this.toHotelUrl({ url: value, name: "admin-team" });
    }
  }

  async loadHotel() {
    await this.loadHotelAsync();
  }

  async loadInfo() {
    await this.loadInfoAsync();

    if (this.hotelInfo) {
      const subdomain = location.hostname.split(".")[0];
      if (this.hotelInfo.hotelUrls.findIndex((item: any) => item === subdomain) === -1) {
        throw "unacceptable hotel.";
      }
    }
  }

  openAddHotel() {
    this.addHotel = true;
    this.addHotelData = {};
  }

  closeAddHotel() {
    this.addHotel = false;
  }

  applyAddHotel() {
    const form = this.$refs.form as any;

    form.validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      try {
        await Hotel.registerHotel(this.addHotelData);

        this.$message.success(`호텔이 추가 되었습니다.`);

        this.load();
        this.closeAddHotel();
      } catch (ex) {
        this.$message.error(`호텔 추가에 실패하였습니다.`);
      }
    });
  }

  async logout() {
    try {
      await Auth.logout();
    } finally {
      this.toHotelUrl({ path: "admin" });
    }
  }

  async presignedRequest({
    file,
    onSuccess,
    onError,
  }: {
    file: any;
    onSuccess: any;
    onError: any;
  }) {
    try {
      if (file.size >= maxFileSize) {
        throw Error("Large Size");
      }
      const { presignedUrl, url } = await File.getPreSignedUrl(file.name);

      await axios.request({
        url: presignedUrl,
        method: "PUT",
        data: file,
        headers: { "Access-Control-Allow-Origin": "*" },
      });

      onSuccess({
        url,
        fileName: file.name,
        size: file.size,
      });
    } catch (err) {
      this.$message.error("파일의 용량이 20MB가 넘어 파일 업로드에 실패하였습니다.");
      onError(err);
    }
  }

  download() {
    const a = document.createElement("a");
    a.download = "루미 3.1 마스터 메뉴얼";
    a.href =
      "https://rumyr3-test-files.s3.ap-northeast-2.amazonaws.com/images/20210322/1616403960546_RUMY 3.1 Admin 사용매뉴얼.pdf";
    a.click();
  }

  async handleFileChange(event: any) {
    switch (event.file.status) {
      case "uploading":
        break;

      case "done":
        {
          const { response } = event.file;
          this.addHotelData.logo = response.url;
          this.addHotelData.file = response;

          this.$message.success("파일 업로드에 성공하였습니다.");
        }
        break;

      case "error":
        this.$message.error("파일 업로드에 실패하였습니다.");
        break;
    }
  }
}
